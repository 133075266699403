import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import { apiSlice } from '../services/apiSlice';
import { useGetAllFilesByDocumentIdQuery } from '../services/documentFiles/documentFilesApi';
import { isDemandOrCoverLetter } from '../common-document';
import { pollPdfRegenerationStatus } from '../services/pdf/thunks';
import { setDocumentStatus } from '../redux/slices/documentSlice';

const SendForAIProcessingConfirmation = () => {
  const dispatch = useDispatch();
  const { sendForAIProcessingConfirmation } = useSelector((state) => state.GlobalDialogues);
  const { open, data } = sendForAIProcessingConfirmation;
  const { documentId } = data;
  const { data: documentFiles = [] } = useGetAllFilesByDocumentIdQuery(documentId);

  const handleClose = () => dispatch(resetGlobalDialogues());

  const handleOkClick = async () => {
    dispatch(resetGlobalDialogues());
    const filesWithoutCoverAndDemandLetter = documentFiles.filter( (file) => !isDemandOrCoverLetter(file.fileEntityData.detectedType) );
    const fileIds = filesWithoutCoverAndDemandLetter.map(({ fileEntityId }) => ({ fileEntityId }));
    const aiProcessingResponse = await dispatch( apiSlice.endpoints.sendAiProcessing.initiate({ documentId, files: { files: fileIds } }) ).unwrap();
    dispatch(setDocumentStatus('AwaitingMedicalExtraction'));
    dispatch(pollPdfRegenerationStatus({ documentId }));
  };

  return (
    <ConfirmationDialog
      dialogOpen={open}
      handleCancel={handleClose}
      handleOk={handleOkClick}
      dialogText="Document edits will be disabled until AI processing is complete."
      additonalContent="Are you sure you want to send for AI processing?"
      okButtonText="Start AI processing"
      cancelButtonText="Cancel"
      maxWidth="sm"
    />
  );
};

export default SendForAIProcessingConfirmation;
