import React from 'react';
import { Button, Typography } from '@mui/material';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
        <div style={{ textAlign: 'center', marginTop: '200px' }}>

            <Typography
                variant="h4">
                Something went wrong:
            </Typography>

            <Typography
                sx={{ mt: 4, mb: 4 }}
                variant="h6"
            >
                {error.message}
            </Typography>

            <Button
                variant="outlined"
                onClick={resetErrorBoundary}
            >
                Try again
            </Button>
        </div>
    );
}

export default ErrorFallback;