/**
 * Custom Tiptap extension for inserting page breaks.
 *
 * Sources:
 * https://github.com/ueberdosis/tiptap/issues/3408
 * Logic is mostly borrowed from the Tiptap Horizontal Rule extension.
 * https://github.com/ueberdosis/tiptap/blob/main/packages/extension-horizontal-rule/src/horizontal-rule.ts
 */

import { Node } from '@tiptap/core';
import { TextSelection } from '@tiptap/pm/state';

const PageBreak = Node.create({
  name: 'pageBreak',

  addOptions() {
    return {
      HTMLAttributes: {
        style: 'page-break-after: always', // This CSS property has been replaced by the break-after property but break-after doesn't work with PSPDF.
        'data-page-break': 'true',
      },
    };
  },

  group: 'block',

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (node) => node.style.pageBreakAfter === 'always',
      },
    ];
  },

  renderHTML() {
    return ['div', this.options.HTMLAttributes];
  },

  addCommands() {
    return {
      insertPageBreak:
        () =>
        ({ chain }) => {
          return (
            chain()
              .insertContent({ type: this.name })
              // set cursor after page break
              .command(({ dispatch, tr }) => {
                if (dispatch) {
                  const { $to } = tr.selection;
                  const posAfter = $to.end();

                  if ($to.nodeAfter) {
                    tr.setSelection(TextSelection.create(tr.doc, $to.pos));
                  } else {
                    // add node after page break if it’s the end of the document
                    const node = $to.parent.type.contentMatch.defaultType?.create({
                      style: {
                        pageBreakAfter: 'always',
                      },
                      'data-page-break': 'true',
                    });

                    if (node) {
                      tr.insert(posAfter, node);
                      tr.setSelection(TextSelection.create(tr.doc, posAfter));
                    }
                  }

                  tr.scrollIntoView();
                }

                return true;
              })
              .run()
          );
        },
    };
  },
});

export default PageBreak;
