import { createSlice } from '@reduxjs/toolkit';
import { pollPdfRegenerationStatus } from './thunks';
import { updateLoadingState } from './helpers';

const initialState = {
    demandS3RegenerationLoadingData: {},
    demandS3RegenerationPollingRetryData: {},
    demandS3RegenerationSkeletonData: {}
};

export const PdfCustomSlice = createSlice({
    name: 'PdfCustomSlice',
    initialState,
    reducers: {
        setPdfUrlPollingLoadingStatus: (state, action) => {
            state.demandS3RegenerationLoadingData = {
                ...state.demandS3RegenerationLoadingData,
                ...action.payload
            }
        },
        setPdfUrlPollingRetryCount: (state, action) => {
            state.demandS3RegenerationPollingRetryData = {
                ...state.demandS3RegenerationPollingRetryData,
                ...action.payload
            }
        },
        setPdfUrlPollingLoadingSkeleton: (state, action) => {
            state.demandS3RegenerationSkeletonData = {
                ...state.demandS3RegenerationSkeletonData,
                ...action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(pollPdfRegenerationStatus.fulfilled, (state, action) => {
                action.payload === 'complete' && updateLoadingState(state, action, false)
            })
        }
    })

export const {
    setPdfUrlPollingLoadingStatus,
    setPdfUrlPollingRetryCount,
    setPdfUrlPollingLoadingSkeleton
} = PdfCustomSlice.actions;

export default PdfCustomSlice.reducer;