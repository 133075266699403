import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { RadioGroup, Alert, Snackbar, FormControlLabel, MenuItem, Radio, Box, Grid, Typography, Button, Stack, Card, TextField, OutlinedInput, InputAdornment, List, ListItem, ListItemText, IconButton, CircularProgress, Divider, Skeleton } from '@mui/material';
import { SearchRounded, CancelOutlined, EditOutlined, CloseRounded, SaveRounded, BadgeOutlined, DeleteOutline } from '@mui/icons-material';
import AdminTabs from '../CustomerManagement/AdminTabs';
import Title from '../CommonComponents/Title';
import awsExports from '../aws-exports';
import NoSearchResultsImage from '../assets/NoSearchResults.svg'
import { stateLabelValues } from '../common-data';
import { formatPhoneNumber, normalizeEmailAddress, useDebounce } from '../common';
import { searchCarriersApi } from '../api';
import StatusDialog from '../dialogs/StatusDialog';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import parse from 'html-react-parser'
import { useSelector } from 'react-redux';

export const ContactManagementView = (props) => {

    const defaultFormData = {defaultDeliveryChannelType: "none", aliases:[], email:"", fax:"", notes:"", carrierName:""};
    const rootApiUrl = awsExports.ROOT_API_URL;
    const [searchText, setSearchText] = useState("");
    const [selectedCarrier, setSelectedCarrier] = useState();
    const [searchResults, setSearchResults] = useState([]);
    const [allSearchResults, setAllSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [addNewCarrierOpen, setAddNewCarrierOpen] = useState(false);

    // Dialog State
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [confirmationDialogText, setConfirmationDialogText] = useState("");
    const [confirmationDialogTitle, setConfirmationDialogTitle] = useState("");
    const [saveConfirmationDialogOpen, setSaveConfirmationDialogOpen] = useState(false);
    const [saveConfirmationDialogText, setSaveConfirmationDialogText] = useState("");
    const [saveConfirmationDialogTitle, setSaveConfirmationDialogTitle] = useState("");
   
    // Carrier form state
    const [formData, setFormData] = useState(defaultFormData);
    const [orignalFormData, setOrignalFormData] = useState({...defaultFormData});
    const [formError, setFormError] = useState({});
    const [formDirty, setFormDirty] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [isNewCarrier, setIsNewCarrier] = useState(false);
    
    const { user } = useSelector(state => state.User);

    /*
     * APIs
     */

    const searchCarriers = async () => {
        
        setLoading(true);

        const response =  await searchCarriersApi(user, searchText);

        setLoading(false);

        if (response.status == 200) {
            const responseData = await response.json();
            setSearchResults(responseData);

            // Save the complete list for dupe checking. This may need to move to a server check
            // if we change the default search state.
            if (searchText == "") {
                setAllSearchResults(responseData);

                // Update the selected carrier with latest from server
                if (selectedCarrier) {
                    responseData.forEach((carrier) => {
                        if (carrier.carrierId == selectedCarrier.carrierId) {
                            setSelectedCarrier(carrier);
                        }
                    });
                }
            }
        }
        else {
            showStatusDialog("Error", "There was an error loading the carrier data.");
        }
    };
    
    const debouncedSearchCarriers = useDebounce( async () => {
        searchCarriers();
    }, 300, [searchResults]);

    const saveCarrierApi = async (carrierData) => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify(carrierData)
        };

        setSaving(true);

        const response = await fetch(
            rootApiUrl + "/carrier", settings
        ).then((response) => {
            if (response.status == 200) {
                setSaveConfirmationDialogOpen(false);
                showToast('The carrier has been saved.');
                handleCarrierSelected(carrierData);
                searchCarriers();
            }
            else {
                setSaveConfirmationDialogOpen(false);
                showStatusDialog('Save Carrier', 'There was an error saving the carrier data.');
            }

            return {};
        }).finally(() => {
            setSaving(false);
        });
    };

    const saveCarrier = () => {
        var carrier = isNewCarrier ? {} : selectedCarrier;
        if (isNewCarrier) {
            carrier["carrierId"] = uuidv4();
        }
        carrier["name"] = getFormData("carrierName");
        carrier["notes"] = getFormData("notes");
        carrier["defaultDeliveryChannelType"] = getFormData("defaultDeliveryChannelType");
        
        if (isNewCarrier) {
            carrier["deliveryChannels"] = [];
        }

        if (getFormData("email") && getFormData("email").length > 0) {
            if (isNewCarrier) {
                carrier["deliveryChannels"].push({
                    deliveryChannelId: uuidv4(),
                    deliveryChannelType: "email",
                    deliveryDestinationAddress: normalizeEmailAddress(getFormData("email"))
                });
            }
            else {
                updateDeliveryChannel(carrier, "email", normalizeEmailAddress(getFormData("email")));
            }
        }
        else {
            // Remove channel if it exists
            removeDeliveryChannel(carrier, "email");
        }

        if (getFormData("fax") && getFormData("fax").length > 0) {
            if (isNewCarrier) {
                carrier["deliveryChannels"].push({
                    deliveryChannelId: uuidv4(),
                    deliveryChannelType: "fax",
                    deliveryDestinationAddress: getFormData("fax")
                })
            }
            else {
                updateDeliveryChannel(carrier, "fax", getFormData("fax"));
            }
        }
        else {
            // Remove channel if it exists
            removeDeliveryChannel(carrier, "fax");
        }

        carrier["address"] = {
            street1: getFormData("street1"),
            street2: getFormData("street2"),
            city: getFormData("city"),
            state: getFormData("state"),
            zipCode: getFormData("zipCode")
        };

        
        carrier["aliases"] = [];
        carrier["aliases"].push(...formData["aliases"]);
 
        saveCarrierApi(carrier);
    };

    /*
     * Event Handlers & Helpers
     */

    const renderCityStateZip = (address) => {
        var addressStr = selectedCarrier.address.city;
        if (selectedCarrier.address.state) {
            addressStr += `, ${selectedCarrier.address.state}`;
        }
        if (selectedCarrier.address.zipCode) {
            addressStr += ` ${selectedCarrier.address.zipCode}`;
        }

        return addressStr;
    };

    const doesAddressHaveData = (address) => {
        return address.street1 || address.street2 || address.city || address.state || address.zipCode;
    };

    const highlight = (inputText, text) => {
        if (text.length === 0)
            return inputText;
        var index = inputText.toLowerCase().indexOf(text.toLowerCase());
        if (index >= 0) { 
            inputText = inputText.substring(0,index) + "<span class='highlight'>" + inputText.substring(index,index+text.length) + "</span>" + inputText.substring(index + text.length);
        }
        return inputText;
    };

    const checkDuplicateCarrier = (carrierName) => {

        for (var index=0; index < allSearchResults.length; ++index) {

            const carrier = allSearchResults[index];

            const existingCarrierName = carrier.name.toLowerCase().trim();
            const candidateCarrierName = carrierName.toLowerCase().trim();

            if (existingCarrierName === candidateCarrierName && (isNewCarrier || (!isNewCarrier && formData["carrierId"] !== carrier.carrierId))) {
                return [true,"carrier",carrier];
            }

            if (carrier.aliases.length > 0) {
               for (var aliasIndex=0; aliasIndex < carrier.aliases.length; ++aliasIndex) {
                    const alias = carrier.aliases[aliasIndex];
                    const existingAlias = alias.alias.toLowerCase().trim();
                    if (existingAlias === candidateCarrierName && (isNewCarrier || (!isNewCarrier && formData["carrierId"] !== carrier.carrierId))) {
                        return [true,"alias",carrier];
                    }
                }
            }
        }

        return  [false,"none", null];
    };

    const handleCancelFormOk = () => {
        resetFormData();
        setAddNewCarrierOpen(false);
        setConfirmationDialogOpen(false);
    };

    const handleCancelFormCancel = () => {
        setConfirmationDialogOpen(false);
    };

    const showToast = (msg) => {
        setToastMessage(msg);
        setToastOpen(true);
    };

    const handleToastClose = () => {
        setToastMessage("");
        setToastOpen(false);
    };

    const showStatusDialog = (title, text) => {
        setStatusDialogTitle(title);
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const handleRemoveAlias = (index) => {
        formData["aliases"].splice(index,1);
        setFormData({ ...formData });
    };

    const handleAddAlias = (e) => {
        formData["aliases"].push({
            "aliasId": uuidv4(),
            "alias": ""
        });
        setFormData({ ...formData });
    };

    const handleSaveCarrierConfirmOk = () =>{
        saveCarrier();
        
    };

    const handleSaveCarrierConfirmCancel = () =>{
        setSaveConfirmationDialogOpen(false);
    };

    const handleSaveCarrier = (e) => {
        setSaveConfirmationDialogTitle("Apply changes?");
        setSaveConfirmationDialogText("You updated global delivery information that will change the way Exchange delivers demands. Changes will apply immediately.");
        setSaveConfirmationDialogOpen(true);
    };

    const updateDeliveryChannel = (carrier, deliveryChannelType, deliveryDestinationAddress) => {
        var found = false;
        carrier.deliveryChannels.forEach((deliveryChannel) => {
            if (deliveryChannel.deliveryChannelType === deliveryChannelType) {
                deliveryChannel.deliveryDestinationAddress = deliveryDestinationAddress;
                found = true;
            }

        });

        if (!found) {
            carrier.deliveryChannels.push({
                deliveryChannelId: uuidv4(),
                deliveryChannelType: deliveryChannelType,
                deliveryDestinationAddress: deliveryDestinationAddress
            });
        }

        return carrier;
    };

    const removeDeliveryChannel = (carrier, deliveryChannelType) => {
        carrier.deliveryChannels = carrier.deliveryChannels.filter(deliveryChannel => deliveryChannelType !== deliveryChannel.deliveryChannelType);

        return carrier;
    };

    const handleSearchTextChanged = (e) => {
        setSearchText(e.target.value);
    };

    const handleCarrierSelected = (carrier) => {
        resetFormData();
        setAddNewCarrierOpen(false);
        setSelectedCarrier(carrier);
    };
 
    const handleAddNewCarrierClick = () => {
        setIsNewCarrier(true);
        resetFormData();
        setAddNewCarrierOpen(true);
    };
 
    const handleCancelEditClick = (e) => {
        if (JSON.stringify(orignalFormData) !== JSON.stringify(formData)) {
            setConfirmationDialogTitle("Discard changes?");
            setConfirmationDialogText("Are you sure you want to discard your work? Unsaved changes will be lost.");
            setConfirmationDialogOpen(true);
        }
        else {
            setAddNewCarrierOpen(false);
        }
    };

    const handleEditCarrierClick = () => {
        setIsNewCarrier(false);
        setFormValid(true);
        setFormDirty(false);
        mapCarrierToFormData(selectedCarrier);
        setAddNewCarrierOpen(true);
    };
    
    const mapCarrierToFormData = (carrier) => {
        
        formData["carrierId"] = carrier.carrierId;
        formData["carrierName"] = carrier.name;
        formData["notes"] = carrier.notes;
        formData["aliases"] =  carrier.aliases ? JSON.parse(JSON.stringify(carrier.aliases)) : [];
        carrier.deliveryChannels.forEach((deliveryChannel)=>{
            if (deliveryChannel.deliveryChannelType === "email") {
                formData["email"] = deliveryChannel.deliveryDestinationAddress;
            }
            else if (deliveryChannel.deliveryChannelType === "fax") {
                formData["fax"] = deliveryChannel.deliveryDestinationAddress;
            }
        });
        formData["defaultDeliveryChannelType"] = carrier.defaultDeliveryChannelType;
        formData["address"] = carrier.address || {}

        setFormData({...formData});
        setOrignalFormData(JSON.parse(JSON.stringify(formData)));
        setFormError({});
    };

    const getFormData = (id) => {
        if (id in formData) {
            return formData[id];
        }
        return "";
    };

    const resetFormData = () => {
        setFormDirty(false);
        setFormError({});
        setFormData({...defaultFormData});
        setOrignalFormData({...defaultFormData});
    };

    const handleAliasChange = (event, index, aliasId) => {
        formData["aliases"][index].alias = event.target.value;
        setFormData({ ...formData });
        validateForm();
    };

    const handleFormElementChange = (event, id) => {
        const elementId = id || event.target.id;
        if (event.target.type == 'checkbox') {
            formData[elementId] = event.target.checked
        }
        else {
            formData[elementId] = event.target.value;
        }

        setFormDirty(false);
        for (var key in formData) {
            if (formData[key] != "") {
                setFormDirty(true);
            }
        }
        
        if (elementId === "fax") {
            const formattedPhoneNumber = formatPhoneNumber(getFormData(elementId));
            formData[elementId] = formattedPhoneNumber;
        }

        setFormData({ ...formData });

        /**
         * Do general form validation
         */

        validateForm();
    };

    const validateForm = () => {
        
        setFormValid(true);
        var localFormError = {};

        // Check carrier name
        if (!formData['carrierName'] || formData['carrierName'].length == 0 ) {
            localFormError["carrierName"] =  "Carrier Name is required";
        }
        else {
            const [isError, matches, matchingCarrier] = checkDuplicateCarrier(formData['carrierName']);

            if (isError) {
                if (matches === 'carrier')
                    localFormError["carrierName"] =  `${formData['carrierName']} already exists as a carrier`;
                else
                    localFormError["carrierName"] =  `${formData['carrierName']} already exists as an alias for ${matchingCarrier.name}`;
            }
        }

        // Validate Aliases
        formData["aliases"].forEach((alias)=>{
            if (alias.alias) {
                const [isError, matches, matchingCarrier] = checkDuplicateCarrier(alias.alias)
                if (isError) {
                    localFormError[alias.aliasId] = `${matchingCarrier.name} already exists with this name or alias.`;            
                }   
                else {
                    localFormError[alias.aliasId] = "";
                } 
            }
            else {
                localFormError[alias.aliasId] = 'Alias name cannot be empty.'
            }
        });

        //
        // If we have a defaultDeliveryChannelType set, validate that the delivery channel has a value.
        //
        if (["email","fax"].includes(formData["defaultDeliveryChannelType"])) {
            const channelType = formData["defaultDeliveryChannelType"];
            var errorMsg = null;
            var channelTypeLabel = null;

            if (channelType === "email" && (!formData['email'] || formData['email'].length == 0)) {
                channelTypeLabel = "email address";
            }
            else if (channelType === "fax" && (!formData['fax'] || formData['fax'].length == 0)) {
                channelTypeLabel = "fax number";
            }

            errorMsg = `Default delivery channel type is ${channelType} but a ${channelTypeLabel} has not been configured`

            if (!formData[channelType] || formData[channelType].length == 0 ) {
                localFormError[channelType] = errorMsg;
            }
        }
        
        if (getFormData("fax") && !localFormError["fax"]) {
            const formattedPhoneNumber = formatPhoneNumber(getFormData("fax"));
            //strip anything past the "x"
            const withoutExtension = formattedPhoneNumber.split("x")[0];
            //there must be at least 10 digits in a valid phone number
            const digitsOnly = withoutExtension.replace(/[^0-9]/g, '');
            if (formattedPhoneNumber.length != 0 && digitsOnly.length < 10) {
                localFormError["fax"] = "Invalid phone number. It must have at least 10 digits." ;
            }
        }

        if (getFormData("email")  && !localFormError["email"]) {
            //validate that the email is in the correct format
            const emailRegex = /\S+@\S+\.\S+/;
            //if it is empty, or it is not in the correct format, show an error
            const email = normalizeEmailAddress(getFormData("email"));
            if (email.length != 0 && !emailRegex.test(email)) {
                localFormError["email"] = "Invalid email address";
            }
        }

        setFormValid(Object.keys(localFormError).filter((key) => localFormError[key].length > 0).length === 0);
        setFormError({...localFormError});
    }

    //  Hooks
    useEffect(() => {
        
        // If search text has a value then run the debounced search to save API calls.
        if (searchText == "")
            searchCarriers();
        else
            debouncedSearchCarriers();
    }, [searchText]);

    return (
        <Box padding={4} pt={8} display="flex" flexDirection="column" height="100vh" boxSizing="border-box">
            <Stack spacing={1} style={{ flex: 1}}>
                <AdminTabs selectedTab="contact-management"></AdminTabs>

                <Title>Contact database</Title>
                
                <Grid container={true} style={{ flex: 1 }}>
                    <Grid item={true} xs={3} style={{maxWidth:"360px"}}>
                        <Card sx={{height: "100%"}}>
                            <Stack marginTop={1}>
                                <Stack spacing={1} sx={{p:1}}>
                                    <OutlinedInput value={searchText} onChange={handleSearchTextChanged} placeholder='Search' size='small' 
                                        startAdornment={
                                            <InputAdornment position="start">
                                                 <SearchRounded />
                                            </InputAdornment>
                                        }
                                        
                                        endAdornment={  
                                            <InputAdornment position="start">
                                                {searchText.length == 0 ?
                                                    <></>
                                                : 
                                                !loading ?
                                                    <IconButton onClick={(e)=>{setSearchText("")}}>
                                                        <CancelOutlined/>
                                                    </IconButton>
                                                    :
                                                    <CircularProgress color='secondary' size={"1rem"}></CircularProgress>
                                                }
                                            </InputAdornment>
                                        }>
                                    </OutlinedInput>
                                    <Button variant='outlined' color='secondary' size='small' onClick={(e)=>{handleAddNewCarrierClick();}}>Add Carrier</Button>
                                </Stack>
                                <Stack>
                                {(!loading && searchResults && searchResults.length > 0) && (
                                    <List dense={true}>
                                    {(
                                        searchResults.map((carrier, index)=>{
                                            return (
                                                <ListItem key={carrier.carrierId} 
                                                        onClick={(e)=>{handleCarrierSelected(carrier)}}
                                                        className={selectedCarrier && selectedCarrier.carrierId === carrier.carrierId ? "carrier-item selected-carrier-item" : "carrier-item"}>
                                                    <ListItemText>
                                                        {parse(highlight(carrier.name, searchText))}
                                                    </ListItemText> 
                                                </ListItem>
                                            ) 
                                        })
                                    )}
                                    </List>
                                    )
                                }

                                {loading  && (
                                    <div style={{textAlign:"center",marginTop: "25px"}}>
                                        <CircularProgress />
                                    </div>
                                )}

                                {!loading && (!searchResults || searchResults.length === 0) && (
                                    <div style={{marginTop:"75px"}}>
                                        <div className="general-info-text" style={{marginBottom: "20px", textAlign: "center", lineHeight: "32px"}}>No search results</div>
                                        <div style={{textAlign: "center"}}><img src={NoSearchResultsImage} /></div>
                                    </div>
                                )}
                                </Stack>
                        </Stack>
                        </Card>
                    </Grid>

                    <Grid item xs={9}>
                        
                        { selectedCarrier && !addNewCarrierOpen ? 
                        
                        <Stack spacing={2} sx={{p:2, pt:0}}>
                            <Stack direction={"row"}>
                                <Stack spacing={1} direction={"row"} width={"35%"} display="flex" alignItems="left">
                                    <Typography variant="h4" color="primary">
                                        {selectedCarrier.name}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={2} width={"65%"} justifyContent={"flex-end"}>
                                    <IconButton onClick={handleEditCarrierClick}>
                                        <EditOutlined/>
                                    </IconButton>
                                </Stack>
                            </Stack>
                            
                            <Box sx={{mt: 2}}>
                                <Typography variant="h6" color="secondary">Aliases</Typography>

                                {selectedCarrier.aliases.length > 0 &&
                                    <List sx={{ listStyleType: 'disc', pt: 0, pb: 0 }}>
                                        {selectedCarrier.aliases.map((aliasItem, index)=>{
                                            return (
                                            <ListItem key={aliasItem.aliasId} sx={{pt:0,pb:0}}>
                                                <ListItemText sx={{ display: 'list-item' }}><Typography variant="body1" color="primary">{aliasItem.alias}</Typography></ListItemText>
                                            </ListItem>)
                                        }
                                        )}
                                    </List>
                                }

                                {selectedCarrier.aliases.length === 0 &&
                                    <div style={{paddingTop:"10px"}}>There are not any aliases configured.</div>
                                }
                            </Box>

                            <Box sx={{mt: 2}}>
                                <Typography variant="h6" color="secondary">Delivery Information</Typography>

                                {selectedCarrier.deliveryChannels.length > 0 &&
                                <Stack spacing={2} sx={{mt: 1}}>
                                    {selectedCarrier.deliveryChannels.map((channel, index)=>{
                                    return (
                                        <Stack key={channel.deliveryChannelType}>
                                            <Typography color={"text"} variant="inputLabel">{channel.deliveryChannelType}</Typography>
                                            <Typography variant="body1">{channel.deliveryDestinationAddress}</Typography>
                                        </Stack>
                                    )
                                    }
                                )}
                                    <Stack>
                                        <Typography color={"text"} variant="inputLabel">Default delivery method</Typography>
                                        <Typography variant="body1">{selectedCarrier.defaultDeliveryChannelType}</Typography>
                                    </Stack>
                                </Stack>
                                }

                                {selectedCarrier.deliveryChannels.length === 0 &&
                                    <div style={{paddingTop:"10px"}}>There are not any delivery channels configured.</div>
                                }
                            </Box>

                            <Box sx={{marginTop: "16px"}}>
                                <Typography variant="h6" color="secondary">Address</Typography>
                                
                                {doesAddressHaveData(selectedCarrier.address) && 
                                    <Stack>
                                        <Typography variant="body1">{selectedCarrier.address.street1}</Typography>
                                        <Typography variant="body1">{selectedCarrier.address.street2}</Typography>
                                        <Stack direction={"row"}>
                                            <Typography variant="body1">{renderCityStateZip(selectedCarrier.address)}</Typography>
                                        </Stack>
                                    </Stack>
                                }

                                {!doesAddressHaveData(selectedCarrier.address) && 
                                    <Typography variant="body1">No address for carrier</Typography>
                                }
                            </Box>

                            <Box sx={{marginTop: "16px"}}>
                                <Typography variant="h6" color="secondary">Notes</Typography>
                                <Typography variant="body1">{selectedCarrier.notes}</Typography>
                            </Box>
                        </Stack>
                        :
                        null}

                        {addNewCarrierOpen && 
                        
                        <Stack spacing={2} sx={{p:2, pt:0}}>
                            <Stack alignItems="left">
                                <Stack direction={"row"} >
                                    <Stack spacing={1} direction={"row"} width={"50%"} display="flex" alignItems="left">
                                        <Typography variant="h5" color="secondary">
                                        {isNewCarrier ? "Create new carrier" : "Update "+selectedCarrier.name}
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"} spacing={2} width={"50%"} justifyContent={"flex-end"}>
                                        <IconButton onClick={handleCancelEditClick}>
                                            <CloseRounded />
                                        </IconButton>

                                        <IconButton disabled={!formValid && !saving} onClick={(e)=>{handleSaveCarrier(e)}}>
                                            <SaveRounded />
                                        </IconButton>
                                    </Stack>
                                </Stack>

                                <TextField id="carrierName" required={true} placeholder="Name" onChange={(e) => { handleFormElementChange(e, 'carrierName') }} 
                                    error={formError.carrierName ? true : false}
                                    helperText={formError.carrierName || ''}
                                    value={getFormData("carrierName")}/>
                            
                                { getFormData('aliases').length > 0 ?
                                    <Divider sx={{mt:2,mb:1}} />
                                    : 
                                    null
                                }
                                <Stack spacing={2}>
                                { getFormData('aliases') && getFormData('aliases').map((alias, index)=>{
                                    return (
                                        <Stack key={alias.aliasId} spacing={1} direction="row">
                                            <TextField fullWidth placeholder="Alias" id={alias.aliasId} onChange={(e) => { handleAliasChange(e, index, alias.aliasId) }} 
                                                 disabled={alias?.isCarrierNameAlias}
                                                 error={formError[alias.aliasId] ? true : false}
                                                 helperText={formError[alias.aliasId] || ''}
                                                value={getFormData("aliases")[index].alias}/>
                                            { !alias?.isCarrierNameAlias &&
                                            <IconButton onClick={(e) => { handleRemoveAlias(index); }} >
                                                <DeleteOutline/>
                                            </IconButton>
                                            }
                                        </Stack>
                                    )
                                    })
                                }
                                </Stack>

                                <Button style={{width: "fit-content"}} variant='text' 
                                    onClick={handleAddAlias}
                                    startIcon={<BadgeOutlined />} color='secondary' >Add Alias</Button>
                            </Stack>
                            

                            <Box sx={{mt: 2}}>
                                <Stack spacing={2}>
                                    <Typography variant="h5" color="secondary">Delivery Information</Typography>

                                    <TextField id="email" placeholder="Email" onChange={(e) => { handleFormElementChange(e, 'email') }} 
                                        error={formError.email ? true : false}
                                        helperText={formError.email || ''}
                                        value={getFormData("email")}/>
                                
                                    <TextField id="fax" placeholder="Fax" onChange={(e) => { handleFormElementChange(e, 'fax') }}
                                        error={formError.fax ? true : false}
                                        helperText={formError.fax || ''}
                                        value={getFormData("fax")}/>
                                
                                    <Typography color={"text"} variant="body1">Default delivery method</Typography>
                                        
                                    <RadioGroup
                                        row
                                        aria-labelledby="ack-buttons-group-label"
                                        defaultValue="none"
                                        name="ack-buttons-group"
                                    >
                                        <FormControlLabel className='add-contact-label' value="email" control={<Radio onChange={(e) => { handleFormElementChange(e, 'defaultDeliveryChannelType') }}
                                            checked={getFormData('defaultDeliveryChannelType') == 'email'} />} label="Email" />
                                        <FormControlLabel className='add-contact-label' value="fax" control={<Radio onChange={(e) => { handleFormElementChange(e, 'defaultDeliveryChannelType') }}
                                            checked={getFormData('defaultDeliveryChannelType') == 'fax'} />} label="Fax" />
                                        <FormControlLabel className='add-contact-label' value="manual" control={<Radio onChange={(e) => { handleFormElementChange(e, 'defaultDeliveryChannelType') }}
                                            checked={getFormData('defaultDeliveryChannelType') == 'manual'} />} label="Manual" />
                                        <FormControlLabel className='add-contact-label' value="none" control={<Radio onChange={(e) => { handleFormElementChange(e, 'defaultDeliveryChannelType') }}
                                            checked={getFormData('defaultDeliveryChannelType') == 'none'} />} label="None" />
                                    </RadioGroup>

                                </Stack>

                            </Box>

                            <Box sx={{marginTop: "16px"}}>
                                    <Stack spacing={2}>
                                        <Typography variant="h5" color="secondary">Address</Typography>

                                        <TextField id="street1" required={true} placeholder="Line 1" onChange={(e) => { handleFormElementChange(e, 'street1') }} value={getFormData("street1")}/>
                                
                                        <TextField id="street2" required={true} placeholder="Line 2" onChange={(e) => { handleFormElementChange(e, 'street2') }} value={getFormData("street2")}/>
                            
                                        <Stack direction={"row"} spacing={2}>
                                            <TextField id="city" fullWidth required={true} placeholder="City" onChange={(e) => { handleFormElementChange(e, 'city') }} value={getFormData("city")}/>
                                            
                                            <TextField
                                                id="state"
                                                select
                                                label="State"
                                                fullWidth
                                                variant="outlined"
                                                onChange={(e) => { handleFormElementChange(e, 'state') }}
                                                value={getFormData('state')}
                                            >
                                                {stateLabelValues.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>


                                            <TextField id="zipCode" fullWidth required={true} placeholder="ZIP" onChange={(e) => { handleFormElementChange(e, 'zipCode') }} value={getFormData("zipCode")}/>
                                            
                                        </Stack>
                                    </Stack>
                                
                            </Box>

                            <Box sx={{marginTop: "16px"}}>
                                <Stack spacing={2}>
                                    <Typography variant="h5" color="secondary">Notes</Typography>
                                    <TextField multiline={true} rows={5} style={{width: "100%"}} onChange={(e) => { handleFormElementChange(e, 'notes') }} value={getFormData("notes")}>
                                    </TextField>
                                </Stack>
                            </Box>
                        </Stack>}

                    </Grid>
                </Grid>

            </Stack>

            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>
            <ConfirmationDialog handleOk={handleCancelFormOk} handleCancel={handleCancelFormCancel} okButtonText="Discard Changes" cancelButtonText="Cancel" dialogOpen={confirmationDialogOpen} dialogText={confirmationDialogText} dialogTitle={confirmationDialogTitle}></ConfirmationDialog>
            <ConfirmationDialog handleOk={handleSaveCarrierConfirmOk} handleCancel={handleSaveCarrierConfirmCancel} okButtonText="Apply Changes" cancelButtonText="Cancel" dialogOpen={saveConfirmationDialogOpen} dialogText={saveConfirmationDialogText} dialogTitle={saveConfirmationDialogTitle}></ConfirmationDialog>

            
            <Snackbar
                open={toastOpen}
                autoHideDuration={4000}
                onClose={handleToastClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>{toastMessage}</Alert>
            </Snackbar>

        </Box>
    )
}