import { createSlice } from "@reduxjs/toolkit";
import { fetchAuthorFormData } from "../thunks/demandData";

const AuthorSlice = createSlice({
    name: "Author",
    initialState: {
        approvers: [],
        carriers: [],
        users: [],

    },
    reducers: {
        // setAuthors: (state, action) => {
        //     // state.authors = action.payload;
        // },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAuthorFormData.fulfilled, (state, action) => {
            const { response } = action.payload;
            const { approvers, carriers, users } = response;
            state.approvers = approvers;
            state.carriers = carriers;
            state.users = users;
        })
        builder.addCase(fetchAuthorFormData.rejected, (state, action) => {
            console.error("Error fetching author form data: ", action.payload.error);
        });
    },

});

export const { setAuthors } = AuthorSlice.actions;

export default AuthorSlice.reducer;

