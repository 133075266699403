import { updateFilterState as updateContactLogFilterState, updateFilteredActiveDocuments as updateContactLogFilteredActiveDocuments } from '../redux/slices/contactLogInventorySlice'
import { updateFilterState as updateInventoryFilterState, updateFilteredActiveDocuments as updateInventoryFilteredActiveDocuments, updateFilteredContacts } from '../redux/slices/inventorySlice'

const countStatuses = (statusCounts, statusList, countAll = false) => {
    var count = 0;
    for (const [key, value] of Object.entries(statusCounts)) {
        if (countAll || statusList.includes(key)) {
            count += value;
        }
    }
    return count;
};

export const getFilterListDictionary = (data) => {

    const dictionary = {
        Inventory: {
            'All demands': {
                tooltipText: null,
                defaultToggleState: true,
                filteredData: data,
                getRowCount: (data, statusCounts, totalDocuments) => { return { rowCount: totalDocuments } }
            },
            'New uploaded': {
                tooltipText: "Submit pending edits to initiate demand processing",
                defaultToggleState: false,
                filteredData: [],
                getRowCount: (data, statusCounts) => {
                    const count = countStatuses(statusCounts, ['DocumentUploaded']);
                    return {
                        rowCount: count
                    }
                }
            },
            'Needs review': {
                tooltipText: "Submit review to initiate demand approval process",
                defaultToggleState: false,
                filteredData: [],
                getRowCount: (data, statusCounts) => {
                    const count = countStatuses(statusCounts, ['ReadyForReview']);
                    return {
                        rowCount: count
                    }
                }
            },
            'Ready for approval': {
                tooltipText: 'Submit approval for delivery to carrier',
                defaultToggleState: false,
                filteredData: [],
                getRowCount: (data, statusCounts) => {
                    const count = countStatuses(statusCounts, ['WaitingFirmApproval']);
                    return {
                        rowCount: count
                    }
                }
            },
            'Upcoming': {
                tooltipText: 'Demands are processing and will be available for review soon',
                defaultToggleState: false,
                filteredData: [],
                getRowCount: (data, statusCounts) => {
                    const count = countStatuses(statusCounts, ['AwaitingMedicalExtraction', 'AwaitingPrecedentSignoff']);
                    return {
                        rowCount: count
                    }
                }
            },
            'All': {
                tooltipText: null,
                defaultToggleState: true,
                filteredData: data,
                getRowCount: (data, statusCounts, totalDocuments) => {
                    return { rowCount: totalDocuments }
                }
            },
            'New': {
                tooltipText: null,
                defaultToggleState: false,
                filteredData: [],
                getRowCount: (data, statusCounts) => {
                    const count = countStatuses(statusCounts, ['DocumentUploaded', 'AwaitingMedicalExtraction']);
                    return {
                        rowCount: count
                    }
                }
            },
            'Pending signoff': {
                tooltipText: null,
                defaultToggleState: false,
                filteredData: [],
                getRowCount: (data, statusCounts) => {
                    const count = countStatuses(statusCounts, ['AwaitingPrecedentSignoff']);
                    return {
                        rowCount: count
                    }
                }
            },
            'Pending processing': {
                tooltipText: null,
                defaultToggleState: false,
                filteredData: [],
                getRowCount: (data, statusCounts) => {
                    const count = countStatuses(statusCounts, ['PendingProcessing']);
                    return {
                        rowCount: count
                    }
                }
            },
            'Pending review': {
                tooltipText: null,
                defaultToggleState: false,
                filteredData: [],
                getRowCount: (data, statusCounts) => {
                    const count = countStatuses(statusCounts, ['ReadyForReview']);
                    return {
                        rowCount: count
                    }
                }
            },
            'Pending approval': {
                tooltipText: null,
                defaultToggleState: false,
                filteredData: [],
                getRowCount: (data, statusCounts) => {
                    var count = countStatuses(statusCounts, ['WaitingFirmApproval']);
                    return {
                        rowCount: count
                    }
                }
            }
        },
        ContactLogInventory: {
            'All demands': {
                tooltipText: null,
                defaultToggleState: true,
                filteredData: data,
                getRowCount: (data, statusCounts, totalDocuments) => { return { rowCount: totalDocuments } }
            },
            'Needs initial call': {
                tooltipText: 'Demands where carriers have not been contacted',
                defaultToggleState: false,
                filteredData: [],
                getRowCount: (data, statusCounts) => {
                    const count = countStatuses(statusCounts, ['NeedsInitialContact']);
                    return {
                        rowCount: count
                    }
                }
            },
            'Needs receipt acknowledged': {
                tooltipText: 'Demands delivered to carrier pending receipt acknowledged',
                defaultToggleState: false,
                filteredData: [],
                getRowCount: (data, statusCounts) => {
                    const count = countStatuses(statusCounts, ['DocumentSubmitted']);
                    return {
                        rowCount: count
                    }
                }
            },
            'Pending signoff': {
                tooltipText: null,
                defaultToggleState: false,
                filteredData: [],
                getRowCount: (data, statusCounts) => {
                    const count = countStatuses(statusCounts, ['AwaitingPrecedentSignoff']);
                    return {
                        rowCount: count
                    }
                }
            },
            'Pending processing': {
                tooltipText: null,
                defaultToggleState: false,
                filteredData: [],
                getRowCount: (data, statusCounts) => {
                    const count = countStatuses(statusCounts, ['PendingProcessing']);
                    return {
                        rowCount: count
                    }
                }
            },
        }
    }

    return dictionary;
}


export const filterDispatchDictionary = {
    ContactLogInventory: { updateFilterState: updateContactLogFilterState, updateFilteredActiveDocuments: updateContactLogFilteredActiveDocuments },
    Inventory: { updateFilterState: updateInventoryFilterState, updateFilteredActiveDocuments: updateInventoryFilteredActiveDocuments, updateFilteredContacts: updateFilteredContacts }
}