import awsExports from './aws-exports';
import React, { useEffect,useState, createRef } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Title from './CommonComponents/Title'
import {formatCurrency} from './common';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import EnclosuresTable from './EnclosuresTable';
import { useSelector, useDispatch } from 'react-redux'
import { toggle } from './redux/slices/accordionSlice'


const AdjusterViewerHighlights = (props) => {

    const rootApiUrl = awsExports.ROOT_API_URL;
    const [totalLostWages, setTotalLostWages] = useState(0.0);
    const [totalPastLostWages, setTotalPastLostWages] = useState(0.0);
    const [totalFutureLostWages, setTotalFutureLostWages] = useState(0.0);
    const [totalOtherDamages, setTotalOtherDamages] = useState(0.0);
    const [totalPastOtherDamages, setTotalPastOtherDamages] = useState(0.0);
    const [totalFutureOtherDamages, setTotalFutureOtherDamages] = useState(0.0);
    const [totalGeneralDamages, setTotalGeneralDamages] = useState(0.0);
    const [totalPastGeneralDamages, setTotalPastGeneralDamages] = useState(0.0);
    const [totalFutureMedicals, setTotalFutureMedicals] = useState(0.0);
    const [totalPastMedicals, setTotalPastMedicals] = useState(0.0);
    const [detailsPaneTitle, setDetailsPaneTitle] = useState("");
    const [detailsPaneOpen, setDetailsPaneOpen] = useState(false);
    const [currentDocumentUrl, setCurrentDocumentUrl] = useState("");
  
    const dispatch = useDispatch();
    const frameRef = createRef();
    const demandDoc = props.demandDoc;

    const panels = useSelector(state => state.accordion.panels);
        
    const getAttachmentS3Url = async (attachmentId) => {
        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: props.user.signInUserSession.accessToken.jwtToken
            }
        };

        const response = await fetch(
            rootApiUrl+"/attachment/"+attachmentId, settings
        ).then((response) => response.json());
    
       return response.s3Url;
    };

    const downloadAttachment = async (attachmentId) => {

        // Load the documents
        const s3_url = await getAttachmentS3Url(attachmentId);

        var xhr = new XMLHttpRequest();
        xhr.open("GET", s3_url);
        xhr.responseType = "blob";
        xhr.onload = function () {
            const link = document.createElement("a");
            link.download = findAttachment(attachmentId).attachmentName;
            link.href =  URL.createObjectURL(xhr.response);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }; 
        xhr.send();
    };


    const findAttachment = (attachmentId) => {
        for (let i = 0; i < demandDoc.attachments.length; i++) {
            const attachment = demandDoc.attachments[i];
            if (attachment.attachmentId == attachmentId)
                return attachment;
        }

        return null;
    };

    const resizeIframe = () => {
        if (frameRef.current != null) {
            let h = window.innerHeight-150;    
            h = 700;
            frameRef.current.style.height = h+'px';
        }
    };

    const hideDetailsPanel = () => {
        setDetailsPaneOpen(false);
    };

    const viewLiabilityHighlightsPane = async (attachmentId, title) => {
        setDetailsPaneTitle(title);

        // Load the documents
        const s3_url = await getAttachmentS3Url(attachmentId);
        setCurrentDocumentUrl(s3_url);

        // Open the panel
        setDetailsPaneOpen(true);
    };
    
    const handleAccordionChange = (panel) => (event, newExpanded) => {
        dispatch(toggle({id:panel,expanded:newExpanded}));
    };

    const isExpanded = (panelId) => {
        const matchingPanel = panels.find(panel => panel.id === panelId)
        if (matchingPanel) {
            return matchingPanel.expanded;
        }
        else {
            return panelId == 'facts' ? true : false;
        }
    } 

    useEffect(() => {
        let total = 0.0;
        let totalPastLostWages = 0.0
        let totalFutureLostWages = 0.0
        demandDoc.lostWages.map((d) => {
            const lw = parseFloat(d.grossWages);
            total += lw;

            if (d.futureIndicator == true) {
                totalFutureLostWages += lw;
            }
            else {
                totalPastLostWages +=lw;
            }
        }
        );
        setTotalLostWages(total);
        setTotalPastLostWages(totalPastLostWages);
        setTotalFutureLostWages(totalFutureLostWages);
    
        total = 0.0;
        let totalPastOther = 0.0
        let totalFutureOther = 0.0
        demandDoc.otherDamages.map((d) => {
            const damage = parseFloat(d.otherDamageAmount);
            total += damage;
            if (d.futureIndicator == true) {
                totalFutureOther += damage;
            }
            else {
                totalPastOther += damage;
            }
        }
        );
        setTotalOtherDamages(total);
        setTotalPastOtherDamages(totalPastOther);
        setTotalFutureOtherDamages(totalFutureOther);
    
        total = 0.0;
        let totalPast = 0.0;
        demandDoc.generalDamages.map((d) => {
            if (d.futureIndicator == true) {
                total += parseFloat(d.estimatedAmount);
            }
            else {
                totalPast += parseFloat(d.estimatedAmount);
            }

        }
        );
        setTotalGeneralDamages(total);
        setTotalPastGeneralDamages(totalPast);
    
        total = 0.0;
        demandDoc.futureMedicals.map((d) => {
            total += parseFloat(d.estimatedAmount);
        }
        );
        setTotalFutureMedicals(total);
    
        total = 0.0;
        demandDoc.courseOfTreatment.map((d) => {
            total += parseFloat(d.billedAmount);
        }
        );
        setTotalPastMedicals(total);
    }, []);
    
    useEffect(() => {
        resizeIframe();
    }, [frameRef]);

    return (
        <Grid container sx={{mt:1}}>
        <Grid xs={detailsPaneOpen ? 6 : 8} >
            <Accordion sx={{mb:1}} expanded={isExpanded('facts')} onChange={handleAccordionChange('facts')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon color='secondary'/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid container xs={12}>
                        <Grid xs={4}>
                            <Title>Facts & Liability</Title>
                        </Grid>
                        <Grid xs={8} textAlign={"right"}>
                            <Stack direction={"row"} sx={{marginRight:'5px'}}justifyContent={"flex-end"}>
                            {demandDoc.liability.demandTags.map((tag,index) => {
                                return (
                                    <div className="highlight-tag">{tag.tagName}</div>
                                )
                            })
                            }
                            </Stack>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                   <Stack className='highlights-text-block'>
                        <div style={{marginBottom: "15px"}}>
                            {demandDoc.liability.lossDescriptionNarrative}
                        </div>

                        <div>
                            {demandDoc.liability.liabilityDescriptionNarrative}
                        </div>

                        <div  className="section-hr" />

                        <EnclosuresTable showTitle={true} demandDoc={demandDoc} attachments={demandDoc.liability.referencedAttachments} viewTitle="Facts & Liability Highlights" downloadAttachment={downloadAttachment} viewAttachment={viewLiabilityHighlightsPane} />
        
                        <div  className="section-hr" />

                        <Stack direction={"row"} justifyContent={"flex-end"} spacing={2} style={{marginTop: '20px'}}>
                            <Button color='secondary' variant="outlined" onClick={()=>{props.changeTab(2)}}>Facts & Liability</Button>
                        </Stack>
                   </Stack>
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{mb:1}} expanded={isExpanded('injury')} onChange={handleAccordionChange('injury')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon color='secondary'/>}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Grid container xs={12}>
                        <Grid xs={4}>
                            <Title>Injury & Treatments</Title>
                        </Grid>
                        <Grid xs={8} textAlign={"right"}>
                            <Stack direction={"row"} sx={{marginRight:'5px'}}justifyContent={"flex-end"}>
                            {demandDoc.injuryNarrative.demandTags.map((tag,index) => {
                                return (
                                    <div className="highlight-tag">{tag.tagName}</div>
                                )
                            })
                            }
                            </Stack>                                            
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <Stack className='highlights-text-block'>
                        <div style={{marginBottom: "15px"}}>
                            {demandDoc.injuryNarrative.injuryDescription}
                        </div>

                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow key="header">
                                        <TableCell sx={{fontWeight:'bold'}}>Body Part</TableCell>
                                        <TableCell sx={{fontWeight:'bold'}}>Type</TableCell>
                                        <TableCell width="30%" sx={{fontWeight:'bold'}}>Diagnosis Comments</TableCell>
                                        <TableCell sx={{fontWeight:'bold'}}>Diagnosis Date</TableCell>
                                        <TableCell sx={{fontWeight:'bold'}}>Provider</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {demandDoc.injuries.map((injury,index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{injury.injuryBodyPart}</TableCell>
                                                <TableCell>{injury.injuryType}</TableCell>
                                                <TableCell>{injury.injuryDiagnosisComments}</TableCell>
                                                <TableCell align='center'>{injury.injuryDiagnosisDate}</TableCell>
                                                <TableCell>{injury.medicalProvider ? injury.medicalProvider.businessName : ""}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div style={{marginTop:"15px"}} />

                        <EnclosuresTable showTitle={true} demandDoc={demandDoc} attachments={demandDoc.injuryNarrative.referencedAttachments} viewTitle="Injury Highlights" downloadAttachment={downloadAttachment} viewAttachment={viewLiabilityHighlightsPane} />
        
                   </Stack>
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{mb:1}} expanded={isExpanded('damages')} onChange={handleAccordionChange('damages')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon color='secondary' />}
                aria-controls="panel2c-content"
                id="panel2c-header"
                >
                    <Grid container xs={12}>
                        <Grid xs={4}>
                            <Title>Damages:  {formatCurrency(totalFutureMedicals+totalGeneralDamages+totalOtherDamages+totalLostWages+totalPastMedicals+totalPastGeneralDamages)} </Title>
                        </Grid>
                        <Grid xs={8} textAlign={"right"}>
                            <Stack direction={"row"} sx={{marginRight:'5px'}}justifyContent={"flex-end"}>
                            {demandDoc.damagesSummary.demandTags.map((tag,index) => {
                                return (
                                    <div className="highlight-tag">{tag.tagName}</div>
                                )
                            })
                            }
                            </Stack>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell width={100} sx={{fontWeight:'bold'}}>Item</TableCell>
                                <TableCell sx={{fontWeight:'bold'}}>Past</TableCell>
                                <TableCell sx={{fontWeight:'bold'}}>Future</TableCell>
                                <TableCell sx={{fontWeight:'bold'}}>Total</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key="1">
                                    <TableCell sx={{fontWeight:'bold'}}>Economic</TableCell>
                                    <TableCell sx={{fontWeight:'bold'}}>
                                         {formatCurrency(totalOtherDamages+totalLostWages+totalPastMedicals)}
                                    </TableCell>
                                    <TableCell sx={{fontWeight:'bold'}}>{formatCurrency(totalFutureMedicals)}</TableCell>
                                    <TableCell sx={{fontWeight:'bold'}}>
                                        {formatCurrency(totalOtherDamages+totalLostWages+totalPastMedicals+totalFutureMedicals)}
                                    </TableCell>
                                </TableRow>
                                <TableRow key="2">
                                    <TableCell sx={{paddingLeft:'35px'}}>Medical</TableCell>
                                    <TableCell>
                                        {formatCurrency(totalPastMedicals)}
                                    </TableCell>
                                    <TableCell> {formatCurrency(totalFutureMedicals)}</TableCell>
                                    <TableCell>{formatCurrency(totalFutureMedicals+totalPastMedicals)}</TableCell>
                                </TableRow>
                                <TableRow key="3">
                                    <TableCell  sx={{paddingLeft:'35px'}}>Lost Wages</TableCell>
                                    <TableCell component="th" scope="row">
                                        {formatCurrency(totalPastLostWages)}
                                    </TableCell>
                                    <TableCell>{formatCurrency(totalFutureLostWages)}</TableCell>
                                    <TableCell>{formatCurrency(totalLostWages)}</TableCell>
                                </TableRow>
                                <TableRow key="4">
                                    <TableCell  sx={{paddingLeft:'35px'}}>Other</TableCell>
                                    <TableCell component="th" scope="row">
                                        {formatCurrency(totalPastOtherDamages)}
                                    </TableCell>
                                    <TableCell>{formatCurrency(totalFutureOtherDamages)}</TableCell>
                                    <TableCell>{formatCurrency(totalOtherDamages)}</TableCell>
                                </TableRow>
                                <TableRow key="5">
                                    <TableCell sx={{fontWeight:'bold'}}>Non-Economic</TableCell>
                                    <TableCell sx={{fontWeight:'bold'}}>
                                     {formatCurrency(totalPastGeneralDamages)}
                                    </TableCell>
                                    <TableCell sx={{fontWeight:'bold'}}>{formatCurrency(totalGeneralDamages)}</TableCell>
                                    <TableCell sx={{fontWeight:'bold'}}>{formatCurrency(totalGeneralDamages+totalPastGeneralDamages)}</TableCell>
                                </TableRow>
                                <TableRow key="6">
                                    <TableCell className="secondary" sx={{fontWeight:'bold'}}>Total</TableCell>
                                    <TableCell className="secondary" sx={{fontWeight:'bold'}}>
                                        {formatCurrency(totalOtherDamages+totalLostWages+totalPastMedicals+totalPastGeneralDamages)}
                                    </TableCell>
                                    <TableCell className="secondary" sx={{fontWeight:'bold'}}>
                                         {formatCurrency(totalFutureMedicals+totalGeneralDamages)}
                                    </TableCell>
                                    <TableCell className="secondary" sx={{fontWeight:'bold'}}>
                                        {formatCurrency(totalFutureMedicals+totalGeneralDamages+totalOtherDamages+totalLostWages+totalPastMedicals+totalPastGeneralDamages)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div  className="section-hr" />

                    <EnclosuresTable showTitle={true}  demandDoc={demandDoc} attachments={demandDoc.damagesSummary.referencedAttachments} viewTitle="Damages Highlights" downloadAttachment={downloadAttachment} viewAttachment={viewLiabilityHighlightsPane} />
        
                    <div  className="section-hr" />

                    <Stack direction={"row"} justifyContent={"flex-end"} spacing={2} sx={{mt:3}}>
                        <Button color='secondary' variant="outlined" onClick={()=>{props.changeTab(5)}}>Pain & Suffering</Button>
                        <Button color='secondary' variant="outlined" onClick={()=>{props.changeTab(4)}}>Future Damages</Button>
                        <Button color='secondary' variant="outlined" onClick={()=>{props.changeTab(3)}}>Incurred Damages</Button>
                    </Stack>
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{mb:1}} expanded={isExpanded('settle')} onChange={handleAccordionChange('settle')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon color='secondary' />}
                aria-controls="panel3d-content"
                id="panel3d-header"
                >
                <Title>Offer To Settle: {formatCurrency(demandDoc.demandDetails.demandOfferToSettleAmount)} </Title>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <Stack className='highlights-text-block'>
                            <div style={{marginBottom: "15px"}}>
                                {demandDoc.demandDetails.offerToSettleNarrative}
                            </div>
                        </Stack>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={isExpanded('conditions')} onChange={handleAccordionChange('conditions')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon color='secondary' />}
                aria-controls="panel2e-content"
                id="panel2e-header"
                >
                <Title>Settlement Conditions</Title>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                   
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow key="header">
                                <TableCell width={100} sx={{fontWeight:'bold'}}>Type</TableCell>
                                <TableCell sx={{fontWeight:'bold'}}>Description</TableCell>
                                <TableCell sx={{fontWeight:'bold'}}>Due Date</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {demandDoc.settlementConditionsSummary.settlementConditions.map((row,index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.settlementConditionType}
                                </TableCell>
                                <TableCell>{row.settlementConditionTerm}</TableCell>
                                <TableCell>{row.settlementConditionDueDate}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody> 
                        </Table>
                    </TableContainer>
                    
                    <div  className="section-hr" />

                    <EnclosuresTable showTitle={true}  demandDoc={demandDoc} attachments={demandDoc.settlementConditionsSummary.referencedAttachments} viewTitle="Settlement Conditions Highlights" downloadAttachment={downloadAttachment} viewAttachment={viewLiabilityHighlightsPane} />

                    <Stack direction={"row"} justifyContent={"flex-end"} spacing={2} sx={{mt:3}}>
                    </Stack>
                </Typography>
                </AccordionDetails>
            </Accordion>
        </Grid>
        <Grid xs={detailsPaneOpen ? 6 : 4} >
            {detailsPaneOpen ? (
            <div style={{ height: "100%", marginLeft:"10px" }}>
                <Stack direction={"column"}>
                    <Stack direction={"row"}>
                        <Grid container xs={12}>
                            <Grid xs={6} >
                                <Title>{detailsPaneTitle}</Title>
                            </Grid>
                            <Grid xs={6} textAlign={"right"}>
                                <IconButton title='Close Details' style={{width:'25px'}} onClick={() => { hideDetailsPanel()}}>
                                <Close color='secondary'/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Stack>
                   
                    <div ref={frameRef}>
                        <iframe onLoad={resizeIframe()} id="documentFrame" src={currentDocumentUrl} title="Document" width={"100%"} height={"100%"}>
                        </iframe>
                    </div>
                </Stack>
            </div>
            ) : null }
        </Grid>
    </Grid>
    )

}

export default AdjusterViewerHighlights;