
export const dateComparator = (v1, v2) => {
    const d1 =  new Date(v1);
    const d2 =  new Date(v2);
    return d1-d2;
 };

export const currencyComparator = (v1, v2) => {
     v1 = v1.replace("$","").replaceAll(",","");
     v2 = v2.replace("$","").replaceAll(",","");
     if (v1=="") 
         v1 = 0;
     if (v2=="") 
         v2 = 0;
     v1= parseFloat(v1);
     v2= parseFloat(v2);
     return v1 - v2;
 };
