import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirmDeleteFile, resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { setShouldDeleteFile } from '../redux/slices/documentSlice';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';

const ConfirmDeleteFile = () => {
  const dispatch = useDispatch();

  const { confirmDeleteFile } = useSelector((state) => state.GlobalDialogues);
  const { open, data } = confirmDeleteFile;
  const { fileName } = data;

  const handleDeleteFileClick = async () => {
    dispatch(setShouldDeleteFile(true));
    dispatch(setConfirmDeleteFile({ open: false, data: {} }));
  };

  const handleClose = () => dispatch(resetGlobalDialogues());

  return (
    <ConfirmationDialog
      dialogOpen={open}
      handleCancel={handleClose}
      handleOk={handleDeleteFileClick}
      dialogText={`${fileName} will be removed from the demand immediately. Are you sure you want to delete?`}
      okButtonText="Delete"
      cancelButtonText="Cancel"
      buttonColor="error"
    />
  );
};

export default ConfirmDeleteFile;
