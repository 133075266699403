import { Box, LinearProgress, Paper, Skeleton, Stack } from '@mui/material'
import React from 'react'

const PDFSkeletonLoader = ({ height }) => {
    return (
        <Box
            sx={{
                height: height
            }}
        >
            <Stack
                sx={{
                    borderRadius: '4px',
                    border: '1px solid',
                    borderColor: 'divider',
                    background: '#F6F8FA',
                    height: '100%',
                    overflow: 'auto'
                }}>
                <Box
                >
                    <Paper
                        elevation={1}
                        sx={{
                            width: '100%',
                            height: 44,
                            background: '#FCFDFE',
                            borderRadius: 0
                        }}>
                    </Paper>
                    <LinearProgress variant="indeterminate" />
                </Box>

                <Stack
                    spacing={2}
                    px={4}
                    py={2}
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Skeleton variant='rectangular' width={'100%'} height={'100%'} />
                </Stack>
            </Stack>
        </Box>
    )
}

export default PDFSkeletonLoader