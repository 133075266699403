import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from '../Transition';
import { Button, Stack } from '@mui/material';

const ConfirmationDialog = ({
  handleCancel,
  handleOk,
  dialogOpen,
  dialogTitle,
  dialogText,
  cancelButtonText,
  okButtonText,
  buttonColor,
  additonalContent,
  enableOkButton = true,
  maxWidth = 'xs',
}) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      open={dialogOpen}
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
    >
      {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
      <DialogContent>
        <Stack spacing={2}>
          <DialogContentText id="alert-dialog-slide-description">{dialogText}</DialogContentText>

          <DialogContentText>{additonalContent && additonalContent}</DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => handleCancel()}>
          {cancelButtonText}
        </Button>{' '}
        <Button
          disabled={!enableOkButton}
          variant={'contained'}
          color={buttonColor || 'secondary'}
          onClick={() => {
            handleOk();
          }}
        >
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
