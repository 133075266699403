import { Stack, Typography } from "@mui/material";

const cancelText = 'Cancel';
const exitText = 'Exit';

export const DiscardWork = {
    text: <Stack spacing={2}>
        <Typography
            variant="body1"
            color="primary"
        >
            Unsaved work will be lost.
        </Typography>
        <Typography
            variant="body1"
            color="primary"
        >
            Are you sure you want to exit?
        </Typography>
    </Stack>,
    confirmText: exitText,
    cancelText: cancelText
}