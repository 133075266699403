import { createSlice } from '@reduxjs/toolkit';

const initialMriFindingsSlice = {
  mriSelectedRows: [],
  mriFindingsUpdateLoading: false
};

export const MriFindingsSlice = createSlice({
  name: 'MriFindings',
  initialState: initialMriFindingsSlice,
  reducers: {
    setMriSelectedRows: (state, action) => {
      state.mriSelectedRows = action.payload;
    },
    setMriFindingsUpdateLoading: (state, action) => {
      state.mriFindingsUpdateLoading = action.payload
    },
  },
});

export const {
  setMriSelectedRows,
  setMriFindingsUpdateLoading,
} = MriFindingsSlice.actions;

export default MriFindingsSlice.reducer;
