import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState } from "react";
import { Stack, IconButton, Typography, Tab, Tabs, Box } from '@mui/material';
import { WestRounded } from '@mui/icons-material';
import awsExports from '../aws-exports';
import AdminTabs from '../CustomerManagement/AdminTabs';
import UsersView from './UsersView';
import CustomerConfigView from './CustomerConfigView';
import DemandLettersView from './DemandLetters/DemandLettersView';
import { setCustomerData, setPrecedentUsers } from '../redux/slices/adminEditTemplateSlice';
import { useSelector, useDispatch } from 'react-redux';

export const CustomerManagementView = () => {
    const dispatch = useDispatch();
    const { customerData } = useSelector((state) => state.AdminEditTemplate);

    const buildFullName = (user) => {
        return user.firstName + " " + user.lastName
    };

    const getPrecedentUsersApi = () => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify({ customerId: "precedent" })
        };

        fetch(rootApiUrl + "/users", settings)
            .then((response) => response.json())
            .then((response) => {
                const precedentUsersList = []
                if (Array.isArray(response)) {
                    response.forEach(element => {
                        const name = buildFullName(element);
                        precedentUsersList.push({ value: element.emailAddress, label: name, emailAddress: element.emailAddress, userId: element.userId});
                    });
                }
                dispatch(setPrecedentUsers(precedentUsersList));
            })
            .catch((error) => console.log(error))
            .finally(() => {
                setLoading(false);
            });
    };

    const { user } = useSelector(state => state.User);
    var { customerId } = useParams();
    const rootApiUrl = awsExports.ROOT_API_URL;
    const [loading, setLoading] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const navigate = useNavigate();

    const getCustomerApi = async () => {
        if (customerId == "new") {
            dispatch(setCustomerData({
                "customerId": uuidv4(),
                "name": "",
                "customerEntityData": {},
                "firmApprovalRemindersConfig": [
                    {
                        "minutesFromStart": 1440,
                        "subject_filename": "email_subject_reminder1.txt",
                        "body_txt_filename": "email_body_reminder.txt",
                        "body_html_filename": "email_body_reminder.html",
                        "cc_flags": [],
                        "reminder_name": "first_reminder"
                    },
                    {
                        "minutesFromStart": 2880,
                        "subject_filename": "email_subject_reminder1.txt",
                        "body_txt_filename": "email_body_reminder.txt",
                        "body_html_filename": "email_body_reminder.html",
                        "cc_flags": [
                            "supervisorCC"
                        ],
                        "reminder_name": "second_reminder"
                    },
                    {
                        "minutesFromStart": 4320,
                        "subject_filename": "email_subject_reminder3.txt",
                        "body_txt_filename": "email_body_reminder.txt",
                        "body_html_filename": "email_body_reminder.html",
                        "cc_flags": [
                            "supervisorCC",
                            "firmApprovalCC"
                        ],
                        "reminder_name": "third_reminder"
                    }
                ],
                "isNew": true,
                "customerType": "lawfirm"
            }));
        }
        else {
            const settings = {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: user.signInUserSession.accessToken.jwtToken
                },
            };

            setLoading(true);

            const response = await fetch(
                rootApiUrl + `/customer/${customerId}`, settings
            ).then((response) => response.json())
                .finally(() => {
                    setLoading(false);
                });

            response["isNew"] = false;
            dispatch(setCustomerData(response));
        }
    };



    /*
     * Handlers
     */

    const customerUpdatedCallback = (updatedCustomerId) => {
        customerId = updatedCustomerId;
        navigate("/customer-management/" + customerId);
    };

    const handleShowAllCustomersClick = () => {
        navigate('/customer-management');
    };

    const handleTabChanged = (e) => {
        setTabValue(e.target.tabIndex);
    };

    /*
     * Hooks
     */

    useEffect(() => {
        getCustomerApi();
        getPrecedentUsersApi();
    }, [customerId]);

    return (
        <Box padding={4} pt={8}>
            <Stack spacing={2}>
                <AdminTabs selectedTab="customer-management"></AdminTabs>

                {customerData &&

                    <>
                        <div style={{ marginTop: "20px" }} >
                            <Stack direction={"row"} spacing={1} style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}>
                                <IconButton onClick={handleShowAllCustomersClick} color='primary'>
                                    <WestRounded />
                                </IconButton>
                                <Typography variant='h6' alignItems={"flex-start"}>{customerData.name}</Typography>
                            </Stack>
                        </div>

                        <Stack direction={"row"} spacing={2} style={{ width: "100%" }}>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={tabValue}
                                onChange={handleTabChanged}
                                aria-label="Customer admin tabs"
                                sx={{ borderRight: 1, borderColor: 'rgba(0,0,0,.12)', width: '168px', minWidth: '168px' }}
                            >
                                <Tab label="Properties" id="properties-tab" tabIndex={0} />
                                <Tab label="Users" id="users-tab" tabIndex={1} disabled={customerId == "new"} />
                                <Tab label="Demand letters" id="demand-letters-tab" tabIndex={2} disabled={customerId == "new"} />
                            </Tabs>

                            <Box
                                sx={{
                                    width: "calc(100vw - 224px)"
                                }}
                            >

                                {tabValue == 0 &&
                                    <CustomerConfigView
                                        updateCallback={customerUpdatedCallback}
                                        user={user}
                                    >
                                    </CustomerConfigView>
                                }


                                {tabValue == 1 &&
                                    <UsersView
                                        user={user}
                                    >
                                    </UsersView>
                                }

                                {tabValue == 2 &&
                                    <DemandLettersView
                                        user={user}
                                    >
                                    </DemandLettersView>
                                }


                            </Box>
                        </Stack>
                    </>
                }
            </Stack>
        </Box>
    );

}
