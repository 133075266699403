import awsExports from './aws-exports';
import React, { useEffect,useState, createRef } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Title from './CommonComponents/Title'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {formatAddress, formatCurrency,formatDate} from './common';
import {getAttachmentS3Url, downloadAttachment} from './attachments_api';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import KeyboardBackspaceRounded from '@mui/icons-material/KeyboardBackspaceRounded';
import EnclosuresTable from './EnclosuresTable';
import Button from '@mui/material/Button';

const AdjusterViewerTreatmentCourse= (props) => {

    const primaryActionsColor = "rgba(0, 0, 0, 0.56)";
    const [detailsPaneOpen, setDetailsPaneOpen] = useState(false);
    const [currentDocumentUrl, setCurrentDocumentUrl] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [otherAttachments, setOtherAttachments] = useState([]);
    const [totalLostWages, setTotalLostWages] = useState(0.0);
    const [totalOtherDamages, settTotalOtherDamages] = useState(0.0);
    const [totalCOT, setTotalCOT] = useState(0.0);
    
    const frameRef = createRef();
    const leftFrameRef = createRef();
    const demandDoc = props.demandDoc;
    const treatmentCourse = props.treatmentCourse;
    
    const handleTreatmentClick = (courseOfTreatment) => {
        //setTreatmentInEdit(courseOfTreatment);
        //setTreatmentOpen(true);
    };

    const findAttachment = (attachmentId) => {
        for (let i = 0; i < demandDoc.attachments.length; i++) {
            const attachment = demandDoc.attachments[i];
            if (attachment.attachmentId == attachmentId)
                return attachment;
        }

        return null;
    };

    const resizeIframe = () => {
        if (frameRef.current != null) {
            let h = window.innerHeight-150;    
            h = 700;
            frameRef.current.style.height = h+'px';
            leftFrameRef.current.style.height = frameRef.current.style.height
        }
    };

    const hideDetailsPanel = () => {
        setDetailsPaneOpen(false);
    };

    const viewIncurrecDamagesClick = () => {
        props.backCallback();
    };

    const viewDetailsPanelCallback = async (attachmentId, title) => {
    
        // Load the documents
        const s3_url = await getAttachmentS3Url(attachmentId);
        setCurrentDocumentUrl(s3_url);

        // Open the panel
        setDetailsPaneOpen(true);
    };

    const downloadAttachmentCallback = async (attachmentId) => {
        downloadAttachment(findAttachment(attachmentId));
    };

    useEffect(() => {
    
        const tempAttachments = [];
        for (let i = 0; i < props.demandDoc.attachments.length; i++) {
            const attachment = props.demandDoc.attachments[i];

            if (attachment.associatedDemandEntity.demandSection == 'IncurredDamages' &&
                (attachment.associatedDemandEntity.associatedDemandEntityId ==  treatmentCourse.treatmentCourseId)) {
                tempAttachments.push(attachment);
            }
        }
        setAttachments([...tempAttachments]);

    }, []);
    
    useEffect(() => {
        resizeIframe();
    }, [frameRef,leftFrameRef]);

    return (
    <Grid container sx={{mt:1}}>
        <Grid xs={detailsPaneOpen ? 6 : 12} >
            <Box ref={leftFrameRef} style={{overflowY:'auto'}}>
            
                <Box sx={{mt:3, mb:3}}>
                    <Button onClick={viewIncurrecDamagesClick} size='large'><><KeyboardBackspaceRounded />Incurred Damages</></Button>
                </Box>

                <Stack spacing={3} width={detailsPaneOpen ? "100%" : "50%"}>
                    <Accordion elevation={3} sx={{mb:1}} defaultExpanded={true}>
                        <AccordionSummary
                            sx={{margin:0}}
                            expandIcon={<ExpandMoreIcon color='secondary'/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Title>{treatmentCourse.treatmentType}</Title>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>

                                <Grid container xs={12} className="doc-content-section adjuster-form-data">
                                    <Grid xs={3} className="adjuster-form-label">
                                        Start Date
                                    </Grid>
                                    <Grid xs={9}>
                                        {formatDate(treatmentCourse.startDate)}
                                    </Grid>

                                    <Grid xs={3} className="adjuster-form-label">
                                        End Date
                                    </Grid>
                                    <Grid xs={9}>
                                        {formatDate(treatmentCourse.endDate)}
                                    </Grid>

                                    <Grid xs={3} className="adjuster-form-label">
                                        Number of Visits
                                    </Grid>
                                    <Grid xs={9}>
                                        {treatmentCourse.treatmentEncounters.length}
                                    </Grid>

                                    <Grid xs={3} className="adjuster-form-label">
                                        Provider
                                    </Grid>
                                    <Grid xs={9}>
                                        {treatmentCourse.medicalProvider.businessName}
                                    </Grid>

                                    <Grid xs={3} className="adjuster-form-label">
                                        Provider TIN
                                    </Grid>
                                    <Grid xs={9}>
                                        {treatmentCourse.medicalProvider.providerTIN}
                                    </Grid>

                                    <Grid xs={3} className="adjuster-form-label">
                                        Incurred Damages
                                    </Grid>
                                    <Grid xs={9}>
                                        {formatCurrency(treatmentCourse.billedAmount)}
                                    </Grid>
                                </Grid>

                                <Stack direction={"row"} justifyContent={"flex-end"} >
                                   
                                </Stack>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                        
                    <Accordion elevation={3} sx={{mb:1}} defaultExpanded={false}>
                        <AccordionSummary
                            sx={{margin:0}}
                            expandIcon={<ExpandMoreIcon color='secondary'/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Title>Incurred Damages: {formatCurrency(treatmentCourse.billedAmount)}</Title>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{fontWeight:'bold'}}>Date</TableCell>
                                                <TableCell sx={{fontWeight:'bold'}}>Provider</TableCell>
                                                <TableCell sx={{fontWeight:'bold'}}>Billed Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {treatmentCourse.treatmentEncounters.map((encounter) => {
                                                return (
                                                    <TableRow key={encounter.treatmentLineItemId}>
                                                        <TableCell>{encounter.startDate}</TableCell>
                                                        <TableCell>{encounter.primaryPhysician}</TableCell>
                                                        <TableCell>{encounter.billedAmount}</TableCell>
                                                    </TableRow>
                                                )
                                            }
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion elevation={3} sx={{mb:1}} defaultExpanded={false}>
                        <AccordionSummary
                            sx={{margin:0}}
                            expandIcon={<ExpandMoreIcon color='secondary'/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Title>Enclosures ({attachments.length})</Title>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>

                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Stack>
            </Box>
        </Grid>
        <Grid xs={detailsPaneOpen ? 6 : 0} >
            {detailsPaneOpen ? (
            <div style={{ height: "100%", marginLeft:"20px" }}>
                <Stack direction={"column"}>
                    <Stack direction={"row"}>
                        <Grid container xs={12}>
                            <Grid xs={6} >
                                <Title>Enclosures</Title>
                            </Grid>
                            <Grid xs={6} textAlign={"right"}>
                                <IconButton title='Close Details' style={{width:'25px'}} onClick={() => { hideDetailsPanel()}}>
                                <Close color='secondary'/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Stack>
                   
                    <div ref={frameRef}>
                        <iframe onLoad={resizeIframe()} id="documentFrame" src={currentDocumentUrl} title="Document" width={"100%"} height={"100%"}>
                        </iframe>
                    </div>
                </Stack>
            </div>
            ) : null }
        </Grid>
    </Grid>
    )

}

export default AdjusterViewerTreatmentCourse;