
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import { CircularProgress, Grid, Stack, TextField, ThemeProvider } from '@mui/material';
import awsExports from './aws-exports';
import { theme } from './Theme'
import Title from './CommonComponents/Title';
import { Box } from "@mui/system";


export const ShortCodeResolver = (props) => {

    const rootApiUrl = awsExports.ROOT_API_URL;
    const { shortCode } = useParams();
    const navigate = useNavigate()

    const resolveShortCode = async () => {
        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        };

        const response = await fetch(
            rootApiUrl + "/shortcode/" + shortCode, settings
        ).then((response) => response.json()).catch(error => console.log("Error: ", error));

        const docId = response.documentId

        if (docId) {
            navigate("/m/" + docId)  // Navigate to the document page
        } else {
            navigate("/404")  // If we can't resolve a document then wherever they're trying to go is a 404
        }
    };

    // Triggers on page load or shortCode change
    useEffect(() => {
        if (shortCode) {
            resolveShortCode()
        }
    }, [shortCode]);


    return (
        <Container maxWidth='100' sx={{
            flexGrow: 1,
            height: '100%',
            overflow: 'auto',
            padding: '10px'
        }}>
            <ThemeProvider theme={theme}>
                <>
                    <div style={{ position: 'fixed', zIndex: 1, top: 63, width: '97%', backgroundColor: "#F3FAFF" }}>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ minHeight: "100vh" }}
                        >
                            <Grid item>
                                <CircularProgress size={80} />
                            </Grid>
                        </Grid>
                    </div>
                </>
            </ThemeProvider>
        </Container>

    );
};
