import React from 'react'
import ConfirmationDialog from '../dialogs/ConfirmationDialog'
import { Alert, AlertTitle, FormControlLabel, FormGroup, Stack, Switch } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux';
import { setAttorneyOverrideReview, setAttorneySend } from '../redux/slices/globalDialogueSlice';
import { setOverrideReview } from '../redux/slices/demandDomSlice';

const OverrideReview = ({ carrierCommonName }) => {
    const dispatch = useDispatch();
    const inventoryState = useSelector((state) => state.Inventory);

    const { attorneyOverrideReview } = useSelector((state) => state.GlobalDialogues);
    const { overrideReview } = useSelector((state) => state.DemandDom);

    const handleAttorneyConfirmationDialogClose = () => {
        dispatch(setAttorneyOverrideReview({ open: false }));
        dispatch(setOverrideReview(false));
    };

    const handleApproveDemandClick = (event) => {
        dispatch(setAttorneySend({ open: true }));
    };

    const handleAttorneyConfirmationDialogOk = () => {
        handleAttorneyConfirmationDialogClose();
        handleApproveDemandClick(inventoryState.row.documentId, inventoryState.row.attorneyContactEmailAddress);
        dispatch(setAttorneySend({ open: true }));
    };

    const handleReviewOverride = () => dispatch(setOverrideReview(!overrideReview));

    return (
        <ConfirmationDialog
            dialogOpen={attorneyOverrideReview.open}
            dialogText={
                <Stack spacing={2}>
                    <Alert
                        severity='warning'
                    >
                        <AlertTitle>
                            Review in progress
                        </AlertTitle>
                        Demand may not be ready to deliver to {carrierCommonName}.
                    </Alert>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={overrideReview}
                                    onChange={handleReviewOverride}
                                    color="secondary"
                                />
                            } label="I am sure I want to send for approval"
                        />
                    </FormGroup>
                </Stack>
            }
            cancelButtonText={'Cancel'}
            okButtonText={'Continue'}
            handleCancel={handleAttorneyConfirmationDialogClose}
            handleOk={handleAttorneyConfirmationDialogOk}
            enableOkButton={overrideReview}
        />
    )
}

export default OverrideReview