
import React, { useState, useEffect } from "react";
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, Button, Box, Typography, Stack } from '@mui/material';
import { Transition } from '../Transition'
import { sendToCarrierApi } from '../api';
import StatusDialog from "./StatusDialog";
import { getMedicalTreatmentAlerts } from "../MedicalsComponents/medicals";
import ConfirmationSwitch from '../CommonComponents/ConfirmationSwitch';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDocumentData } from '../redux/thunks/documentData';
import LoadingButton from '@mui/lab/LoadingButton';
import { apiSlice } from '../services/apiSlice';
import { MEDICAL_CHARGES_URL_PATH } from '../MedicalsComponents/insights';

const CarrierSendDialog = ({ handleClose, dialogOpen, documentId, user, carrierName, handler, viewType, source }) => {
    const dispatch = useDispatch();
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [medicalTreatmentAlerts, setMedicalTreatmentAlerts] = useState([]);
    const [switchChecked, setSwitchChecked] = useState(false);
    const { documentData } = useSelector((state) => state.Document);
    const documentDataLoading = useSelector((state) => state.Document.documentDataLoading)[documentId] ?? false;
    const { medicals, medicalsLoading } = useSelector((state) => state.Medicals);
    const hasAlerts = medicalTreatmentAlerts.length > 0;
    const buttonIsDisabled = (hasAlerts && !switchChecked) || documentDataLoading || medicalsLoading;
    const currentDocumentFetched = documentData?.documentId === documentId;

    useEffect(() => {
        if (source === 'inventory') {
            dispatch(fetchDocumentData({ documentId, user }));
            dispatch(
                apiSlice.endpoints.getEntityInsights.initiate(
                    { documentId, insightsType: MEDICAL_CHARGES_URL_PATH },
                    { forceRefetch: true }
                )
            );
        }
    }, [documentId, user, source, dispatch]);

    useEffect(() => {
        if (!documentDataLoading && !medicalsLoading && currentDocumentFetched) {
            setMedicalTreatmentAlerts(getMedicalTreatmentAlerts(documentData, viewType, true, true, medicals));
        }
    }, [documentDataLoading, medicalsLoading, currentDocumentFetched, documentData, viewType, medicals]);

    const showStatusDialog = (text) => {
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const sendToCarrier = async (userTriggered) => {

        if (userTriggered) {
            handleClose();
            setStatusCloseDisabled(true);
            showStatusDialog("Sending demand to " + carrierName + "...")
        }

        sendToCarrierApi(documentId, user)
            .then((response) => {
                if (response.status === 200) {
                    showStatusDialog("Success! The demand has been sent to " + carrierName);
                    setStatusCloseDisabled(false);
                    handler(true);
                }
                else if (response.status === 201 || response.status === 409) {
                    setStatusDialogText("The demand is being regenerated. This should only take a few moments.");
                    setTimeout(function () { sendToCarrier(false) }, 3000);
                }
                else {
                    setStatusCloseDisabled(false);
                    showStatusDialog("There was an error sending the email :-(");
                    handler(false);
                }
            });
    }

    return (
        <>
            <Dialog
                maxWidth="xs"
                open={dialogOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent
                    sx={{
                        my: 0
                    }}
                >
                    {hasAlerts && (
                        <Box>
                            <Stack
                                spacing={1}
                            >
                                {medicalTreatmentAlerts.map((alert, index) => {
                                    return (
                                        <Alert key={index} severity={alert.alertSeverity}>{alert.alertMessage}</Alert>
                                    )
                                }
                                )}
                            </Stack>
                            <ConfirmationSwitch setSwitchChecked={setSwitchChecked} switchChecked={switchChecked} labelText='Send demand despite warnings'></ConfirmationSwitch>
                        </Box>
                    )}


                    <DialogContentText id="alert-dialog-slide-description">
                        <Stack spacing={2}>
                            <Typography variant="body1" color={"primary"}>
                                Demand will be submitted to <b>{carrierName}</b> by Precedent.
                            </Typography>
                            <Typography variant="body1" color={"primary"}>
                                Are you sure you want to send?
                            </Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => { handleClose() }}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        disabled={buttonIsDisabled}
                        variant="contained"
                        color="secondary"
                        onClick={() => { sendToCarrier(true) }}
                        loading={documentDataLoading || medicalsLoading}
                    >
                        Approve and send
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>
        </>
    )

}

export default CarrierSendDialog;