import React, { useState, useMemo, useEffect } from 'react';
import { getLatestTemplateContentFromTemplateId } from '../../api';
import { DataGridPro, getGridDateOperators, useGridApiRef } from '@mui/x-data-grid-pro';
import { Box, IconButton, Stack, Typography, Link, Chip, Button } from '@mui/material';
import  CustomMuiDialogWrapper from '../../CommonComponents/CustomMuiDialogWrapper';
import { dateComparator } from '../../comparators';
import { convertUTCDateTimeToLocalDate, convertUTCDateTimeToLocalTime } from '../../common';
import { ContentCopyOutlined, FileDownloadOutlined } from '@mui/icons-material';
import EditOrCreateTemplate from './EditOrCreateTemplate';
import ColumnMenu from '../../CommonComponents/DataGrid/ColumnMenu';
import { Transition } from '../../Transition'
import { setAllTemplateRows, setIsEditorOpen, setCustomerData, resetFormFieldsAndMetadata } from '../../redux/slices/adminEditTemplateSlice';
import { fetchTemplateDataById, fetchAvailableTemplatesForCustomer } from '../../redux/thunks/adminEditTemplateData';
import { fetchTemplateDocumentUsage } from '../../redux/thunks/templateData';
import { fetchCodeEditorContent } from '../../redux/thunks/codeEditorData';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from '../../redux/slices/globalToastSlice';
import { handleCreateTemplateButtonClick } from '../helpers';


const dateOperators = getGridDateOperators().filter((operator) => operator.value === 'is');

const DemandLettersView = ({ user }) => {
  const dispatch = useDispatch();
  const AdminEditTemplateState = useSelector((state) => state.AdminEditTemplate);
  const { availableTemplates, availableTemplatesLoading, formMetadata, customerData, precedentDefaultTemplateId, precedentUsers, allCustomerUsers } = AdminEditTemplateState;
  
  const isEditOpen = formMetadata.isOpen;
  const selectedCustomer = customerData;

  const usersList = [...precedentUsers, ...allCustomerUsers];
  const [currentRow, setCurrentRow] = useState({});

  const handleOpenEditor = () => dispatch(setIsEditorOpen(true));
  const handleFetchCodeEditorContent = (templateId) => dispatch(fetchCodeEditorContent({ templateId, user, parentComponentName: 'AdminEditTemplate' }));


  const handleOpenEditTemplate = (row) => {
    dispatch(fetchTemplateDataById({ templateId: row.id, user }));
    handleFetchCodeEditorContent(row.id);
    handleOpenEditor();
    dispatch(setCustomerData(selectedCustomer));
    dispatch(fetchTemplateDocumentUsage({ templateId: row.id, user }));
    setCurrentRow(row);
  };

  const handleCloseEditTemplate = () => {
    dispatch(setIsEditorOpen(false))
    dispatch(resetFormFieldsAndMetadata());
  };

  const handleFileDownload = (templateId) => {
    getLatestTemplateContentFromTemplateId(templateId, user)
      .then((response) => {
        const element = document.createElement('a');
        const file = new Blob([response.content], { type: 'text/html' });
        element.href = URL.createObjectURL(file);
        element.download = `${templateId}.html`;
        document.body.appendChild(element);
        element.click();
      })
      .catch((err) => console.error(err));
  };

  const styles = {
    '.truncate': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& .hidden-button': {
      display: 'none',
    },
    '&:hover .hidden-button': {
      display: 'flex',
    },
    cursor: 'pointer',
    width: '100%',
    height: '100%',
  };

  const defaultTemplateId = selectedCustomer?.defaultTemplateId;

  const copyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
    dispatch(setToast({ isOpen: true, message: 'Copied to clipboard!', severity: 'success' }));
  };

  const apiRef = useGridApiRef();

  const columns = [
    {
      field: 'templateName',
      headerName: 'Name',
      width: 200,
      renderCell: (params) => {
        return (
          <Stack
            sx={{
              width: '100%',
              py: .5
            }}
          >
            <Link
              component="button"
              onClick={() => handleOpenEditTemplate(params.row)}
              sx={{
                textDecoration: 'none',
                textAlign: 'left'
              }}
            >
              <Typography variant="tableP1" color="secondary">
                {params.value}
              </Typography>
            </Link>
            {params.row.id === defaultTemplateId && <Typography variant="tableP2">Default template</Typography>}
          </Stack>
        );
      },
      pinnable: false,
    },
    {
      field: 'version',
      headerName: 'Version',
      align: 'left',
      headerAlign: 'left',
      pinnable: false,
      resizable: false,
      filterable: false,
      width: 250,
      renderCell: (params) => {
        const status = params.row.status.toLowerCase();
        return (
          <Stack
            direction='row'
            spacing={1}
            alignItems={'center'}
          >
            <Typography
              variant='tableP1'
              sx={{
                minWidth: '30px'
              }}
            >
              {params.row.version}
            </Typography>
            <Chip
              label={status === 'unpublished' ? 'Live- updates available' : status[0].toUpperCase() + status.slice(1)}
              variant={status === 'live' ? 'filled' : 'outlined'}
              color={status === 'live' ? 'success' : 'default'}
              sx={{
                color: status === 'live' ? 'white' : status === 'unpublished' ? 'success.dark' : 'rgba(0,0,0,0.87)',
                borderColor: status === 'unpublished' ? 'success.dark' : 'rgba(0,0,0,0.23)',
                backgroundColor: status === 'live' ? 'success.dark' : 'transparent',
              }}
            />

          </Stack>
        )
      }
    },
    {
      field: 'currentVersionCount',
      headerName: 'On current',
      align: 'center',
      pinnable: true,
      resizable: true,
      filterable: true,
    },
    {
      field: 'olderVersionsCount',
      headerName: 'On legacy',
      align: 'center',
      pinnable: true,
      resizable: true,
      filterable: true,
    },
    {
      field: 'templateId',
      headerName: 'Template ID',
      pinnable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={styles}
            onClick={(e) => copyToClipboard(params.row.id)}
          >
            <Typography variant="tableP1" className="truncate">
              {params.row.id}
            </Typography>
            <IconButton size="small" onClick={(e) => copyToClipboard(params.row.id)} color="" className="hidden-button">
              <ContentCopyOutlined />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: 'versionId',
      headerName: 'Version ID',
      pinnable: false,
      sortable: false,
      renderCell: (params) => {
        const url = params.row.s3url;
        let versionId = url.split('/').pop().replace('.html', '');
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems={'center'}
            justifyContent={'space-between'}
            width={'100%'}
            sx={styles}
            onClick={(e) => copyToClipboard(versionId)}
          >
            <Typography variant="tableP1" className="truncate">
              {versionId}
            </Typography>
            <IconButton size="small" onClick={(e) => copyToClipboard(versionId)} className="hidden-button">
              <ContentCopyOutlined />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: 'createdTs',
      headerName: 'Last save',
      sortComparator: dateComparator,
      filterOperators: dateOperators,
      valueGetter: (params) => (params.row.createdTs ? new Date(params.row.createdTs * 1000) : 0),
      renderCell: (params) => {
        return (
          <Stack>
            <Typography variant="tableP1">
              {convertUTCDateTimeToLocalDate(new Date(params.row.createdTs * 1000))}
            </Typography>
            <Typography variant="tableP2">
              {convertUTCDateTimeToLocalTime(new Date(params.row.createdTs * 1000))}
            </Typography>
          </Stack>
        );
      },
      pinnable: false,
    },
    {
      field: 'userIdLastSavedBy',
      headerName: 'Saved by',
      width: 150,
      valueGetter: (params) => {
        const user = usersList.find((user) => user.userId === params.value) || {}
        return user?.label || params?.value || '';
      },
      pinnable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        return (
          <Stack direction={'row'} spacing={1}>
            <IconButton onClick={(e) => handleFileDownload(params.row.templateId)}>
              <FileDownloadOutlined />
            </IconButton>
          </Stack>
        );
      },
      disableColumnMenu: true,
      sortable: false
    },
  ];

  const rows = useMemo(() => {
    const mappedTemplates = availableTemplates
      .map(({
        templateName,
        version,
        userIdLastSavedBy,
        s3url,
        createdTs,
        templateId,
        usage,
        status
      }) => {
        return {
          id: templateId,
          templateName,
          version,
          userIdLastSavedBy,
          s3url,
          createdTs,
          templateId,
          currentVersionCount: usage?.currentVersionCount || 0,
          olderVersionsCount: usage?.olderVersionsCount || 0,
          currentVersion: usage?.currentVersion || 0,
          status: status || 'Draft',
        };
      })
      .sort((a, b) => {
        if (a.id === defaultTemplateId) {
          return -1;
        }
        if (b.id === defaultTemplateId) {
          return 1;
        }
        return b.createdTs - a.createdTs;
      });
    dispatch(setAllTemplateRows(mappedTemplates));
    return mappedTemplates;
  }, [availableTemplates]);

  useEffect(() => {
    user && selectedCustomer?.customerId && dispatch(fetchAvailableTemplatesForCustomer({ user, customerId: selectedCustomer?.customerId }));
  }, [user, selectedCustomer?.customerId]);

  return (
    <Box height="75vh" sx={{ mt: '-50px' }}>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <Button
          variant="outlined"
          color='secondary'
          onClick={e => handleCreateTemplateButtonClick(precedentDefaultTemplateId, dispatch, user)}
        >
          Create template
        </Button>
      </Box>
      <DataGridPro
        rows={rows}
        disableRowSelectionOnClick
        columns={columns}
        loading={availableTemplatesLoading || !availableTemplates.length > 0}
        disableColumnSelector
        getRowHeight={() => 'auto'}
        apiRef={apiRef}
        onColumnWidthChange={() => apiRef.current.resetRowHeights()}
        slots={{
          columnMenu: ColumnMenu
        }}
      />

      <CustomMuiDialogWrapper
        open={isEditOpen}
        fullWidth={true}
        fullScreen={true}
        TransitionComponent={Transition}
      >
        <EditOrCreateTemplate
          customerName={selectedCustomer.name}
          user={user}
          onClose={handleCloseEditTemplate}
          row={currentRow}
        />

      </CustomMuiDialogWrapper>

    </Box>
  );
};

export default DemandLettersView;
