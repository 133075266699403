import React from 'react';
import { FormControlLabel, Switch, DialogContentText } from '@mui/material';

const ConfirmationSwitch = ({ setSwitchChecked, switchChecked, labelText }) => {
    return (
        <FormControlLabel
            control={
                <Switch
                    onChange={() => setSwitchChecked(!switchChecked)}
                    checked={switchChecked}
                    color="secondary"
                    sx={{
                        my: 2,
                    }}
                />
            }
            label={<DialogContentText id="alert-dialog-slide-description">{labelText}</DialogContentText>}
        />
    );
};

export default ConfirmationSwitch;
