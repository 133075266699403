import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const AuthenticationLoadingSpinner = () =>
    <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1300 }} >
        <CircularProgress size={60} thickness={4} />
        <Typography variant="h6" mt={2}>
            Authenticating...
        </Typography>
    </Box>

export default AuthenticationLoadingSpinner;