import React, { useState } from "react";
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Avatar, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, IconButton, Link, Paper, Radio, Stack, Tooltip } from '@mui/material';

// importing assets
import GoogleAuthAppLogo from './assets/googleAuthAppLogo.svg'
import MicrosoftAuthAppLogo from './assets/microsoftAuthAppLogo.svg'
import AppStoreBadge from './assets/appStoreBadge.svg'
import GooglePlayBadge from './assets/googlePlayBadge.svg'

import AddMicrosoftAccount from './assets/ma-addAccount.png'
import AddGoogleAccount from './assets/ga-addAccount.png'
import SetUpMicrosoftAccount from './assets/ma-setUpAccount.png'
import SetUpGoogleAccount from './assets/ga-setUpAccount.png'
import { grey } from "@mui/material/colors";
import { LinkOutlined } from "@mui/icons-material";

const badgeStyle = { height: '40px' };
const microsoftAuthLinks = {
    apple: 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458',
    google: 'https://play.google.com/store/apps/details?id=com.azure.authenticator&pcampaignid=web_share'
};

const googleAuthLinks = {
    apple: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
    google: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share'
}

const AuthInstructions = ({ qrCode, timeOut }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [authenticator, setAuthenticator] = useState('')

    const handleMicrosoftClick = () => {
        setAuthenticator('Microsoft');
    }

    const handleGoogleClick = () => {
        setAuthenticator('Google')
    }

    const steps = [
        {
            label: 'Grab your phone to download an authenticator app',
            description: `Select the authenticator you downloaded`,
            component: [
                <Grid container spacing={2} sx={{ mt: 1 }} alignItems="stretch">
                    <Grid item xs={12} md={6}>
                        <Card sx={{ pb: 1, border: authenticator === 'Microsoft' ? '1px solid #232941' : '' }}>
                            <CardHeader
                                avatar={
                                    <img src={MicrosoftAuthAppLogo} style={{ height: '40px', width: '40px' }} />
                                }
                                title="Microsoft Authenticator"
                                sx={{ pb: 0 }}
                            />
                            <CardContent>
                                <Stack spacing={1}>
                                    <FormControlLabel value="google" control={<Radio checked={authenticator === 'Microsoft'} onClick={handleMicrosoftClick} />} label="I downloaded Microsoft Authenticator" sx={{ pb: 2 }} />
                                    <Stack direction="row" spacing={1} alignItems={'center'}>
                                        <LinkOutlined />
                                        <Typography variant="h6">
                                            Download links
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                                        <Link href={microsoftAuthLinks.apple} target="_blank" rel="noopener">
                                            <img src={AppStoreBadge} style={badgeStyle} />
                                        </Link>
                                        <Link href={microsoftAuthLinks.google} target="_blank" rel="noopener">
                                            <img src={GooglePlayBadge} style={badgeStyle} />
                                        </Link>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card sx={{ pb: 1, border: authenticator === 'Google' ? '1px solid #232941' : '' }}>
                            <CardHeader
                                avatar={
                                    <img src={GoogleAuthAppLogo} style={{ height: '40px', width: '40px' }} />
                                }
                                title="Google Authenticator"
                                sx={{ pb: 0 }}
                            />
                            <CardContent>
                                <Stack spacing={1}>
                                    <FormControlLabel value="google" control={<Radio checked={authenticator === 'Google'} onClick={handleGoogleClick} />} label="I downloaded Google Authenticator" sx={{ pb: 2 }} />
                                    <Stack direction="row" spacing={1} alignItems={'center'}>
                                        <LinkOutlined />
                                        <Typography variant="h6">
                                            Download links
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                                        <Link href={googleAuthLinks.apple} target="_blank" rel="noopener">
                                            <img src={AppStoreBadge} style={badgeStyle} />
                                        </Link>
                                        <Link href={googleAuthLinks.google} target="_blank" rel="noopener">
                                            <img src={GooglePlayBadge} style={badgeStyle} />
                                        </Link>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>



            ]
        },
        {
            label: `Add a new account into ${authenticator} Authenticator`,
            description:
                null,
            component: [
                <Stack direction="column" spacing={2} sx={{ my: 3 }} alignItems={"center"}>
                    {authenticator === "Microsoft" &&
                        <Stack direction="column" spacing={2} alignItems={"center"}>
                            <Typography variant="h6">Click the plus on the top right</Typography>
                            <img src={AddMicrosoftAccount} style={{ height: "auto", width: 'auto', maxWidth: "400px", textAlign: "center" }} />
                        </Stack>
                    }
                    {authenticator === "Google" &&
                        <Stack direction="column" spacing={2} alignItems={"center"}>
                            <Typography variant="h6">Click the button to add a code</Typography>
                            <img src={AddGoogleAccount} style={{ height: "auto", width: 'auto', maxWidth: "400px" }} />
                        </Stack>
                    }

                </Stack>
            ]
        },
        {
            label: 'Set up account',
            description:
                null,
            component: [
                <Stack direction="column" spacing={2} sx={{ my: 3 }} divider={<Divider orientation="horizontal" flexItem />} alignItems={"center"}>
                    {authenticator === "Microsoft" &&
                        <Stack direction="column" spacing={2} alignItems={"center"}>
                            <Typography variant="h6">Choose “Other” from the list of account types</Typography>
                            <img src={SetUpMicrosoftAccount} style={{ height: "auto", width: 'auto', maxWidth: "400px" }} />
                        </Stack>
                    }
                    {authenticator === "Google" &&
                        <Stack direction="column" spacing={2} alignItems={"center"}>
                            <Typography variant="h6">Select “Scan a QR code”</Typography>
                            <img src={SetUpGoogleAccount} style={{ height: "auto", width: 'auto', maxWidth: "400px" }} />
                        </Stack>
                    }
                </Stack>
            ]
        },
        {
            label: `Scan QR code using ${authenticator} Authenticator`,
            description: ``,
            component: [
                <>
                    <Typography variant="h6" sx={{ textAlign: "center", mt: 1, mb: 2 }}>
                        Locate <span style={{ fontWeight: 500 }}>Precedent-Exchange-prod</span>
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
                        Enter the 6-digit passcode (they refresh every 30 seconds)
                    </Typography>
                    {qrCode}
                </>

            ]
        }
    ];
    const maxSteps = steps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Stack direction="column" sx={{ mt: 2, mb: 4 }}>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Tooltip title={authenticator === '' ? 'Download an authenticator to continue' : ''} placement="top" arrow>
                        <span>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleNext}
                                disabled={authenticator === '' || activeStep === 3}
                            >
                                Next
                            </Button>
                        </span>
                    </Tooltip>
                }
                backButton={
                    <Button onClick={handleBack} disabled={activeStep === 0}>
                        Back
                    </Button>
                }
            />
            <Stack sx={{ mt: 2 }}>
                <Typography sx={{ textAlign: "center" }} variant="h5" color={grey[800]}>{steps[activeStep].label}</Typography>
                <Typography sx={{ textAlign: "center", mt: 1 }} variant="h6">{steps[activeStep].description}</Typography>
                {steps[activeStep].component[0]}
            </Stack>
        </Stack>
    );
}

export default AuthInstructions