import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { setAiPromptBuilderOpen } from "../../redux/slices/demandDomSlice";
import { DialogContent, DialogTitle, Divider, IconButton, Stack, Toolbar, Box, MenuItem, FormControl, InputLabel, Select, Tabs, Tab, TextField } from "@mui/material";
import {Close, AutoFixHigh} from "@mui/icons-material";
import Title from "../../CommonComponents/Title";
import {theme} from "../../Theme";
import Grid from "@mui/material/Unstable_Grid2";
import {LoadingButton} from "@mui/lab";
import { setAiDataSourceConfig, setSelectedField, setTabValue, setAiGenerationData, setAiGenerationPrompt } from "../../services/aiNarrativeGeneration/customSlice"
import { aiNarrativeGenerationApi, useGetAiDataLoadersQuery, useGetAiEnabledFieldsQuery } from '../../services/aiNarrativeGeneration/aiNarrativeGenerationApi';
import ResizableWrapWithPdfViewer from "../../CommonComponents/ResizableWrapWithPdfViewer"
import { useParams } from "react-router-dom";

const AiPromptBuilder = () => {
    const dispatch = useDispatch();
    const { documentId } = useParams();
    const { user, userData } = useSelector((state) => state.User);

    const {
        selectedField,
        aiGenerationData,
        aiGenerationPrompt,
        tabValue,
        aiDataSourceConfig,
    } = useSelector(state => state.AiNarrativeGeneration)

    const { data: aiDataLoaders = {loaders: []} } = useGetAiDataLoadersQuery({
        refetchOnMount: true,
    });
    const { data: aiEnabledFields = [], isLoading: isLoadingFields } = useGetAiEnabledFieldsQuery(documentId, {
        refetchOnMountOrArgChange: true,
    });
    const [loadAiDataTrigger, { isFetching: isLoadingData }] = aiNarrativeGenerationApi.endpoints.loadAiData.useLazyQuery();
    const [generateNarrativeTrigger, { data: generatedNarrativeContent = "", isFetching: isGenerating }] = aiNarrativeGenerationApi.endpoints.generateNarrativeAdmin.useLazyQuery();

    const closeBuilderView = () => dispatch(setAiPromptBuilderOpen(false));

    const handleChangeField = (event) => {
        const value = event.target.value;

        if (!value) return;

        const fieldConfig = aiEnabledFields[value];

        dispatch(setSelectedField(value));

        const requestBody = {
            "aiDataSourceConfig": fieldConfig.aiDataSourceConfig,
        }
        
        loadAiDataTrigger({documentId: documentId, values: requestBody});
        dispatch(setAiDataSourceConfig(fieldConfig.aiDataSourceConfig));
        dispatch(setAiGenerationPrompt(fieldConfig.aiGenerationPrompt));
    };

    const handleChangeDataSourceConfig = (event) => {
        const value = event.target.value;
        if (!value) return;

        dispatch(setAiDataSourceConfig(value));

        const requestBody = {
            "aiDataSourceConfig": value,
        }

        loadAiDataTrigger({documentId: documentId, values: requestBody});
    };

    const handleGenerateClick = async () => {
        const requestBody = {
            "aiGenerationPrompt": aiGenerationPrompt,
            "aiGenerationData": aiGenerationData,
        }

        generateNarrativeTrigger({documentId: documentId, values: requestBody});
    };

    const handleTabChange = (e) => dispatch(setTabValue(e.target.tabIndex));

    const parseLines = (value) => {
        value = value.replace(/(\\n)/g, "\n");
        if (value.startsWith('"') && value.endsWith('"'))  // remove end quotes
            value = value.substring(1, value.length - 1);
        return value;
    }

    const a11yProps = (index) => ({
        id: `generate-ai-narratives-tab-${index}`,
        'aria-controls': `generate-ai-narratives-tabpanel-${index}`,
    });

    return (
        <div>
              <DialogTitle
                  className="dialog-title-blue"
                  sx={{height: '60px', width: '100%', zIndex: 1000}}
                  display="flex"
                  alignItems="center"
                  position={'fixed'}
              >
                  <Stack
                      direction={'row'}
                      style={{width: '100%'}}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                  >
                      <Stack direction={'row'} spacing={2} alignItems={'center'}>
                          <IconButton
                              onClick={closeBuilderView}
                              aria-label="close"
                              sx={{'&:hover': {backgroundColor: 'rgba(255, 255, 255, 0.15)'}}}
                          >
                              <Close color={'white'}/>
                          </IconButton>
                          <Title color={theme.palette.white.main}>Generate AI narratives</Title>
                      </Stack>
                      <Box sx={{ '& > button': { m: 1 } }}>
                          <LoadingButton
                              disabled={isGenerating || isLoadingData || aiGenerationData === "" || aiGenerationPrompt === ""}
                              loading={isGenerating || isLoadingData}
                              sx={{ '&:disabled': { backgroundColor: ("rgba(255,255,255,0.2)") } }}
                              onClick={(e) => {
                                  e.preventDefault();
                                  handleGenerateClick();
                              }}
                              loadingPosition="start"
                              variant="contained"
                              color="white"
                              startIcon={<AutoFixHigh />}
                          >
                              <span>{isLoadingData ? 'Loading...' : isGenerating ? 'Generating...' : 'Generate'}</span>
                          </LoadingButton>
                      </Box>
                  </Stack>
              </DialogTitle>
              <Toolbar sx={{height: '60px'}}/>
              <fieldset disabled={isGenerating}>
                  <DialogContent sx={{marginTop: '0px', paddingTop: '0px', overflow: 'visible'}}>
                      <div style={{width: '100%', display: 'flex'}}>
                          <ResizableWrapWithPdfViewer
                              adminView={true}
                              defaultWidth={'60%'}
                              pdfHeight={'85vh'}
                              user={user}
                              documentId={documentId}
                          >
                              <div>
                                  <Stack spacing={2} sx={{paddingRight: 2, pb: 2}}>
                                      <Grid container spacing={2} sx={{mb: 5,}}>
                                          <Grid item xs={12}>
                                              <Stack spacing={2} sx={{mt: 2, mb: 5}}
                                                     divider={<Divider flexItem/>}>
                                                  <Stack spacing={2}>
                                                      <FormControl fullWidth>
                                                          <InputLabel id="template-field-select-label" size="small">Template Field</InputLabel>
                                                          <Select label="Template Field" name="aiGeneration.field"
                                                                  disabled={isLoadingFields}
                                                                  value={selectedField}
                                                                  labelId="template-field-select-label"
                                                                  onChange={handleChangeField}
                                                                  size="small"
                                                            >
                                                              {Object.entries(aiEnabledFields).map(([fieldName, fieldConfig]) =>
                                                                (
                                                                  <MenuItem key={fieldName} value={fieldName} size="small">
                                                                      {fieldConfig.friendlyName}
                                                                  </MenuItem>
                                                                )
                                                              )}
                                                            </Select>
                                                      </FormControl>
                                                      <Tabs
                                                          value={tabValue}
                                                          onChange={handleTabChange}
                                                          aria-label="Generate AI narratives form tabs"
                                                          TabIndicatorProps={{
                                                            style: {
                                                              backgroundColor: theme.palette.primary.main,
                                                            }
                                                          }}
                                                        >
                                                          <Tab label="Payload" {...a11yProps(0)} tabIndex={0} />
                                                          <Tab label="Prompt" {...a11yProps(1)} tabIndex={1} />
                                                          <Tab label="Response" {...a11yProps(2)} tabIndex={2} />
                                                      </Tabs>
                                                      {tabValue === 0 && (
                                                          <Stack spacing={2}>
                                                              <FormControl fullWidth>
                                                                  <InputLabel id="etl-select-label" size="small">Data Type</InputLabel>
                                                                  <Select label="Data Type" name="aiGeneration.dataType"
                                                                          value={aiDataSourceConfig}
                                                                          labelId="etl-select-label"
                                                                          onChange={handleChangeDataSourceConfig}
                                                                          disabled={aiDataLoaders.loaders.length === 0}
                                                                          size="small"
                                                                    >
                                                                      {aiDataLoaders.loaders.map((loader) => (
                                                                          <MenuItem key={loader.name} value={loader.name} size="small">
                                                                              {loader.friendlyName}
                                                                          </MenuItem>
                                                                      ))}
                                                                  </Select>
                                                              </FormControl>
                                                              <TextField name="aiGeneration.data" fullWidth multiline
                                                                         label="Data"
                                                                         minRows={30}
                                                                         maxRows={30}
                                                                         value={parseLines(aiGenerationData)}
                                                                         onChange={e => dispatch(setAiGenerationData(e.target.value))}
                                                              />
                                                          </Stack>
                                                      )}
                                                      {tabValue === 1 && (
                                                          <TextField name="aiGeneration.prompt"  fullWidth multiline
                                                                     label="Prompt"
                                                                     minRows={30}
                                                                     maxRows={30}
                                                                     value={parseLines(aiGenerationPrompt)}
                                                                     onChange={e => dispatch(setAiGenerationPrompt(e.target.value))}
                                                          />
                                                      )}
                                                      {tabValue === 2 && (
                                                          <TextField name="aiGeneration.narrative" fullWidth multiline
                                                                     label="Generated narrative"
                                                                     minRows={30}
                                                                     maxRows={30}
                                                                     value={parseLines(generatedNarrativeContent)}
                                                          />
                                                      )}
                                                  </Stack>
                                              </Stack>
                                          </Grid>
                                      </Grid>
                                  </Stack>
                              </div>
                          </ResizableWrapWithPdfViewer>
                      </div>
                  </DialogContent>
              </fieldset>
        </div>
    )
}

export default AiPromptBuilder;