import { cloneDeep } from 'lodash';

export const parseNestedObject = (obj = {}) => { //currently setup to parse strings. Can be modified in the future to parse other data types or specific form field types
    
    const recursiveParse = (current) => {
      if (typeof current === 'string') {
        return current.trim();
      } else if (typeof current === 'object' && current !== null) {
        for (const key in current) {
          if (current.hasOwnProperty(key)) {
            current[key] = recursiveParse(current[key]);
          }
        }
      }
      return current;
    };
  
    return recursiveParse(cloneDeep(obj));  //cloneDeep is used to avoid mutating the original object and incurring read only errors
  };