
import awsExports from './aws-exports';

export const getAttachmentS3Url = async (attachmentId) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        }
    };

    const response = await fetch(
        awsExports.ROOT_API_URL+"/attachment/"+attachmentId, settings
    ).then((response) => response.json());

   return response.s3Url;
};

export const downloadAttachment = async (attachment) => {

    // Load the documents
    const s3_url = await getAttachmentS3Url(attachment.attachmentId);

    var xhr = new XMLHttpRequest();
    xhr.open("GET", s3_url);
    xhr.responseType = "blob";
    xhr.onload = function () {
        const link = document.createElement("a");
        link.download = attachment.attachmentName;
        link.href =  URL.createObjectURL(xhr.response);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }; 
    xhr.send();
};
