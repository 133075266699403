import { GridColumnMenu } from '@mui/x-data-grid-pro'
import React from 'react'

const ColumnMenu = (props) => {
    return (
        <GridColumnMenu
            {...props}
            slotProps={{
                columnMenuFilterItem: {
                    displayOrder: 0,
                },
                columnMenuSortItem: {
                    displayOrder: 10,
                },
            }}
        />
    )
}

export default ColumnMenu

