import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton, Typography, Stack, Tabs, Tab, Button, Tooltip, Badge, Box, CircularProgress } from '@mui/material';
import CodeEditor from '../../CodeEditor/CodeEditor';
import PDFViewerComponent from '../../CommonComponents/PDFViewerComponent';
import { MenuOpenRounded, SaveAltOutlined } from '@mui/icons-material';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { setResizableSideBySideClosed } from '../../redux/slices/sideBySideWithVerticalNavSlice';
import { closeWithTransition } from '../../redux/thunks/sideBysideWithVerticalNav';
import { setCurrentTab, setTemplatePreviewErrors } from '../../redux/slices/templatePreviewSlice';
import { useDispatch, useSelector } from 'react-redux';
import { generateTemplatePreview, getDocumentDataByIdForTemplatePreview } from '../../redux/thunks/templatePreview';
import { LoadingButton } from '@mui/lab';
import { isJsonValidOrEmpty, isJsonValidAndNotEmpty } from '../../common';
import { theme } from '../../Theme';

const TemplatePreview = ({ user }) => {
    const dispatch = useDispatch();

    const TemplatePreviewState = useSelector((state) => state.TemplatePreview);
    const AdminTemplateCodeEditorState = useSelector((state) => state.CodeEditor.AdminEditTemplate);
    const TemplatePreviewCodeEditorState = useSelector((state) => state.CodeEditor.PreviewTemplate);
    const OverrideJsonCodeEditorState = useSelector((state) => state.CodeEditor.OverrideJson);
    const { presigned_url, currentTab, errors, loading } = TemplatePreviewState;
    const { currentCodeEditorContent } = AdminTemplateCodeEditorState;
    const templatePreviewCodeEditorCurrentContent = TemplatePreviewCodeEditorState.currentCodeEditorContent;
    const overrideJsonCodeEditorCurrentContent = OverrideJsonCodeEditorState.currentCodeEditorContent;
    const isAnythingLoading = loading.generateTemplatePreview || loading.getDocumentDataByIdForTemplatePreview;

    const [demandId, setDemandId] = useState('');
    const [demandIdBlurred, setDemandIdBlurred] = useState(false);

    const validateDemandId = (value, fireDispatch) => {
        const doesMatch = value.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/);
        fireDispatch && dispatch(
            setTemplatePreviewErrors({
                ...errors,
                getDocumentDataByIdForTemplatePreview: !value ? 'Demand ID is required' : !doesMatch ? 'Invalid Demand ID' : ''
            })
        );
        return !value || !doesMatch;
    }

    const handleTemplatePreviewClose = () => {
        dispatch(setResizableSideBySideClosed(true));
        dispatch(closeWithTransition());
    }

    const generatePreview = () => {
        dispatch(generateTemplatePreview({
            templateText: currentCodeEditorContent,
            overrideJsonBlobText: overrideJsonCodeEditorCurrentContent,
            user: user,
            documentId: demandId
        }));
    }

    const generateJsonFromDemandId = () => {
        dispatch(getDocumentDataByIdForTemplatePreview({ demandId, user }));
    }

    const isJsonFieldValid = isJsonValidAndNotEmpty(templatePreviewCodeEditorCurrentContent) && isJsonValidOrEmpty(overrideJsonCodeEditorCurrentContent);

    return (
        <Stack spacing={2} sx={{ pt: 1, px: 2, width: '100%' }}>
            <Stack direction={'row'} spacing={2} sx={{ width: '100%' }}>
                <Stack
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    direction={"row"}
                    sx={{
                        width: '100%',
                    }}
                >
                    <Typography variant="h6">Preview</Typography>
                    <IconButton
                        onClick={handleTemplatePreviewClose}
                    >
                        <MenuOpenRounded
                            sx={{
                                transform: 'scaleX(-1)'
                            }}
                        />
                    </IconButton>
                </Stack>
            </Stack>

            <Stack spacing={1}>
                <TextField
                    required
                    fullWidth
                    size='small'
                    label="Demand ID"
                    variant="outlined"
                    InputAdornmentProps={{ position: 'end' }}
                    value={demandId}
                    onChange={(e) => {
                        if (demandIdBlurred) validateDemandId(e.target.value, true);
                        setDemandId(e.target.value, true);
                    }}
                    helperText={errors.getDocumentDataByIdForTemplatePreview}
                    error={errors.getDocumentDataByIdForTemplatePreview}
                    onBlur={(e) => {
                        setDemandIdBlurred(true);
                        validateDemandId(e.target.value, true);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={generateJsonFromDemandId}
                                    disabled={isAnythingLoading || validateDemandId(demandId)}
                                    size="small"
                                >
                                    {loading.getDocumentDataByIdForTemplatePreview ?
                                        <CircularProgress
                                            size="1rem"
                                        />
                                        : <SaveAltOutlined />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{
                        maxLength: 36
                    }}
                />
                <Stack direction={'row'} sx={{ width: '100%' }}>
                    <Stack>
                        <Tabs
                            value={currentTab}
                            onChange={(e, newValue) => dispatch(setCurrentTab(newValue))}
                            variant="scrollable"
                            scrollButtons="auto"
                            sx={{ borderBottom: 1, borderColor: 'divider' }}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: !isJsonFieldValid && currentTab === 0 ? theme.palette.error.main : theme.palette.primary.main
                                }
                            }}
                        >

                            <Tab
                                label={
                                    <Tooltip
                                        title={isJsonFieldValid ? '' : 'Invalid JSON'}
                                        arrow
                                        placement="top">
                                        <Badge
                                            color="error"
                                            invisible={isJsonFieldValid}
                                        >
                                            <Typography color={isJsonFieldValid ? 'inherit' : 'error'}>
                                                JSON
                                            </Typography>
                                            {!isJsonFieldValid &&
                                                <ErrorOutlineIcon
                                                    color="error"
                                                    fontSize="small"
                                                    sx={{ marginLeft: 1 }}
                                                />
                                            }
                                        </Badge>
                                    </Tooltip>
                                }
                            />
                            <Tab label={
                                <Typography>
                                    PDF
                                </Typography>
                            }
                            />

                        </Tabs>
                    </Stack>

                    <Stack direction={'row'} spacing={.5} sx={{ width: '100%' }} alignItems={'center'} justifyContent={'flex-end'}>

                        <Tooltip
                            title={!isJsonFieldValid ? 'Invalid JSON' : ''}
                            arrow
                            placement="left">
                            <div>
                                <LoadingButton
                                    onClick={generatePreview}
                                    loading={loading.generateTemplatePreview}
                                    disabled={(isAnythingLoading || errors.getDocumentDataByIdForTemplatePreview || !isJsonFieldValid)}
                                    variant="text"
                                    color="secondary"
                                    startIcon={
                                        <PlayCircleFilledWhiteOutlinedIcon
                                        />
                                    }
                                >
                                    Generate preview
                                </LoadingButton>
                            </div>
                        </Tooltip>

                    </Stack>
                </Stack>

                <Stack direction={'row'} spacing={2} sx={{ width: '100%', height: '70vh', overflow: 'auto' }}>

                    {currentTab === 0 && (
                        <Stack
                            direction={'column'}
                            spacing={2}
                            sx={{
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <CodeEditor
                                parentComponentName={'PreviewTemplate'}
                                readOnly={true}
                                title="Document JSON"
                                height={'50%'}
                            />
                            <CodeEditor
                                parentComponentName={'OverrideJson'}
                                readOnly={false}
                                title="Override JSON"
                                height={'50%'}
                            />
                        </Stack>
                    )}

                    {currentTab === 1 && (
                        <div style={{
                            height: '100vh',
                            overflow: 'auto',
                            width: '100%',
                        }}>

                            {presigned_url && (
                                <Box
                                    sx={{
                                        border: '1px solid',
                                        borderRadius: 1,
                                        borderColor: 'divider',
                                        height: '100%'
                                    }}
                                >
                                    <PDFViewerComponent
                                        currentPage={0}
                                        height={'100%'}
                                        key={presigned_url}
                                        customUrl={presigned_url}   
                                    />
                                </Box>
                            )}

                        </div>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
}

export default TemplatePreview;