import React from 'react';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';

const MenuButtonPageBreak = (props) => {
  const editor = useRichTextEditorContext();

  return (
    <MenuButton
      tooltipLabel="Insert page break"
      IconComponent={InsertPageBreakIcon}
      disabled={!editor.can().insertPageBreak()}
      onClick={() => editor?.chain().focus().insertPageBreak().run()}
      {...props}
    />
  );
};

export default MenuButtonPageBreak;
