import React from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogContentText, DialogTitle, CircularProgress, Stack, Typography } from '@mui/material';

const SavingDemand = () => {

    const { savingDemand } = useSelector(state => state.GlobalDialogues);
    const { open, data, } = savingDemand;
    const { title, text } = data;

    return (

        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogTitle>

                <Stack direction="row" justifyContent="center">
                    <Typography variant="h6">{title}</Typography>
                    <CircularProgress size={20} sx={{ ml: 5, mt: 1 }} />
                </Stack>

            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
    
                    <Stack direction="row">
                        {text}
                    </Stack>

                </DialogContentText>
            </DialogContent>

        </Dialog>
    );
}

export default SavingDemand;