import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from "react";
import { DataGridPro, GridRowModes, GridRowEditStopReasons, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { LinearProgress, Stack, Snackbar, Alert, Button } from '@mui/material';
import awsExports from '../aws-exports';
import { DeleteRounded, EditRounded, SaveRounded, CloseRounded, PersonAddOutlined } from '@mui/icons-material';
import { isValidEmail, normalizeEmailAddress, isValidPhoneNumber, formatPhoneNumber } from '../common';
import { getConfigValue, getUsersListApi } from '../api';
import StatusDialog from '../dialogs/StatusDialog';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import { PRECEDENT_ROLES, LAW_FIRM_ROLES, isLawFirmUser, isPrecedentUser } from '../common-roles';
import { useSelector } from 'react-redux';

const UsersView = ({user}) => {
    const selectedCustomer = useSelector((state) => state.AdminEditTemplate.customerData);
    const rootApiUrl = awsExports.ROOT_API_URL;
    const [usersListData, setUsersListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastSeverity, setToastSeverity] = useState();
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [rowModesModel, setRowModesModel] = useState({});
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [confirmationDialogText, setConfirmationDialogText] = useState("");
    const [confirmationDialogTitle, setConfirmationDialogTitle] = useState("Delete user?");
    const [currentDeleteUser, setCurrentDeleteUser] = useState({});
    const [roleOptions, setRoleOptions] = useState([]);
    const [validDomains, setValidDomains] = useState([]);
    const [currentUserId, setCurrentUserId] = useState();

    const apiRef = useRef({});
    const navigate = useNavigate();

    const updateUserApi = async (userRecord) => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify(userRecord)
        };
        setLoading(true);

        const response = await fetch(
            rootApiUrl + "/user", settings
        )
            .then((response) => {
                if (response.status === 200) {
                    showToast("User updated successfully.", "success");
                }
                else {
                    showToast("There was an error updating the user.", "error");
                }
                return response.json();
            }).finally((response) => {
                setLoading(false);
            });

        return response;
    };

    const deleteUserApi = async (userRecord) => {

        const settings = {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify(userRecord)
        };

        setLoading(true);

        const response = await fetch(
            rootApiUrl + "/user", settings
        )
            .then((response) => {
                if (response.status === 200) {
                    setConfirmationDialogOpen(false);
                    setUsersListData(usersListData.filter((row) => row.userId !== userRecord.userId));
                    showToast("User successfully deleted.", "success");
                }
                else {
                    setConfirmationDialogText("There was an error deleting the user.");
                }
                return response.json();
            }).finally(() => {
                setLoading(false);
                setCurrentDeleteUser(null);
            });

        return response;
    };

    const getValidDomains = async (customerId) => {
        const validDomains = await getConfigValue("validEmailDomains", customerId, user) || []
        setValidDomains(validDomains);
    };

    const processRowUpdate = async (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        updatedRow.supervisorId = updatedRow.supervisorId ? usersListData.filter((user) => user.firstName + ' ' + user.lastName === updatedRow.supervisorId)[0].userId : ''

        if (!updatedRow["firstName"]) {
            throw new Error("First name is required.");
        }
        if (!updatedRow["lastName"]) {
            throw new Error("Last name is required.");
        }
        if (!updatedRow["emailAddress"]) {
            throw new Error("Email address is required.");
        }
        if (!updatedRow["roleId"]) {
            throw new Error("Role is required.");
        }

        // Clean up the email address
        updatedRow["emailAddress"] = normalizeEmailAddress(updatedRow["emailAddress"]);
        if (!isValidEmail(updatedRow["emailAddress"])) {
            throw new Error("The email address \"" + updatedRow["emailAddress"] + "\" is not valid.");
        }

        // Validate email is for an approved domain
        if (validDomains.length > 0) {
            const emailDomain = updatedRow["emailAddress"].split("@")[1];
            if (!validDomains.includes(emailDomain.toLowerCase())) {
                throw new Error("The email address \"" + updatedRow["emailAddress"] + "\" does not match an approved domain.");
            }
        }

        // Validate the phone number, if one was entered
        if (updatedRow["phone"] && !isValidPhoneNumber(updatedRow["phone"])) {
            throw new Error("The phone number \"" + updatedRow["phone"] + "\" is not valid.");
        }
        updatedRow["phone"] = formatPhoneNumber(updatedRow["phone"]);

        // Set the role
        updatedRow["roles"] = [updatedRow["roleId"]];
        delete updatedRow["roleId"];

        // Handle new user. Set customer id and remove user id so we trigger a create.
        if (newRow.isNew) {
            updatedRow["customerId"] = selectedCustomer.customerId;
            updatedRow["isNewUser"] = true;
            delete updatedRow["userId"];
        }
    
        const userResponse = await updateUserApi(updatedRow);
        const userId = userResponse["userId"];
        if (!userId) {
            const error = userResponse["error"];
            throw new Error("Error saving user: " + JSON.stringify(updatedRow) + " - " + error);
        }
        updatedRow["userId"] = userId;
        setUsersListData(usersListData.map((row) => (row.userId === newRow.userId ? updatedRow : row)));

        return updatedRow;
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const showStatusDialog = (title, text) => {
        setStatusDialogTitle(title);
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleAddUserClick = (e) => {
        setUsersListData((oldRows) => [{ isNew: true, userId: 'NEW', firstName: '', lastName: '', customerName: selectedCustomer.name, customerId: selectedCustomer.customerId, emailAddress: '', roles: roleOptions }, ...oldRows]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            ["NEW"]: { mode: GridRowModes.Edit, fieldToFocus: 'firstName' },
        }));
    };

    const handleEditUserClick = (id) => () => {
        const newRowModesModel = { ...rowModesModel, [id]: { mode: GridRowModes.Edit } };
        const rowModesModelKeys = Object.keys(rowModesModel);
        rowModesModelKeys.forEach((key) => {
            if (key !== id) {
                newRowModesModel[key] = { mode: GridRowModes.View, ignoreModifications: true };
            }
        });
        setCurrentUserId(id);
        setRowModesModel(newRowModesModel);
    };

    const handleDeleteUserClick = (id) => () => {
        const row = usersListData.find((row) => row.userId === id);
        setCurrentDeleteUser(row);
        setConfirmationDialogText("Are you sure you want to delete the user " + row.firstName + " " + row.lastName + "?");
        setConfirmationDialogOpen(true);
    };

    const handleDeleteUserConfirmOk = () => {
        deleteUserApi(currentDeleteUser);
    };

    const handleDeleteUserCancel = () => {
        setConfirmationDialogOpen(false);
    };

    const handleCancelUserClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
        const editedRow = usersListData.find((row) => row.userId === id);
        if (editedRow.isNew) {
            setUsersListData(usersListData.filter((row) => row.userId !== id));
        }
    };

    const handleSaveUserClick = (id) => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowUpdateError = (errorMessage) => {
       showStatusDialog("Error", errorMessage.message);
    };

    const handleRowEditStart = (params, event) => {
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const loadUser = (customerId) => {
        setUsersListData([]);
        getValidDomains(customerId);
        setLoading(true);
        getUsersListApi(customerId, user)
        .then(response => {
            setUsersListData(response);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    };

    const handleToastClose = () => {
        setToastMessage("");
        setToastOpen(false);
    };

    const showToast = (message, severity) => {
        setToastSeverity(severity);
        setToastMessage(message);
        setToastOpen(true);
    };

    const getSupervisorOptions = (params) => {
        const isPrecedent = isPrecedentUser(params.row)
        const isLawFirm = isLawFirmUser(params.row);
        const listOfAdmins = usersListData.filter((user) => user.roles.includes(isPrecedent ? 'PrecedentAdmin' : 'LawFirmAdmin')).filter((user) => user.userId !== params.row.userId);
        return listOfAdmins.length > 0 ? listOfAdmins.map((admin) => `${admin.firstName} ${admin.lastName}`) : []
    };


    useEffect(() => {
        if (selectedCustomer && user) {
            if (selectedCustomer["customerType"] === "precedent") {
                setRoleOptions(PRECEDENT_ROLES);
            }
            else if (selectedCustomer["customerType"] === "lawfirm") {
                setRoleOptions(LAW_FIRM_ROLES);
            }

            loadUser(selectedCustomer.customerId);
        }
    }, [selectedCustomer, user]);

    // Grid Column
    const first_name_col = {
        field: 'firstName',
        headerName: 'First name',
        minWidth: 150,
        valueGetter: (params) => {
            return params.row.firstName;
        },
        editable: true
    };

    const last_name_col = {
        field: 'lastName',
        headerName: 'Last name',
        minWidth: 150,
        valueGetter: (params) => {
            return params.row.lastName;
        },
        editable: true
    };

    const email_col = {
        field: 'emailAddress',
        headerName: 'Email address',
        minWidth: 250,
        editable: true,
        valueGetter: (params) => {
            return params.row.emailAddress;
        }

    };

    const customer_col = {
        field: 'customerName',
        headerName: 'Customer ID',
        minWidth: 200,
        editable: false,
        valueGetter: (params) => {
            return params.row.customerName;
        }
    };

    const phone_col = {
        field: 'phone',
        headerName: 'Phone',
        minWidth: 150,
        editable: true,
        valueGetter: (params) => {
            return params.row.phone ? params.row.phone : ''
        }
    };


    const supervisor_col = {
        field: 'supervisorId',
        headerName: 'Supervisor',
        minWidth: 200,
        editable: true,
        valueGetter: (params) => {
            const supervisorId = params?.row?.supervisorId
            const supervisorName = supervisorId ? usersListData.filter((user) => user.userId === supervisorId)[0]?.firstName + ' ' + usersListData.filter((user) => user.userId === supervisorId)[0]?.lastName : ''
            return supervisorName
        },
        type: 'singleSelect',
        valueOptions: (params) => getSupervisorOptions(params)

    };

    const role_col = {
        field: 'roleId',
        headerName: 'Role',
        minWidth: 150,
        valueGetter: (params) => {
            return params.row.roles[0];
        },
        editable: true,
        type: 'singleSelect',
        valueOptions: roleOptions,
    };

    const user_status_col = {
        field: 'userStatus',
        headerName: 'Status',
        minWidth: 150,
        editable: false,
        valueGetter: (params) => {
            return params.row.userStatus ? (params.row.userStatus === "CONFIRMED" ? "Active" : "") : "";
        }
    };

    const actions_col = {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 110,
        type: 'actions',
        getActions: ({ id, row }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<SaveRounded />}
                        label="Save"
                        color='secondary'
                        onClick={(e) => { handleSaveUserClick(id) }}
                    />,
                    <GridActionsCellItem
                        icon={<CloseRounded />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelUserClick(id)}
                        color="primaryActions"
                    />,
                ];
            }

            return [
                <GridActionsCellItem
                    icon={<EditRounded />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditUserClick(id)}
                    color="primaryActions"
                />,
                <GridActionsCellItem
                    icon={<DeleteRounded />}
                    label="Delete"
                    onClick={handleDeleteUserClick(id)}
                    color="primaryActions"
                />,
            ];
        }
    }

    const columns = React.useMemo(() => [first_name_col, last_name_col, email_col, phone_col, customer_col, supervisor_col, role_col, user_status_col, actions_col]);

    return (
        <div>
            <div>
                <Stack direction={"row"}>
                    <Stack direction={"row"} sx={{ width: "100%" }} justifyContent={"flex-end"}>
                        <Button variant="contained" color='secondary'
                            disabled={usersListData.length > 0 && usersListData[0].userId === 'NEW'}
                            onClick={handleAddUserClick} title='Add a new user to Exchange'
                            startIcon={< PersonAddOutlined />}>Create user</Button>
                    </Stack>
                </Stack>

                <DataGridPro 
                    autoHeight={true} 
                    getRowId={(row) => row.userId} 
                    columns={columns} 
                    rows={usersListData}
                    apiRef={apiRef}
                    editMode="row"
                    density="compact"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    onRowEditStart={handleRowEditStart}
                    onProcessRowUpdateError={handleRowUpdateError}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'lastName', sort: 'asc' }],
                        }
                    }}
                    isCellEditable={(params) => {
                        if (params.row.isNew)
                            return params.field !== 'customerName';
                        else {
                            if (params.field === 'customerName' || params.field === 'emailAddress')
                                return false;
                            else
                                return true;
                        }

                    }}
                    processRowUpdate={processRowUpdate}
                    getRowHeight={() => 'auto'}
                    slots={{
                        loadingOverlay: LinearProgress,
                    }}
                    disableRowSelectionOnClick={true}
                    disableColumnSelector={true}
                    loading={loading}
                    pageSizeOptions={[25, 50, 100]}
                    sx={{
                        backgroundColor: "#fff",
                        mt: 1,
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontSize: '9pt',
                            fontWeight: '500'
                        },
                        '& .MuiDataGrid-cellContent': {
                            fontSize: '9pt',
                            paddingTop: '5px',
                            paddingBottom: '5px'
                        }
                    }}
                >
                </DataGridPro>

                <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>

                <ConfirmationDialog buttonColor="error" handleOk={handleDeleteUserConfirmOk} handleCancel={handleDeleteUserCancel} okButtonText="Delete User" cancelButtonText="Cancel" dialogOpen={confirmationDialogOpen} dialogText={confirmationDialogText} dialogTitle={confirmationDialogTitle}></ConfirmationDialog>

                <Snackbar
                    open={toastOpen}
                    autoHideDuration={3000}
                    onClose={handleToastClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>{toastMessage}</Alert>
                </Snackbar>

            </div>
        </div>
    );

}

export default UsersView;