import { createSlice } from '@reduxjs/toolkit';

const initialMedicalsState = {
    medicalsLoading: false,
    medicals: {
        medicalTreatments: [],
    },
    medicalsUpdateLoading: {},
};

const medicalsSlice = createSlice({
    name: 'Medicals',
    initialState: initialMedicalsState,
    reducers: {
        setMedicalsTreatments: (state, action) => {
            state.medicals.medicalTreatments = action.payload;
        },
        setMedicalsLoading: (state, action) => {
            state.medicalsLoading = action.payload;
        },
        clearMedicalsState: () => initialMedicalsState,
        setMedicalsUpdateLoading: (state, action) => {
            const currentLoadingState = {
                ...state.medicalsUpdateLoading,
                [action.payload.documentId]: action.payload.loading,
            }
            state.medicalsUpdateLoading = currentLoadingState;
        },
    },
});

export const {
    setMedicalsTreatments,
    setMedicalsLoading,
    clearMedicalsState,
    setMedicalsUpdateLoading,
} = medicalsSlice.actions;

export default medicalsSlice.reducer;