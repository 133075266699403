import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { Transition } from '../Transition';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { ICD_CODES_URL_PATH } from '../MedicalsComponents/insights';
import { setToast } from '../redux/slices/globalToastSlice';
import { setSelectedRowsAndIcdCodeIds, setIcdCodesUpdateLoading } from '../redux/slices/icdCodesSlice';
import ICDCodeAutocomplete from '../MedicalsComponents/ICDCodes/ICDCodeAutocomplete';
import { apiSlice } from '../services/apiSlice';
import { useGetEntityInsightsQuery } from '../services/insights/insightsApi';

const EditICDCode = () => {
  const dispatch = useDispatch();

  const { editICDCode } = useSelector((state) => state.GlobalDialogues);
  const { open, data } = editICDCode;
  const { documentId, user } = data;

  const { data: icdSummaryData = [] } = useGetEntityInsightsQuery({
    documentId,
    insightsType: ICD_CODES_URL_PATH,
  });

  const { selectedRowsAndIcdCodeIds } = useSelector((state) => state.IcdCodes);

  const selectedIcdCodes = Object.keys(selectedRowsAndIcdCodeIds);
  const isSameIcdCode = selectedIcdCodes.length === 1;
  const icdCodeData = icdSummaryData.find(({ value }) => value === selectedIcdCodes[0]) || {};
  const initialIcdCode = isSameIcdCode ? icdCodeData.value : '';
  const initialDescription = isSameIcdCode ? icdCodeData?.description : '';
  const initialIcdStatus = isSameIcdCode ? icdCodeData?.status : '';

  const [icdCodeFormValue, setIcdCodeFormValue] = useState([initialIcdCode, initialDescription]);
  const [icdCode, description] = icdCodeFormValue;

  const [selectedStatusFormValue, setSelectedStatusFormValue] = useState(initialIcdStatus);
  const [isSaving, setIsSaving] = useState(false);

  const icdCodeDirty = icdCodeFormValue[0] !== initialIcdCode;
  const statusDirty = selectedStatusFormValue !== '';

  const handleIcdCodeChange = (value) => {
    if (Array.isArray(value)) {
      setIcdCodeFormValue(value);
    } else if (value === null) {
      setIcdCodeFormValue(['', '']);
    }
  };

  const handleChangeStatus = (e) => setSelectedStatusFormValue(e.target.value);

  const handleSaveClick = async () => {
    // Start loading states
    setIsSaving(true);
    dispatch(setIcdCodesUpdateLoading(true));
    // Perform update
    const { error } = await dispatch(
      apiSlice.endpoints.updateEntityInsightDetails.initiate({
        documentId,
        user,
        insightsType: ICD_CODES_URL_PATH,
        insightValues: selectedIcdCodes,
        entitiesToUpdate: Object.values(selectedRowsAndIcdCodeIds).map(entityIds => ({entityIds}) ),
        newStatus: selectedStatusFormValue,
        newValue: icdCode,
        newDescription: description,
      })
    );
    dispatch(setIcdCodesUpdateLoading(false));
    if (error) {
      console.log('error', error);
      dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with updating ICD code(s)' }));
    }

    dispatch(setSelectedRowsAndIcdCodeIds({})); // Reset selections
    dispatch(resetGlobalDialogues()); // Close dialog
  };

  const handleClose = () => {
    setIcdCodeFormValue([initialIcdCode, initialDescription]);
    setSelectedStatusFormValue('');
    dispatch(resetGlobalDialogues());
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth TransitionComponent={Transition}>
      <DialogTitle>Edit ICD codes</DialogTitle>

      <DialogContent>
        <Alert severity="info" spacing={2} sx={{ my: 2 }}>
          {selectedIcdCodes.length} selected
        </Alert>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <ICDCodeAutocomplete value={icdCodeFormValue} onChange={handleIcdCodeChange} user={user} />

          <FormControl
            fullWidth
            size='small'
          >
            <InputLabel id="select-icd-status-label">Status</InputLabel>
            <Select
              labelId="select-icd-status-label"
              id="select-icd-status"
              value={selectedStatusFormValue}
              label="Status"
              onChange={handleChangeStatus}
            >
              <MenuItem value={'INCLUDED'}>Included</MenuItem>
              <MenuItem value={'EXCLUDED'}>Excluded</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>

        <Button
          disabled={!statusDirty && !icdCodeDirty}
          onClick={handleSaveClick}
          variant="contained"
          color="secondary"
        >
          {isSaving ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditICDCode;
