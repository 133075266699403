import { createAsyncThunk } from '@reduxjs/toolkit'
import awsExports from '../../aws-exports';
const rootApiUrl = awsExports.ROOT_API_URL;

export const fetchDocumentContacts = createAsyncThunk(
    'Inventory/fetchDocumentContacts',
    async ({ requestBody, token }, { }) => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: token
            },
            body: JSON.stringify(requestBody)
        };

        const response = await fetch(rootApiUrl + "/getDocumentContacts", settings)
            .then((response) => response.json())

        return {response};
    },
)