import React from 'react';
import { useSelector } from 'react-redux';
import Toast from './Toast';

const GlobalToasts = () => {
  const { toast, apiErrorToast } = useSelector((state) => state.GlobalToasts);

  return (
    <>
      {toast.isOpen && <Toast toast={toast} />}
      {apiErrorToast.isOpen && <Toast toast={apiErrorToast} />}
    </>
  );
};

export default GlobalToasts;
