import awsExports from '../aws-exports';
import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { setToast } from '../redux/slices/globalToastSlice';
import { fetchAuthSession } from '@aws-amplify/auth';

export const baseUrl = awsExports.ROOT_API_URL;

export const staggeredBaseQueryWithBailOut = retry(
    async (args, api, extraOptions) => {
        const result = await fetchBaseQuery({ baseUrl, prepareHeaders })(
            args,
            api,
            extraOptions
        );
        if (result.meta?.response?.status === 202) {
            console.log('Retrying on 202 status code')
            result.meta.response.ok = false
        }

        // bail out of re-tries immediately if user input error,
        // because we know successive re-retries would be redundant
        if (result.meta?.response?.status >= 400 && result.meta?.response?.status < 500) {
            retry.fail(result.meta.response)
        }
        return result
    },
    {
        maxRetries: 20,
    }
)


export const prepareHeaders = async (headers, { getState }) => {

    try {

        const authSession = await fetchAuthSession();
        const token = authSession?.tokens?.accessToken || '';
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
    } catch (error) {
        console.error('Error fetching auth token:', error);
    }
    return headers;
};

const buildErrorMessage = (errors) => {
    const message = errors.reduce((acc, curr) => {
        const { error, associationId } = curr;
        const associationMessage = associationId ? `Association ID: ${associationId}` : '';
        const errorStatus = error.status ? `Status: ${error.status}` : '';
        const errorMessage = error?.data?.message ? `Message: ${error?.data?.message}` : '';
        return `${acc} ${associationMessage} ${errorStatus} ${errorMessage}\n`;
    }
        , '');
    return message;
}

export const errorDispatchToast = ({ errors, dispatch, rejectWithValue, rejuctThunk }) => {
    const message = buildErrorMessage(errors);
    dispatch(setToast({ message, severity: 'error', isOpen: true }));
    return rejuctThunk ? rejectWithValue(errors) : null;
}



