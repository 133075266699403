import React from 'react';
import { useRichTextEditorContext } from 'mui-tiptap';
import { LoadingButton } from '@mui/lab';
import { AutoAwesome } from '@mui/icons-material';
import { deepPurple } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';

const MenuButtonGenerate = ({ fieldName, documentId, user, onMouseDown, disabled, aiDataSourceConfig }) => {
  const editor = useRichTextEditorContext();

  const handleClick = () => {
    editor.commands.generateNarrative(fieldName, documentId, user);
  };

  const isFactsLiability = aiDataSourceConfig === 'FACTS_LIABILITY_SUMMARY';
  const isDisabled = disabled && !isFactsLiability; // We can still show the button if the AI data source is FACTS_LIABILITY_SUMMARY

  const fetchTooltipMessage = () => {
    if (isDisabled)
      return 'Please wait for the demand to process to enable';
    else if (isFactsLiability)
      return 'For best results, wait for the demand to process or upload a police report';
    else
      return '';
  };

  return (
    <Tooltip title={fetchTooltipMessage()} arrow>
      <span>
        {/* This is necessary because a disabled button does not trigger hover events directly */}
        <LoadingButton
          sx={{
            backgroundColor: deepPurple[50],
            color: deepPurple[900],
            '&:hover': {
              backgroundColor: deepPurple[100],
            },
          }}
          size="small"
          startIcon={<AutoAwesome fontSize="small" sx={{ color: deepPurple[900] }} />}
          onClick={handleClick} // Use the handleClick function
          onMouseDown={onMouseDown}
          disabled={isDisabled}
        >
          Compose
        </LoadingButton>
      </span>
    </Tooltip>
  );
};

export default MenuButtonGenerate;
