import React from 'react';
import { Button } from '@mui/material';

export const ReloadToast = (window) => ({
    isOpen: true,
    duration: 10000,
    message: 'PDF regeneration is taking longer than expected. Please try again by refreshing your browser.',
    severity: 'error',
    action: (
        <Button color="inherit" size="small" onClick={() => window.location.reload()}>
            Refresh
        </Button>
    )
})