import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'

const KeyValuePair = ({ label, value, value2, validation, callToAction, useKeyOptionalStyles }) => {
    const keyDefaultStyle = { fontWeight: 500 }
    const keyOptionalStyles = { textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }
    const keyStyles = useKeyOptionalStyles ? { ...keyDefaultStyle, ...keyOptionalStyles } : keyDefaultStyle
    const valueStyles = { textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }

    return (
        <Stack direction="row">
            <Stack direction="row" spacing={1} sx={{ width: '45%' }} alignContent={'flex-start'}>
                <Typography variant="body2" sx={keyStyles}>
                    {label}
                </Typography>

            </Stack>
            <Box sx={{ width: '55%' }} >
                <Tooltip title={value2 ? `${value} ${value2}` : value} arrow placement="top-start">
                    <Typography variant="body2" sx={valueStyles}>
                        {value} {value2}
                    </Typography>
                </Tooltip>
                {validation}
                {callToAction}
            </Box>

        </Stack>
    )
}

export default KeyValuePair