import { createSlice } from '@reduxjs/toolkit';

const originalEditDocumentState = {
    sequencedDocumentFiles: [],
};

const EditDocumentsSlice = createSlice({
    name: 'EditDocuments',
    initialState: originalEditDocumentState,
    reducers: {
        setSequencedDocumentFiles: (state, action) => {
            state.sequencedDocumentFiles = action.payload;
        },
        mapNewFilesToSequence: (state, action) => {
            const newFilesList = action.payload;
            const newSequencedFileList = [...state.sequencedDocumentFiles]
                .map((file) => newFilesList
                    .find((newFile) => newFile.fileEntityId === file.fileEntityId) || file);
            state.sequencedDocumentFiles = newSequencedFileList;
        },
    },
});

export const {
    setSequencedDocumentFiles,
    mapNewFilesToSequence,
} = EditDocumentsSlice.actions;

export default EditDocumentsSlice.reducer;