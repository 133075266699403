import { createSlice } from '@reduxjs/toolkit';
import { generateTemplatePreview, getDocumentDataByIdForTemplatePreview } from '../thunks/templatePreview';

const originalTemplatePreviewState = {
    jsonGeneratedbyDemandId: '',
    currentTab: 0,
    templatePreview: '',
    presigned_url: '',
    errors: {
        generateTemplatePreview: '',
        getDocumentDataByIdForTemplatePreview: ''
    },
    loading: {
        generateTemplatePreview: false,
        getDocumentDataByIdForTemplatePreview: false
    }
};

const templatePreviewSlice = createSlice({
    name: 'TemplatePreview',
    initialState: originalTemplatePreviewState,
    reducers: {
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
        setTemplatePreview: (state, action) => {
            state.templatePreview = action.payload;
        },
        setTemplatePreviewErrors: (state, action) => {
            state.errors = action.payload;
        },
        setTemplatePreviewLoading: (state, action) => {
            state.loading = action.payload;
        },
        resetTemplatePreviewState: (state, action) => originalTemplatePreviewState,
    },
    extraReducers: (builder) => {

        builder.addCase(generateTemplatePreview.pending, (state, action) => {
            state.loading.generateTemplatePreview = true;
        });

        builder.addCase(generateTemplatePreview.fulfilled, (state, action) => {
            state.templatePreview = action.payload;
            const { presigned_url } = action.payload;
            state.presigned_url = presigned_url;
            state.loading.generateTemplatePreview = false;
        });

        builder.addCase(generateTemplatePreview.rejected, (state, action) => {
            state.errors.generateTemplatePreview = action.error.message;
            state.loading.generateTemplatePreview = false;
        });

        builder.addCase(getDocumentDataByIdForTemplatePreview.pending, (state, action) => {
            state.loading.getDocumentDataByIdForTemplatePreview = true;
        });

        builder.addCase(getDocumentDataByIdForTemplatePreview.fulfilled, (state, action) => {
            state.jsonGeneratedbyDemandId = action.payload;
            state.loading.getDocumentDataByIdForTemplatePreview = false;
        });

        builder.addCase(getDocumentDataByIdForTemplatePreview.rejected, (state, action) => {
            state.errors.getDocumentDataByIdForTemplatePreview = action.error.message;
            state.loading.getDocumentDataByIdForTemplatePreview = false;
        });

    }

});

export const {
    setCurrentTab,
    setTemplatePreview,
    setTemplatePreviewErrors,
    setTemplatePreviewLoading,
    resetTemplatePreviewState
} = templatePreviewSlice.actions;

export default templatePreviewSlice.reducer;
