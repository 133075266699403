import React from 'react';
import { Dialog, DialogContent, Typography, CircularProgress, Box, Grow } from '@mui/material';

const SaveAnimationDialog = ({ isSaving, message='Saving demand...' }) => {
  return (
    <Dialog
      open={isSaving}
      fullScreen
      fullWidth
      TransitionProps={{ timeout: 350 }}
    >
      <DialogContent
        sx={{
          background: '#ffffff',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          mt: '-200px',
        }}
      >
        <Typography
          variant="h5"
          color={'primary'}
          sx={{
            pb: 4
          }}
        >
          {message}
        </Typography>
        <CircularProgress
          sx={{
            color: 'primary',
          }}
          size={65}
          thickness={2}
        />



      </DialogContent>
    </Dialog>
  );
};

export default SaveAnimationDialog;
