import awsExports from './aws-exports';
import React, { useEffect,useState, createRef } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Title from './CommonComponents/Title'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {formatAddress, formatCurrency,formatDate} from './common';
import {getAttachmentS3Url, downloadAttachment} from './attachments_api';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import EnclosuresTable from './EnclosuresTable';
import Button from '@mui/material/Button';
import AdjusterViewerTreatmentCourse from './AdjusterViewerTreatmentCourse';
import { useSelector, useDispatch } from 'react-redux'
import { toggle } from './redux/slices/accordionSlice'

const AdjusterViewerIncurredDamages= (props) => {

    const primaryActionsColor = "rgba(0, 0, 0, 0.56)";
    const [detailsPaneOpen, setDetailsPaneOpen] = useState(false);
    const [currentDocumentUrl, setCurrentDocumentUrl] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [otherAttachments, setOtherAttachments] = useState([]);
    const [totalLostWages, setTotalLostWages] = useState(0.0);
    const [totalOtherDamages, settTotalOtherDamages] = useState(0.0);
    const [totalCOT, setTotalCOT] = useState(0.0);
    const [selectedTreatmentEncounter, setSelectedTreatmentEncounter] = useState();
    const [showTreatmentEncounter, setshowTreatmentEncounter] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const frameRef = createRef();
    const leftFrameRef = createRef();
    const demandDoc = props.demandDoc;

    const dispatch = useDispatch();
    const panels = useSelector(state => state.accordion.panels);

    const handleAccordionChange = (panel, newExpanded) => {
        dispatch(toggle({id:panel,expanded:newExpanded}));
    };

    const isExpanded = (panelId) => {
        const matchingPanel = panels.find(panel => panel.id === panelId)
        if (matchingPanel) {
            return matchingPanel.expanded;
        }
        else {
            return false;
        }
    } 

    const findAttachment = (attachmentId) => {
        for (let i = 0; i < demandDoc.attachments.length; i++) {
            const attachment = demandDoc.attachments[i];
            if (attachment.attachmentId == attachmentId)
                return attachment;
        }

        return null;
    };

    const resizeIframe = () => {
        if (frameRef.current != null) {
            let h = window.innerHeight-150;    
            h = 700;
            frameRef.current.style.height = h+'px';
            leftFrameRef.current.style.height = frameRef.current.style.height
        }
    };

    const hideDetailsPanel = () => {
        setDetailsPaneOpen(false);
    };

    const viewDetailsPanelCallback = async (attachmentId, title) => {
    
        // Load the documents
        const s3_url = await getAttachmentS3Url(attachmentId);
        setCurrentDocumentUrl(s3_url);

        // Open the panel
        setDetailsPaneOpen(true);
    };

    const downloadAttachmentCallback = async (attachmentId) => {
        downloadAttachment(findAttachment(attachmentId));
    };

    const viewTreatmentEncounterClick = (treatmentEncounter) => {
        setScrollPosition(window.pageYOffset);
        setSelectedTreatmentEncounter(treatmentEncounter);
        setshowTreatmentEncounter(true);
    };

    const viewIncurredDamagesClick = () => {
        setSelectedTreatmentEncounter(null);
        setshowTreatmentEncounter(false);
    };

    useEffect(() => {
        let cotTotal = 0.0;
        props.demandDoc.courseOfTreatment.map((cot) =>  {
            cotTotal += parseFloat(cot.billedAmount);
        });
        setTotalCOT(cotTotal);

        const lwIds = []
        for (let i = 0; i < props.demandDoc.lostWages.length; i++) {
            const lostWage = props.demandDoc.lostWages[i];
            if (lostWage.futureIndicator == false) {
                lwIds.push(lostWage.lostWageId);
            }
        }

        const odIds = []
        for (let i = 0; i < props.demandDoc.otherDamages.length; i++) {
            const damage = props.demandDoc.otherDamages[i];
            if (damage.futureIndicator == false) {
                odIds.push(damage.otherDamageId);
            }
        }
        
    
        const tempAttachments = []
        const tempODAttachments = []
        for (let i = 0; i < props.demandDoc.attachments.length; i++) {
            const attachment = props.demandDoc.attachments[i];

            if (attachment.associatedDemandEntity.demandSection == 'IncurredDamages' &&
                (attachment.associatedDemandEntity.associatedDemandEntityId && 
                 lwIds.includes(attachment.associatedDemandEntity.associatedDemandEntityId))) {
                tempAttachments.push(attachment);
            }
            else if (attachment.associatedDemandEntity.demandSection == 'IncurredDamages' &&
                    (attachment.associatedDemandEntity.associatedDemandEntityId && 
                     odIds.includes(attachment.associatedDemandEntity.associatedDemandEntityId))) {
                    tempODAttachments.push(attachment);
            }
        }
        setAttachments([...tempAttachments]);
        setOtherAttachments([...tempODAttachments]);


        let total = 0.0;
        demandDoc.lostWages.map((d) => {
            if (d.futureIndicator == false) {
                total += parseFloat(d.grossWages);
            }
        }
        );
        setTotalLostWages(total);
    
        total = 0.0;
        demandDoc.otherDamages.map((d) => {
            if (d.futureIndicator == false) {
                total += parseFloat(d.otherDamageAmount);
            }
        }
        );
        settTotalOtherDamages(total);

        /*
         * Configure accordion state
         */
        const tempList = {};
        demandDoc.courseOfTreatment.map((cot,index) => {
            tempList[cot.treatmentCourseId] = false;
        });
       
    }, []);
    
    useEffect(()=>{
        if (!showTreatmentEncounter) {
            window.scrollTo(0, parseInt(scrollPosition));
        }
    }, [showTreatmentEncounter])

    useEffect(() => {
        resizeIframe();
    }, [frameRef,leftFrameRef]);

    return (
    <>
        {showTreatmentEncounter ? (
            <AdjusterViewerTreatmentCourse backCallback={viewIncurredDamagesClick} treatmentCourse={selectedTreatmentEncounter} demandDoc={demandDoc} />
        ) : (
            <Grid container sx={{mt:1}}>
                <Grid xs={detailsPaneOpen ? 6 : 12} >
                    <Box ref={leftFrameRef} style={{overflowY:'auto'}}>
                    
                        <Box sx={{mt:3, mb:3}}>
                            <Title color={primaryActionsColor}>Medical Damages: {formatCurrency(totalCOT)}</Title>
                        </Box>

                        <Stack spacing={3} width={detailsPaneOpen ? "100%" : "50%"}>
                            {demandDoc.courseOfTreatment.map((cot,index) => {
                                return (
                                    <Accordion key={index} expanded={isExpanded(cot.treatmentCourseId)} onChange={(e,expanded) => {handleAccordionChange(cot.treatmentCourseId, expanded)} } elevation={3} sx={{mb:1}}>
                                        <AccordionSummary
                                            sx={{margin:0}}
                                            expandIcon={<ExpandMoreIcon color='secondary'/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Title>{cot.treatmentType}: {formatCurrency(cot.billedAmount)}</Title>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>

                                                <Grid container xs={12} className="doc-content-section adjuster-form-data">
                                                    <Grid xs={3} className="adjuster-form-label">
                                                        Start Date
                                                    </Grid>
                                                    <Grid xs={9}>
                                                        {formatDate(cot.startDate)}
                                                    </Grid>

                                                    <Grid xs={3} className="adjuster-form-label">
                                                        End Date
                                                    </Grid>
                                                    <Grid xs={9}>
                                                        {formatDate(cot.endDate)}
                                                    </Grid>

                                                    <Grid xs={3} className="adjuster-form-label">
                                                        Number of Visits
                                                    </Grid>
                                                    <Grid xs={9}>
                                                        {cot.treatmentEncounters.length}
                                                    </Grid>

                                                    <Grid xs={3} className="adjuster-form-label">
                                                        Provider
                                                    </Grid>
                                                    <Grid xs={9}>
                                                        {cot.medicalProvider.businessName}
                                                    </Grid>

                                                    <Grid xs={3} className="adjuster-form-label">
                                                        Provider TIN
                                                    </Grid>
                                                    <Grid xs={9}>
                                                        {cot.medicalProvider.providerTIN}
                                                    </Grid>

                                                    <Grid xs={3} className="adjuster-form-label">
                                                        Incurred Damages
                                                    </Grid>
                                                    <Grid xs={9}>
                                                        {formatCurrency(cot.billedAmount)}
                                                    </Grid>
                                                </Grid>

                                                <Stack direction={"row"} justifyContent={"flex-end"} >
                                                    <Button onClick={()=>{viewTreatmentEncounterClick(cot)}} variant="outlined" color='secondary'>View Details</Button>
                                                </Stack>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                                })}

                                <div  className="section-hr" />

                                <Title color={"rgba(0, 0, 0, 0.56)"}>Lost Wages: {formatCurrency(totalLostWages)}</Title>
                            
                                {demandDoc.lostWages.map((lw,index) => {
                                    if (lw.futureIndicator)
                                        return null;
                                    else 
                                        return (
                                            <Accordion  key={lw.lostWageId} elevation={3} sx={{mb:1}} expanded={isExpanded(lw.lostWageId)} onChange={(e,expanded) => {handleAccordionChange(lw.lostWageId, expanded)}}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon color='secondary'/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Title>{lw.employer.employerName}: {formatCurrency(lw.grossWages)}</Title>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                    <   Stack direction={"column"} spacing={1} className='adjuster-form-data'>
                                                            <Grid container xs={12} className="doc-content-section adjuster-form-data">
                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Start Date
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {formatDate(lw.startDate)}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    End Date
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {formatDate(lw.endDate)}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Employer
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {lw.employer.employerName}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Address
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {formatAddress(lw.employer.contactInfo.address)}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Phone
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {lw.employer.contactInfo.phoneNumber}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Fax
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {lw.employer.contactInfo.faxNumber}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Email
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {lw.employer.contactInfo.emailAddress}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Gross Wages
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {formatCurrency(lw.grossWages)}
                                                                </Grid>
                                                            </Grid>

                                                
                                                        </Stack>

                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                        })}

                                
                                <Accordion  elevation={3} sx={{mb:1}} expanded={isExpanded('incurr-enclosures')} onChange={(e,expanded) => {handleAccordionChange('incurr-encolsures', expanded)}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon color='secondary'/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Title>Enclosures ({attachments.length})</Title>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <EnclosuresTable showTitle={false} demandDoc={demandDoc} attachments={attachments} viewTitle="" downloadAttachment={downloadAttachmentCallback} viewAttachment={viewDetailsPanelCallback} />
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <div  className="section-hr" />

                                <Title color={"rgba(0, 0, 0, 0.56)"}>Other Damages: {formatCurrency(totalOtherDamages)}</Title>

                                {demandDoc.otherDamages.map((lw,index) => {
                                    if (lw.futureIndicator)
                                        return null;
                                    else
                                        return (
                                            <Accordion key={lw.otherDamageId} elevation={3} sx={{mb:1}} expanded={isExpanded(lw.otherDamageId)} onChange={(e,expanded) => {handleAccordionChange(lw.otherDamageId, expanded)}}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon color='secondary'/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Title>{lw.otherDamageType}: {formatCurrency(lw.otherDamageAmount)}</Title>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                    <   Stack direction={"column"} spacing={1} className='adjuster-form-data'>
                                                            <Grid container xs={12} className="doc-content-section adjuster-form-data">
                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Type
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {lw.otherDamageType}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Descriptiom
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {lw.otherDamageDescription}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Amount
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {formatCurrency(lw.otherDamageAmount)}
                                                                </Grid>
                                                            </Grid>
                                                        </Stack>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                        })}

                               
                                <Accordion elevation={3}  sx={{mb:1}} expanded={isExpanded('od-enclosures')} onChange={(e,expanded) => {handleAccordionChange('od-enclosures', expanded)}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon color='secondary'/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Title>Enclosures ({otherAttachments.length})</Title>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <EnclosuresTable showTitle={false} demandDoc={demandDoc} attachments={otherAttachments} viewTitle="" downloadAttachment={downloadAttachmentCallback} viewAttachment={viewDetailsPanelCallback} />
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                        </Stack>
                    </Box>
                </Grid>
                <Grid xs={detailsPaneOpen ? 6 : 0} >
                    {detailsPaneOpen ? (
                    <div style={{ height: "100%", marginLeft:"20px" }}>
                        <Stack direction={"column"}>
                            <Stack direction={"row"}>
                                <Grid container xs={12}>
                                    <Grid xs={6} >
                                        <Title>Enclosures</Title>
                                    </Grid>
                                    <Grid xs={6} textAlign={"right"}>
                                        <IconButton title='Close Details' style={{width:'25px'}} onClick={() => { hideDetailsPanel()}}>
                                        <Close color='secondary'/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Stack>
                        
                            <div ref={frameRef}>
                                <iframe onLoad={resizeIframe()} id="documentFrame" src={currentDocumentUrl} title="Document" width={"100%"} height={"100%"}>
                                </iframe>
                            </div>
                        </Stack>
                    </div>
                    ) : null }
                </Grid>
            </Grid>
        )}
    </>
    )

}

export default AdjusterViewerIncurredDamages;