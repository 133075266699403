import React, { forwardRef } from 'react';
import Chip from '@mui/material/Chip';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

// Use forwardRef to avoid MUI warning, see https://mui.com/material-ui/guides/composition/#caveat-with-refs
const WarningChip = forwardRef(({ label, ...props }, ref) => {
  return (
    <Chip
      color="error"
      variant="outlined"
      icon={<ErrorOutline />}
      label={label}
      size="small"
      ref={ref}
      {...props}
    />
  );
});

export default WarningChip;
