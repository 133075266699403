import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from '../Transition'
import { Stack, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const ArchiveDialog = (props) => {

    const [archiveReasonError, setArchiveReasonError] = useState(false);
    const [archiveReason, setArchiveReason] = useState('');
    const [archiveComments, setArchiveComments] = useState('');

    const handleReasonChange = (event) => {
        const reason = event.target.value;
        setArchiveReason(reason);
    };

    const archive = () => {
        const reasonError = !archiveReason;
        setArchiveReasonError(reasonError);

        if (reasonError || (archiveReason === 'Other' && !archiveComments)) {
            return;
        } else {
            props.handler(archiveReason, archiveComments);
        }
    };

    return (
        <Dialog
            open={props.dialogOpen}
            TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
        >
            <DialogTitle>Archive demand {props.firmCaseNumber}</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt={1}>
                    <FormControl
                        fullWidth
                        size="small"
                    >
                        <InputLabel id="archive-reason-label">Archive reason</InputLabel>
                        <Select
                            labelId="archive-reason-label"
                            id="archive-reason-select"
                            value={archiveReason}
                            onChange={handleReasonChange}
                            error={archiveReasonError}
                            label="Archive reason"
                        >
                            <MenuItem value="Case already settled">Case already settled</MenuItem>
                            <MenuItem value="Client no longer represented">Client no longer represented</MenuItem>
                            <MenuItem value="Determined no coverage or wrong carrier">Determined no coverage or wrong carrier</MenuItem>
                            <MenuItem value="Duplicate demand">Duplicate demand</MenuItem>
                            <MenuItem value="Firm determined not ready for demand">Firm determined not ready for demand</MenuItem>
                            <MenuItem value="Demand should not have gone through Precedent">Demand should not have gone through Precedent</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        id="archiveComments"
                        label="Comments"
                        value={archiveComments}
                        onChange={(e) => setArchiveComments(e.target.value)}
                        required={archiveReason === "Other"}
                        fullWidth
                        multiline
                    />
                </Stack>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.handleClose() }} color="secondary" variant="text">Cancel</Button>
                <Button variant="contained"
                    color="secondary"
                    disabled={archiveReason === "Other" && !archiveComments.trim() || !archiveReason}
                    onClick={() => { archive() }}>Archive</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ArchiveDialog;
