import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const initialState = {
    csvExports: [],
}

export const UserHistoryPersistedSlice = createSlice({
    name: 'UserHistoryPersisted',
    initialState,
    reducers: {
        updateCsvExportsList: (state, action) => {
            state.csvExports = [...state.csvExports, action.payload]
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
          return initialState;
        });
      },
})


export const { updateCsvExportsList } = UserHistoryPersistedSlice.actions

export default UserHistoryPersistedSlice.reducer