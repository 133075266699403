import { Divider, Skeleton, Stack } from '@mui/material'
import React from 'react'

const EditDemandDetailsSkeleton = () => {
    return (
        <Stack spacing={1} sx={{ py: 2, pr: 2 }}>
            {/* tabs */}
            <Stack
                direction="row"
                spacing={2}
            >
                <Skeleton variant="text" width={'15%'} height={48} />
                <Skeleton variant="text" width={'15%'} height={48} />
            </Stack>

            {/* case details */}
            <Skeleton variant="text" width={'15%'} height={48} />
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    width: '100%'
                }}
            >
                <Skeleton variant='rounded' width={'50%'} height={40} />
                <Skeleton variant='rounded' width={'50%'} height={40} />
            </Stack>
            <Skeleton variant='rounded' width={'100%'} height={40} />
            <Stack
                direction="row"
                spacing={2}
                alignItems={'center'}
                justifyContent={'flex-start'}
                sx={{
                    width: '100%'
                }}
            >
                <Skeleton variant='circular' height={40} width={40} />
                <Skeleton variant='rounded' width={'25%'} height={24} />
            </Stack>
            <Skeleton variant='rounded' width={'100%'} height={40} />
            <Stack
                direction="row"
                spacing={2}
                alignItems={'center'}
                justifyContent={'flex-start'}
                sx={{
                    width: '100%'
                }}
            >
                <Skeleton variant='circular' height={40} width={40} />
                <Skeleton variant='rounded' width={'25%'} height={24} />
            </Stack>
            <Divider orientation='horizontal' />

            {/* loss details */}
            <Skeleton variant="text" width={'15%'} height={48} />
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    width: '100%'
                }}
            >
                <Skeleton variant='rounded' width={'50%'} height={40} />
                <Skeleton variant='rounded' width={'50%'} height={40} />
            </Stack>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    width: '100%'
                }}
            >
                <Skeleton variant='rounded' width={'50%'} height={40} />
                <Skeleton variant='rounded' width={'50%'} height={40} />
            </Stack>
            <Divider orientation='horizontal' />

            {/* carrier details */}
            <Skeleton variant="text" width={'15%'} height={48} />
            <Skeleton variant='rounded' width={'100%'} height={40} />
            <Skeleton variant='rounded' width={'100%'} height={40} />
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    width: '100%'
                }}
            >
                <Skeleton variant='rounded' width={'50%'} height={40} />
                <Skeleton variant='rounded' width={'50%'} height={40} />
            </Stack>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    width: '100%'
                }}
            >
                <Skeleton variant='rounded' width={'33%'} height={40} />
                <Skeleton variant='rounded' width={'33%'} height={40} />
                <Skeleton variant='rounded' width={'33%'} height={40} />
            </Stack>


        </Stack>
    )
}

export default EditDemandDetailsSkeleton