import React from 'react';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import {
  MenuControlsContainer,
  MenuButtonAlignCenter,
  MenuButtonAlignJustify,
  MenuButtonAlignLeft,
  MenuButtonAlignRight,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonBlockquote,
  MenuButtonRedo,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonIndent,
  MenuButtonUnindent,
  MenuDivider,
  TableImproved,
  MenuButtonAddTable,
  TableMenuControls,
  RichTextEditorProvider,
  RichTextField,
} from 'mui-tiptap';
import { Box } from '@mui/material';
import './ConfiguredRichTextEditor.css';
import { Indent, getIndent, getOutdent } from './CustomExtensions/indent.ts';
import AiGenerate from './CustomExtensions/aiGenerate.js';
import PageBreak from './CustomExtensions/pageBreak.js';
import MenuButtonGenerate from './CustomButtons/MenuButtonGenerate.jsx';
import MenuButtonPageBreak from './CustomButtons/MenuButtonPageBreak.jsx';
import CustomFieldLoadingAnimation from '../../CustomFieldLoadingAnimation.jsx';

const EditorMenuControls = ({ editor, showGenerateButton, aiDataSourceConfig, fieldName, documentId, user, disabled }) => {
  // Helper function to prevent blur on button click
  const handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box onMouseDown={handleMouseDown}>
      <MenuControlsContainer>
        {showGenerateButton && (
          <>
            <MenuButtonGenerate
              fieldName={fieldName}
              aiDataSourceConfig={aiDataSourceConfig}
              documentId={documentId}
              user={user}
              onMouseDown={handleMouseDown}
              disabled={disabled}
            />
            <MenuDivider />
          </>
        )}
        <MenuButtonBold />
        <MenuButtonItalic />
        <MenuButtonUnderline />
        <MenuDivider />
        <MenuButtonBlockquote />
        <MenuButtonBulletedList />
        <MenuButtonOrderedList />
        <MenuButtonUnindent
          tooltipLabel="Decrease indent"
          disabled={!editor?.isEditable}
          onClick={() => getOutdent()({ editor })}
        />
        <MenuButtonIndent
          tooltipLabel="Increase indent"
          disabled={!editor?.isEditable}
          onClick={() => getIndent()({ editor })}
        />
        <MenuDivider />
        <MenuButtonAlignLeft />
        <MenuButtonAlignCenter />
        <MenuButtonAlignRight />
        <MenuButtonAlignJustify />
        <MenuDivider />
        <MenuButtonPageBreak />
        <MenuDivider />
        <MenuButtonUndo />
        <MenuButtonRedo />
        <MenuDivider />
        <MenuButtonAddTable />
        {editor.isActive('table') && <TableMenuControls />}
      </MenuControlsContainer>
    </Box>
  );
};

const ConfiguredRichTextEditor = ({
  content,
  onChange,
  showGenerateButton = false,
  aiDataSourceConfig,
  fieldName,
  disabled,
  documentId,
  user,
}) => {
  const extensions = [
    StarterKit,
    Underline,
    TextAlign.configure({ types: ['paragraph'] }),
    Indent,
    AiGenerate.extend({}), // extending with empty object so that a new instance of the extension with its storage is created per editor
    TextStyle,
    TableImproved.configure({
      resizable: true,
    }),
    TableRow,
    TableHeader,
    TableCell,
    PageBreak,
  ];

  const editor = useEditor({
    extensions,
    content,
    parseOptions: { preserveWhitespace: 'full' },
    onUpdate: onChange,
  });

  return (
    <Box
      sx={{
        position: 'relative', // Set position to relative to contain the overlay
        '.ProseMirror': {
          minHeight: '100px',
          maxHeight: '350px',
          overflow: 'auto',
        },
      }}
    >
      <RichTextEditorProvider editor={editor}>
        {/* Loading overlay inside the editor container */}
        <CustomFieldLoadingAnimation />

        <RichTextField
          controls={
            <EditorMenuControls
              editor={editor}
              aiDataSourceConfig={aiDataSourceConfig}
              showGenerateButton={showGenerateButton}
              fieldName={fieldName}
              documentId={documentId}
              user={user}
              disabled={disabled}
            />
          }
          MenuBarProps={{ hide: !editor.isFocused }}
        />
      </RichTextEditorProvider>
    </Box>
  );
};

export default ConfiguredRichTextEditor;
