import { pushDataToS3, getUploadUrl, handleUpdateFilePdf } from '../../../api';
import { setEditDocumentPdfOpen, setFileDisabled, setFileSaveStatusMap, setEditAllDocumentsOpen } from '../../../redux/slices/demandDomSlice';
import { mapNewFilesToSequence } from '../../../redux/slices/editDocumentsSlice';
import { setPdfUrlPollingLoadingSkeleton } from '../../../services/pdf/customSlice';
import { setToast } from '../../../redux/slices/globalToastSlice';
import PSPDFKit from 'pspdfkit';
import ReactDOM from 'react-dom';
import { Button } from '@mui/material';
import { apiSlice } from '../../../services/apiSlice';
import { pollPdfRegenerationStatus } from '../../../services/pdf/thunks';
import { insightInvalidationTags } from '../../../MedicalsComponents/insights';

//Toolbar items that are removed from the toolbar in the PDF editor.
export const toolbarItemsToRemove = [
    "sidebar-document-outline", "sidebar-annotations", "sidebar-bookmarks", "annotate", "ink", "highlighter", "text-highlighter", "ink-eraser",
    "signature", "image", "stamp", "note", "text", "callout", "line", "link", "arrow", "rectangle", "ellipse", "polygon", "cloudy-polygon",
    "polyline", "document-crop", "multi-annotations-selection", "pan", "print", "sidebar-signatures", "sidebar-layers", "document-editor"
];

//Toolbar items that are added to the PDF editor toolbar.
export const editorItems = ['remove', 'undo', 'redo', 'select-all', 'select-none', 'zoom-out', 'zoom-in', 'rotate-left', 'rotate-right'];

export const handleViewStateChange = ({ dispatch, previousState, pdfEditorContainerRef, PSPDFKit }) => {
    if (previousState?.interactionMode?.toLowerCase() === "document_editor") {
        dispatch(setEditDocumentPdfOpen(false))
    }
}

const applyOperations = (originalArray, operations) => {
    let originalArrayCopy = [...originalArray];

    operations.forEach(operation => {
        if (operation.type === 'movePages') {
            const pageIndexesToMove = operation.pageIndexes.sort();
            const pageValuesToMove = pageIndexesToMove.map(pageIndex => originalArrayCopy[pageIndex]);

            pageIndexesToMove.forEach(page => {
                originalArrayCopy[page] = undefined;
            });

            if (operation?.beforePageIndex !== undefined) {
                const insertionPoint = Math.max(0, operation.beforePageIndex - 1);
                originalArrayCopy.splice(insertionPoint, 0, ...pageValuesToMove);
            } else if (operation?.afterPageIndex !== undefined) {
                const insertionPoint = Math.min(operation.afterPageIndex + 1, originalArrayCopy.length);
                originalArrayCopy.splice(insertionPoint, 0, ...pageValuesToMove);
            }

            originalArrayCopy = originalArrayCopy.filter(val => val != undefined);
        } else if (operation.type === 'removePages') {
            operation.pageIndexes.forEach(pageIndex => {
                originalArrayCopy[pageIndex] = undefined;
            });
            originalArrayCopy = originalArrayCopy.filter(val => val != undefined);
        }
    });

    return originalArrayCopy.map(val => val + 1);
};

export const handleDocumentChangeSave = async ({ dispatch, changes, instance, file, setIsSaving, fileSaveStatusMap, user, editOneDocumentFileName, documentId, pdfEditorContainerRef, PSPDFKit }) => {
    dispatch(setFileDisabled(file.fileEntityId));
    setIsSaving(true);
    dispatch(setFileSaveStatusMap({ ...fileSaveStatusMap, [file.fileEntityId]: 'Saving' }));
    const totalPagesRemoved = changes.filter((change) => change.type === "removePages").map((change) => change.pageIndexes).flat().length;
    const currentPageCount = instance.totalPageCount;
    const originalPageCount = currentPageCount + totalPagesRemoved;
    const originalPageIndexArray = Array.from({ length: originalPageCount }, (_, index) => index);
    const pageNumbersMappedToCorrectIndices = applyOperations(originalPageIndexArray, changes);
    const originalPageNumbers = originalPageIndexArray.map(index => index + 1);

    handleMedicalsUpdate({ pageNumbersMappedToCorrectIndices, originalPageNumbers, instance, dispatch, fileId: file.fileEntityId, file, documentId: documentId, user, editOneDocumentFileName, fileSaveStatusMap, setIsSaving, pdfEditorContainerRef, PSPDFKit });
}

const handleMedicalsUpdate = async ({
  pageNumbersMappedToCorrectIndices,
  originalPageNumbers,
  instance: originalInstance,
  dispatch,
  fileId,
  file,
  documentId,
  user,
  editOneDocumentFileName,
  fileSaveStatusMap,
  setIsSaving,
  pdfEditorContainerRef,
  PSPDFKit,
}) => {
  //create readoonly copy of instance
  const instance = { ...originalInstance };

  dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true }));

  dispatch(setEditAllDocumentsOpen(true));

  // Compare page numbers, if no changes, don't include in payload for updating file
  const arePageNumbersUpdated =
    originalPageNumbers.length !== pageNumbersMappedToCorrectIndices.length ||
    originalPageNumbers.some((page, index) => page !== pageNumbersMappedToCorrectIndices[index]);

  const uploadFields = await getUploadUrl(editOneDocumentFileName, documentId, user);

  const pdf = await instance.exportPDF().then((buffer) => new Blob([buffer], { type: 'application/pdf' }));

  const success = await pushDataToS3(uploadFields, pdf);

  if (success) {
    handleUpdateFilePdf({
      fileId,
      fileName: editOneDocumentFileName,
      user,
      pageNumbers: arePageNumbersUpdated && pageNumbersMappedToCorrectIndices,
    })
      .then(async (response) => {
        handleSave({
          documentId,
          dispatch,
          editOneDocumentFileName,
          file,
          fileSaveStatusMap,
          setIsSaving,
          PSPDFKit,
          pdfEditorContainerRef,
        });
      })
      .catch((error) => {
        dispatch(setToast({ isOpen: true, message: 'Error saving document', severity: 'error' }));
        const fileSaveStatusMapCopy = { ...fileSaveStatusMap };
        delete fileSaveStatusMapCopy[file.fileEntityId];
        dispatch(setFileSaveStatusMap(fileSaveStatusMapCopy));
      });
  }
};

const handleSave = async ({
  documentId,
  dispatch,
  editOneDocumentFileName,
  file,
  fileSaveStatusMap,
  setIsSaving,
  pdfEditorContainerRef,
  PSPDFKit,
}) => {
  dispatch(pollPdfRegenerationStatus({ documentId }));

  const documentFiles = await dispatch(
    apiSlice.endpoints.getAllFilesByDocumentId.initiate(documentId, { forceRefetch: true })
  ).unwrap();

  // Refresh insight entities data
  dispatch(apiSlice.util.invalidateTags(insightInvalidationTags));
  dispatch(setFileSaveStatusMap({ ...fileSaveStatusMap, [file.fileEntityId]: 'Saved' }));
  setIsSaving(false);
  dispatch(setFileDisabled(''));
  dispatch(mapNewFilesToSequence(documentFiles)); 
  dispatch(setToast({ isOpen: true, message: `Updated ${editOneDocumentFileName}`, severity: 'success' }));
  pdfEditorContainerRef && PSPDFKit.unload(pdfEditorContainerRef);

  return;
};

export const renderMUICustomCancelButton = ({ parentNode, dispatch, pdfEditorContainerRef }) => {

    const styles = {
        margin: '0 auto',
        alignItems: 'flex-start',
        alignItems: 'center',
        color: '#000',
        justifyContent: 'center',
        border: 'none',
        cursor: 'pointer',
        display: 'block',
        fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        height: '37.4px',
        padding: '6.5px 13px',
        textAlign: 'center',
        transition: 'all 0.1s ease',
    };



    const CustomCancelButton = () => (
        <Button
            variant="outlined"
            size='large'
            style={styles}
            onClick={() => {
                dispatch(setEditDocumentPdfOpen(false));
                pdfEditorContainerRef.current && PSPDFKit.unload(pdfEditorContainerRef.current);
            }}
        >
            Cancel
        </Button>
    );

    // Render the MUI Button into the parentNode
    ReactDOM.render(<CustomCancelButton />, parentNode);
};