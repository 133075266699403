export const mapFilesWithCalculatedStartPages = (files) => {
    const accumulatedPages = { pages: 1 };
    return files
        .sort((a, b) => a?.fileEntityData?.sequence || 999 - b?.fileEntityData?.sequence || 999)
        .map(file => {
            const totalPages = file?.fileEntityData?.totalPages || 0;
            const startPage = accumulatedPages.pages;
            const endPage = startPage + totalPages - 1;
            accumulatedPages.pages += totalPages;
            return {
                ...file,
                fileEntityData: {
                    ...file.fileEntityData,
                    startPage,
                    endPage,
                }
            }
        });
}