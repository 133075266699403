
import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { ChevronRight, DownloadRounded } from '@mui/icons-material';
import awsExports from './aws-exports';
import { theme } from './Theme'
import CustomTabPanel from './CustomTabPanel';
import { Paper, Container, Box, Button, Tabs, Tab, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, ThemeProvider, Snackbar, Alert, Stack } from '@mui/material';
import GeneratedDemandView from './DemandComponents/DemandGenerationView';
import AttachmentView from './AttachmentView';
import AdjusterViewParties from './AdjusterViewerParties';
import AdjusterViewerHighlights from './AdjusterViewerHighlights';
import AdjusterViewerFacts from './AdjusterViewerFacts';
import AdjusterViewerIncurredDamages from './AdjusterViewerIncurredDamages';
import AdjusterViewerFutureDamages from './AdjusterViewerFutureDamages';
import AdjusterViewerPainSuffering from './AdjusterViewerPainSuffering';
import AdjusterViewerPIPMedpay from './AdjusterViewerPIPMedpay';
import { formatCurrency, formatDate } from './common';
import { useSelector } from 'react-redux';
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const AdjusterViewer = (props) => {
    const { user } = useSelector(state => state.User);
    const rootApiUrl = awsExports.ROOT_API_URL;
    const { documentId } = useParams();
    const [documentData, setDocumentData] = useState();
    const [demandDoc, setDemandDoc] = useState();
    const [isPolicyLimitDemand, setIsPolicyLimitDemand] = useState(false);
    const [formData, setFormData] = useState({});
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [value, setValue] = useState(0);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [infoDialogTitle, setInfoDialogTitle] = useState("");
    const [infoDialogText, setInfoDialogText] = useState("");
    const [demandGenOpen, setDemandGenOpen] = useState(false);
    const [attachmentOpen, setAttachmentOpen] = useState(false);
    const [attachmentId, setAttachmentId] = useState("");

    const navigate = useNavigate();



    const getDocument = async () => {
        if (!user)
            return;

        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            }
        };

        const response = await fetch(
            rootApiUrl + "/document/" + documentId, settings
        ).then((response) => response.json());

        // update the state and map form elements
        setDocumentData(response);
        setDemandDoc(response.document);
    };

    const mapServerDataToFormElements = () => {
        if (demandDoc == null)
            return;

        // Case Info
        const tempFormData = { ...formData };
        tempFormData['attorney_first_name'] = demandDoc.sendingFirm.attorney.firstName;
        tempFormData['attorney_last_name'] = demandDoc.sendingFirm.attorney.lastName;
        tempFormData['attorney_reply_email'] = demandDoc.sendingFirm.attorney.contactInfo.emailAddress;
        tempFormData['attorney_firm_name'] = demandDoc.sendingFirm.firmName;
        tempFormData['attorney_tin_number'] = demandDoc.sendingFirm.firmTINNumber;
        tempFormData['attorney_case_number'] = demandDoc.sendingFirm.firmCaseNumber;

        if (demandDoc.sendingFirm.contactInfo) {
            tempFormData['attorney_street1'] = demandDoc.sendingFirm.attorney.contactInfo.address.street1;
            tempFormData['attorney_street2'] = demandDoc.sendingFirm.attorney.contactInfo.address.street2;
            tempFormData['attorney_city'] = demandDoc.sendingFirm.attorney.contactInfo.address.city;
            tempFormData['attorney_state'] = demandDoc.sendingFirm.attorney.contactInfo.address.state;
            tempFormData['attorney_zipcode'] = demandDoc.sendingFirm.attorney.contactInfo.address.zipCode;
            tempFormData['attorney_phone'] = demandDoc.sendingFirm.attorney.contactInfo.phoneNumber;
            tempFormData['attorney_fax'] = demandDoc.sendingFirm.attorney.contactInfo.faxNumber;
            tempFormData['attorney_email'] = demandDoc.sendingFirm.attorney.contactInfo.emailAddress;
        }

        // Carrier
        if (demandDoc.recipientCarrier) {
            tempFormData['carrier'] = demandDoc.recipientCarrier.carrierCommonName;

            if (demandDoc.recipientCarrier.contactInfo) {
                tempFormData['carrier_street1'] = demandDoc.recipientCarrier.contactInfo.address.street1;
                tempFormData['carrier_street2'] = demandDoc.recipientCarrier.contactInfo.address.street2;
                tempFormData['carrier_city'] = demandDoc.recipientCarrier.contactInfo.address.city;
                tempFormData['carrier_state'] = demandDoc.recipientCarrier.contactInfo.address.state;
                tempFormData['carrier_zipcode'] = demandDoc.recipientCarrier.contactInfo.address.zipCode;
                tempFormData['carrier_phone'] = demandDoc.recipientCarrier.contactInfo.phoneNumber;
                tempFormData['carrier_fax'] = demandDoc.recipientCarrier.contactInfo.faxNumber;
                tempFormData['carrier_email'] = demandDoc.recipientCarrier.contactInfo.emailAddress;
            }
        }

        // Adjuster
        if (demandDoc.recipientAdjuster) {

            tempFormData['adjuster_first_name'] = demandDoc.recipientAdjuster.firstName;
            tempFormData['adjuster_last_name'] = demandDoc.recipientAdjuster.lastName;

            if (demandDoc.recipientAdjuster.contactInfo) {
                tempFormData['adjuster_street1'] = demandDoc.recipientAdjuster.contactInfo.address.street1;
                tempFormData['adjuster_street2'] = demandDoc.recipientAdjuster.contactInfo.address.street2;
                tempFormData['adjuster_city'] = demandDoc.recipientAdjuster.contactInfo.address.city;
                tempFormData['adjuster_state'] = demandDoc.recipientAdjuster.contactInfo.address.state;
                tempFormData['adjuster_zipcode'] = demandDoc.recipientAdjuster.contactInfo.address.zipCode;
                tempFormData['adjuster_phone'] = demandDoc.recipientAdjuster.contactInfo.phoneNumber;
                tempFormData['adjuster_fax'] = demandDoc.recipientAdjuster.contactInfo.faxNumber;
                tempFormData['adjuster_email'] = demandDoc.recipientAdjuster.contactInfo.emailAddress;
            }
        }

        // Client   
        if (demandDoc.claimInfo.claimant) {
            tempFormData['client_first_name'] = demandDoc.claimInfo.claimant.firstName;
            tempFormData['client_last_name'] = demandDoc.claimInfo.claimant.lastName;
            if (demandDoc.claimInfo.claimant.dateOfBirth) {
                tempFormData['client_dob'] = demandDoc.claimInfo.claimant.dateOfBirth;
            }
            else {
                tempFormData['client_dob'] = null;

            }
            tempFormData['client_marital_status'] = demandDoc.claimInfo.claimant.maritalStatus;
            tempFormData['client_health_insurance'] = demandDoc.claimInfo.claimant.healthInsurance;
            tempFormData['client_profession'] = demandDoc.claimInfo.claimant.profession;

            if (demandDoc.claimInfo.claimant.contactInfo) {
                tempFormData['client_street1'] = demandDoc.claimInfo.claimant.contactInfo.address.street1;
                tempFormData['client_street2'] = demandDoc.claimInfo.claimant.contactInfo.address.street2;
                tempFormData['client_city'] = demandDoc.claimInfo.claimant.contactInfo.address.city;
                tempFormData['client_state'] = demandDoc.claimInfo.claimant.contactInfo.address.state;
                tempFormData['client_zipcode'] = demandDoc.claimInfo.claimant.contactInfo.address.zipCode;
                tempFormData['client_phone'] = demandDoc.claimInfo.claimant.contactInfo.phoneNumber;
                tempFormData['client_fax'] = demandDoc.claimInfo.claimant.contactInfo.faxNumber;
                tempFormData['client_email'] = demandDoc.claimInfo.claimant.contactInfo.emailAddress;
            }
        }

        // Claim
        tempFormData['claim_number'] = demandDoc.claimInfo.claimNumber;
        tempFormData['claim_date_of_loss'] = demandDoc.claimInfo.dateOfLoss;
        tempFormData['claim_coverage'] = demandDoc.claimInfo.claimCoverage;
        tempFormData['claim_loss_state'] = demandDoc.claimInfo.lossState;
        tempFormData['claim_loss_county'] = demandDoc.claimInfo.lossCounty;
        tempFormData['named_insured_first_name'] = demandDoc.claimInfo.insuredFirstName;
        tempFormData['named_insured_last_name'] = demandDoc.claimInfo.insuredLastName;
        tempFormData['insured_policy_number'] = demandDoc.claimInfo.insuredPolicyNumber;

        // Demand Narratives
        tempFormData['loss_description'] = demandDoc.liability.lossDescriptionNarrative;
        tempFormData['liability_description'] = demandDoc.liability.liabilityDescriptionNarrative;
        tempFormData['injury_description'] = demandDoc.injuryNarrative.injuryDescription;
        //tempFormData['damages_description'] = demandDoc.demandNarratives.damagesDescription;


        if (demandDoc.demandDetails.demandWrittenDate)
            tempFormData['demand_written_date'] = formatDate(demandDoc.demandDetails.demandWrittenDate);

        if (demandDoc.demandDetails.demandResponseDueDate)
            tempFormData['demand_due_date'] = formatDate(demandDoc.demandDetails.demandResponseDueDate);

        tempFormData['demand_policy_limit'] = demandDoc.demandDetails.policyLimitDemandIndicator || false;
        tempFormData['demand_total_damages'] = demandDoc.demandDetails.demandIncurredDamagesAmount + "";
        tempFormData['est_future_damages'] = demandDoc.demandDetails.demandFutureDamagesAmount + "";
        tempFormData['est_general_damages'] = demandDoc.demandDetails.demandGeneralDamagesAmount + "";
        setIsPolicyLimitDemand(tempFormData['demand_policy_limit']);
        tempFormData['demand_total_amount'] = calculateTotalDemand();

        setFormData(tempFormData);
    }

    const calculateTotalDemand = () => {
        if (!demandDoc)
            return;

        if (!isPolicyLimitDemand) {
            return parseInt(demandDoc.demandDetails.demandFutureDamagesAmount) + parseInt(demandDoc.demandDetails.demandGeneralDamagesAmount) + parseInt(demandDoc.demandDetails.demandIncurredDamagesAmount)
        }
        else {
            return demandDoc.demandDetails.demandAmount;
        }
    };

    const handleAttachmentClose = () => {
        setAttachmentOpen(false)
    };

    const handlePreviewDemandClick = () => {
        setDemandGenOpen(true);
    };

    const handleDemandGenClose = () => {
        setDemandGenOpen(false);
    };


    const handleInfoDialogClose = () => {
        setInfoDialogOpen(false);
    };

    const showToast = (msg) => {
        setToastMessage(msg);
        setToastOpen(true);
    };

    const handleToastClose = () => {
        setToastMessage("");
        setToastOpen(false);
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        mapServerDataToFormElements();
    }, [documentData]);

    useEffect(() => {
        if (documentId) {
            getDocument();
        }
    }, []);

    return (
        <Container className='adjuster-view' maxWidth='100' sx={{
            flexGrow: 1,
            height: '100%',
            overflow: 'auto',
            padding: '10px'
        }}>
            <ThemeProvider theme={theme}>

                <Snackbar
                    open={toastOpen}
                    autoHideDuration={4000}
                    onClose={handleToastClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>{toastMessage}</Alert>
                </Snackbar>

                {demandDoc ? (
                    <>
                        <Grid container sx={{ paddingTop: "10px", paddingBottom: "10px", }}>
                            <Grid xs={8}>
                                <div className='demand-title'>
                                    {demandDoc.claimInfo.claimant.firstName} {demandDoc.claimInfo.claimant.lastName} - {demandDoc.claimInfo.claimCoverage}
                                </div>
                            </Grid>
                            <Grid xs={4} spacing={2} style={{ textAlign: "right" }}>
                                <Stack direction="row" spacing={2} justifyContent={"flex-end"} width={"100%"}>
                                    <Button onClick={() => { setAttachmentOpen(true) }} color='secondary' variant='contained' startIcon={<DownloadRounded />}>
                                        Download PDF
                                    </Button>
                                </Stack>
                                {attachmentOpen ?
                                    <AttachmentView open={attachmentOpen} attachmentId={demandDoc.documentId + "-demand.pdf"} closeCallback={handleAttachmentClose} user={user} />
                                    : null}
                            </Grid>
                        </Grid>

                        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                            <Grid item={true} xs={12} className="adjuster-form-data">
                                <Stack direction={"row"} spacing={6}>
                                    <Stack direction={"column"} spacing={1}>
                                        <Stack direction={"row"} spacing={1}>
                                            <div className='adjuster-form-label'>Claim Number</div>
                                            <div>{demandDoc.claimInfo.claimNumber}</div>
                                        </Stack>
                                        <Stack direction={"row"} spacing={1}>
                                            <div className='adjuster-form-label'>Claimant</div>
                                            <div>{demandDoc.claimInfo.claimant.firstName} {demandDoc.claimInfo.claimant.lastName}</div>
                                        </Stack>
                                        <Stack direction={"row"} spacing={1}>
                                            <div className='adjuster-form-label'>Insured</div>
                                            <div>{demandDoc.claimInfo.insuredFirstName} {demandDoc.claimInfo.insuredLastName}</div>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"column"} spacing={1}>
                                        <Stack direction={"row"} spacing={1}>
                                            <div className='adjuster-form-label'>Date of Loss</div>
                                            <div>{formatDate(demandDoc.claimInfo.dateOfLoss)}</div>
                                        </Stack>
                                        <Stack direction={"row"} spacing={1}>
                                            <div className='adjuster-form-label'>Loss State</div>
                                            <div>{demandDoc.claimInfo.lossState}</div>
                                        </Stack>
                                        <Stack direction={"row"} spacing={1}>
                                            <div className='adjuster-form-label'>Loss County</div>
                                            <div>{demandDoc.claimInfo.lossCounty}</div>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"column"} spacing={1}>
                                        <Stack direction={"row"} spacing={1}>
                                            <div className='adjuster-form-label'>Date of Demand</div>
                                            <div>{formatDate(demandDoc.demandDetails.demandWrittenDate)}</div>
                                        </Stack>
                                        <Stack direction={"row"} spacing={1}>
                                            <div className='adjuster-form-label'>Demand Amount</div>
                                            <div>{formatCurrency(demandDoc.demandDetails.demandOfferToSettleAmount)} </div>
                                        </Stack>
                                        <Stack direction={"row"} spacing={1}>
                                            <div className='adjuster-form-label'>Response Due Date</div>
                                            <div>{formatDate(demandDoc.demandDetails.demandResponseDueDate)}</div>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"column"} spacing={1}>
                                        <Stack direction={"row"} spacing={1}>
                                            <div className='adjuster-form-label'>Claim Coverage</div>
                                            <div>{demandDoc.claimInfo.claimCoverage}</div>
                                        </Stack>
                                        <Stack direction={"row"} spacing={1}>
                                            <div className='adjuster-form-label'>Policy Limit</div>
                                            <div>{formatCurrency(demandDoc.claimInfo.insuredPolicyLimitsAsUnderstoodByFirm)}</div>
                                        </Stack>
                                        <Stack direction={"row"} spacing={1}>
                                            <div className='adjuster-form-label'>Policy Limit Demand</div>
                                            <div>{demandDoc.demandDetails.policyLimitDemandIndicator ? 'Yes' : 'No'}</div>
                                        </Stack>
                                    </Stack>
                                </Stack>

                            </Grid>
                        </Paper>

                        <Box sx={{ width: '100%', marginTop: '20px' }}>
                            <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example" textColor='secondary' indicatorColor='secondary'>
                                    <Tab label="Highlights" {...a11yProps(0)} color='secondary' iconPosition='start' />
                                    <Tab label="Parties" {...a11yProps(1)} color='secondary' iconPosition='start' />
                                    <Tab label="Facts & Liabilities" {...a11yProps(2)} color='secondary' iconPosition='start' />
                                    <Tab label="Incurred Damages" {...a11yProps(3)} iconPosition='start' />
                                    <Tab label="Future Damages" {...a11yProps(4)} iconPosition='start' />
                                    <Tab label="Pain & Suffering" {...a11yProps(5)} iconPosition='start' />
                                    <Tab label="PIP/MEDPAY" {...a11yProps(6)} iconPosition='start' />
                                </Tabs>
                            </Box>

                            <CustomTabPanel value={value} index={0} padding="0">
                                <AdjusterViewerHighlights demandDoc={demandDoc} formData={formData} changeTab={setValue} user={user} />
                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={1} padding="0">
                                <AdjusterViewParties demandDoc={demandDoc} user={user} />
                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={2} padding="0">

                                <AdjusterViewerFacts demandDoc={demandDoc} user={user} />

                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={3} padding="0">

                                <AdjusterViewerIncurredDamages demandDoc={demandDoc} user={user} />

                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={4} padding="0">

                                <AdjusterViewerFutureDamages demandDoc={demandDoc} user={user} />

                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={5} padding="0">

                                <AdjusterViewerPainSuffering demandDoc={demandDoc} user={user} />

                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={6} padding="0">

                                <AdjusterViewerPIPMedpay demandDoc={demandDoc} user={user} />

                            </CustomTabPanel>


                            <Dialog
                                open={infoDialogOpen}
                                TransitionComponent={Transition}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle>{infoDialogTitle}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        {infoDialogText}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => { handleInfoDialogClose() }}>Ok</Button>
                                </DialogActions>
                            </Dialog>

                            {demandGenOpen ? (
                                <GeneratedDemandView open={demandGenOpen} documentId={documentId} closeCallback={handleDemandGenClose} user={user} />
                            ) : null}

                        </Box>
                    </>
                ) : (
                    <div>Loading</div>
                )}
            </ThemeProvider>
        </Container>

    );
};
