import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Stack, Typography, Link, Box } from '@mui/material';
import { WarningAmber } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { ICD_CODES_URL_PATH, NEEDS_REVIEW_STATUS, mapPageInsightStatusToDisplayStatus } from './insights';
import { setSelectedRowsAndIcdCodeIds } from '../redux/slices/icdCodesSlice';
import { useGetEntityInsightsDetailsQuery } from '../services/insights/insightsApi';
import { useGetAllFilesByDocumentIdQuery } from '../services/documentFiles/documentFilesApi';

const dataGridStyles = { backgroundColor: '#FFFFFF' };

const initialState = {
  sorting: {
    sortModel: [{ field: 'page', sort: 'asc' }],
  },
};

const MedicalsDiagnosesDetailPanelContent = ({ icdCode, documentId, handleViewPageInDocument, userData, checkBoxesEnabled }) => {
  const { data: icdDetails = [], isLoading: isIcdDetailsLoading } = useGetEntityInsightsDetailsQuery({
    documentId,
    insightsType: ICD_CODES_URL_PATH,
    insightValue: icdCode,
  });
  const { data: documentFiles = [] } = useGetAllFilesByDocumentIdQuery(documentId);

  const { selectedRowsAndIcdCodeIds } = useSelector((state) => state.IcdCodes);
  // The selected Icd code ids are the selected sub rows.
  const rowSelectionModel = selectedRowsAndIcdCodeIds[icdCode] || [];

  const dispatch = useDispatch();

  const useShortDetailPanel = icdDetails.length < 7;

  const getRowId = useCallback((row) => row.entityInsightId, []);

  const getRowClassName = useCallback(
    (params) => params.row.entityInsightData.status === NEEDS_REVIEW_STATUS && 'grid-row-warning',
    []
  );

  const getRowHeight = useCallback(() => 'auto', []);

  const handleRowSelectionChange = useCallback(
    (newRowSelectionModel) => {
      // The row selections here are the sub-rows. Setting the key (ICD code) as the parent row, and value is the sub-row selection.
      const newSelectedIcdCodeIds = {
        ...selectedRowsAndIcdCodeIds,
        [icdCode]: newRowSelectionModel,
      };

      if (newRowSelectionModel.length === 0) {
        delete newSelectedIcdCodeIds[icdCode];
      }

      dispatch(setSelectedRowsAndIcdCodeIds(newSelectedIcdCodeIds));
    },
    [icdCode, selectedRowsAndIcdCodeIds, dispatch]
  );

  const getDocumentName = (fileEntityId) => {
    const file = documentFiles.find((file) => file.fileEntityId === fileEntityId);
    return file ? file?.fileEntityData?.fileName : '';
  };

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: () => null,
    },
    {
      field: 'document',
      headerName: 'Document',
      width: 400,
      editable: false,
      filterable: true,
      valueGetter: (params) => getDocumentName(params.row.fileEntityId),
      renderCell: ({ row }) => {
        const { entityInsightData, fileEntityId } = row;
        const documentName = getDocumentName(fileEntityId);

        return (
          <>
            {entityInsightData?.status === NEEDS_REVIEW_STATUS && <WarningAmber color="warning" sx={{ mr: 1 }} />}
            {entityInsightData?.generated_page_number ? (
              <Link
                component="button"
                onClick={() =>
                  handleViewPageInDocument(entityInsightData?.generated_page_number, entityInsightData?.bboxes)
                }
                style={{ textDecoration: 'none', textAlign: 'left' }}
              >
                <Typography variant="tableP1" style={{ color: '#4077B0' }}>
                  {documentName}
                </Typography>
              </Link>
            ) : (
              <Typography variant="tableP1">{documentName}</Typography>
            )}
          </>
        );
      },
    },
    {
      field: 'page',
      headerName: 'Page',
      width: 100,
      editable: false,
      filterable: true,
      valueGetter: (params) => params.row?.entityInsightData?.generated_page_number || '',
      renderCell: (params) => {
        return <Typography variant="tableP1">{params.row?.entityInsightData?.generated_page_number || ''}</Typography>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      editable: false,
      filterable: true,
      valueGetter: (params) => params.row.entityInsightData.status,
      renderCell: (params) => {
        return (
          <Typography variant="tableP1">
            {mapPageInsightStatusToDisplayStatus[params.row.entityInsightData.status]}
          </Typography>
        );
      },
    },
  ];

  return (
    <Stack
      direction="column"
      p={2}
      spacing={1}
      sx={{ backgroundColor: grey[100], borderBottom: '1px solid', borderColor: 'divider' }}
    >
      <Typography variant="body2">References to {icdCode}</Typography>
      <Box
        sx={{
          height: useShortDetailPanel ? '' : '400px',
        }}
      >
        <DataGridPro
          rows={icdDetails}
          columns={columns}
          loading={isIcdDetailsLoading}
          density="compact"
          disableRowSelectionOnClick
          disableColumnReorder
          disableColumnSelector
          disableColumnPinning
          sx={dataGridStyles}
          getRowId={getRowId}
          getRowClassName={getRowClassName}
          getRowHeight={getRowHeight}
          autoHeight={useShortDetailPanel ? true : false}
          checkboxSelection={checkBoxesEnabled}
          onRowSelectionModelChange={handleRowSelectionChange}
          rowSelectionModel={rowSelectionModel}
          initialState={initialState}
        />
      </Box>
    </Stack>
  );
};

export default MedicalsDiagnosesDetailPanelContent;
