import React from 'react';
import { Chip, CircularProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const LoadingStatusChip = ({ isLoading, label }) => {
  const styles = isLoading
    ? {
        color: '#0288D1',
        backgroundColor: '#ffffff',
        border: '1px solid #0288D1',
      }
    : { backgroundColor: '#E8F5E9', border: '1px solid #2E7D32', '& .MuiChip-icon': { color: '#2E7D32' } };

  return (
    <Chip
      label={label}
      icon={
        isLoading ? (
          <CircularProgress size={20} sx={{ '.MuiCircularProgress-svg': { color: '#0288D1' } }} />
        ) : (
          <CheckCircleOutlineIcon />
        )
      }
      sx={styles}
    />
  );
};

export default LoadingStatusChip;
