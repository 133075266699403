import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Alert, AlertTitle, Button, CircularProgress, Stack, TextField, ThemeProvider, Box, Typography, IconButton, Toolbar } from '@mui/material';
import { CheckCircleOutlined, CloseOutlined, SendOutlined } from '@mui/icons-material';
import awsExports from '../aws-exports';
import { theme } from '../Theme'
import { buildProviderSummary } from '../common-medicals';
import MedicalsOverview from './MedicalsOverview';
import MedicalsTreatmentsChart from '../eCharts/MedicalsTreatmentsChart';
import MedicalsProviderSummary from './MedicalsProviderSummary';
import MedicalsTreatments from './MedicalsTreatments';
import { setCookie, getCookie } from '../common';
import { setIsNavbarDisplayed } from '../redux/slices/navbarSlice';
import { useDispatch, useSelector } from 'react-redux';
import CollapseCard from "../CommonComponents/CollapseCard";

export const MedicalsViewer = () => {

    const rootApiUrl = awsExports.ROOT_API_URL;
    const { documentId } = useParams();
    const [documentData, setDocumentData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [documentArchived, setDocumentArchived] = useState(false);
    const [shortCode, setShortCode] = useState("");
    const [pin, setPin] = useState("");
    const [pinVerified, setPinVerified] = useState(false);
    const [providerSummary, setProviderSummary] = useState([]);
    const [ipAddress, setIpAddress] = useState();
    const [showPinEntryPage, setShowPinEntryPage] = useState(true);
    const [alertState, setAlertState] = useState({ infoAlert: true, successAlert: true });
    const inputRef = useRef(null);

    const dispatch = useDispatch();

    const { medicals } = useSelector((state) => state.Medicals);
    const medicalTreatments = medicals?.medicalTreatments ?? [];

    useEffect(() => {
        dispatch(setIsNavbarDisplayed(pinVerified));
    }, [pinVerified]);

    useEffect(() => {
        inputRef.current && inputRef.current.focus();
    }, [inputRef.current]);


    useEffect(() => {
        // Get the IP Address of the client
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIpAddress(data.ip));
    }, []);

    const getDocument = async () => {
        setLoading(true);

        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        };

        const response = await fetch(
            rootApiUrl + "/document/" + documentId + "/pin?pin=" + pin + "&ua=" + btoa(navigator.userAgent) + "&ip=" + ipAddress, settings)
            .then((documentData) => documentData)
            .catch((error) => error);
        setLoading(false);

        const responseJson = await response.json();
        if (responseJson.document) {
            setDocumentData(responseJson.document);
            setPinVerified(true);
            setError(false);
            setTimeout(() => {
                setShowPinEntryPage(false);
            }, 1000);
        }
        else {
            if (response.status == 405) {
                setShortCode(responseJson.shortCode);
                setDocumentArchived(true);
                setPinVerified(true);
                setTimeout(() => {
                    setShowPinEntryPage(false);
                }, 1200);
            }
            else {
                setError(true);
            }
        }
    };

    /**
     * Request for backend to record the page viewed.
     */
    const pinPagedViewApi = async () => {

        const pinCookieExists = getCookie("PinPageViewed") != null;

        if (!pinCookieExists) {
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json'
                },
                body: JSON.stringify({ ip: ipAddress, ua: navigator.userAgent })
            };

            const response = await fetch(
                rootApiUrl + "/pinPageViewed/" + documentId, settings
            ).then((response) => {
                return response;
            });

            // Set cookie so we can track these API calls
            setCookie('PinPageViewed', "true", 1);
        }
    };

    const handleOpenDemand = () => {
        getDocument();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (pin.length === 4 && !loading && !pinVerified) {
            handleOpenDemand();
        }
    };

    useEffect(() => {
        setProviderSummary(buildProviderSummary(medicalTreatments));
    }, [medicalTreatments]);

    useEffect(() => {
        if (ipAddress) {
            pinPagedViewApi();
        }
    }, [ipAddress]);

    useEffect(() => {

        //
        // Get the IP Address of the client
        //
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIpAddress(data.ip));
    }, []);

    const firmName = documentData?.sendingFirm?.firmName || "";
    const anyAlerts = alertState.infoAlert || alertState.successAlert;
    const claimNumber = documentData?.claimInfo?.claimNumber || "";
    const carrierCommonName = documentData?.recipientCarrier?.carrierCommonName || "";

    return (
        <Container maxWidth='70' sx={{
            flexGrow: 1,
            height: '100%',
            overflow: 'auto',
            padding: '10px'
        }}>
            <ThemeProvider theme={theme}>
                {!showPinEntryPage && documentArchived ? (
                    <Alert severity="warning" sx={{ m: 1, mt: 8 }}>
                        <AlertTitle>Document Unavailable</AlertTitle>
                        <Typography>
                            {`This document is no longer available to view.  Please contact helpdesk@precedent.com and reference short code: ${shortCode}`}
                        </Typography>
                    </Alert>

                )
                    : null}

                {!showPinEntryPage && documentData && (
                    <>
                        <Toolbar />
                        <Stack spacing={2}>
                            {alertState.successAlert && (
                                <Alert
                                    severity="success"
                                    variant="outlined"
                                    action={
                                        <Stack direction="row" spacing={2}>
                                            <Button
                                                href={`mailto:helpdesk@precedent.com?subject=Carrier: ${carrierCommonName}, Claim: ${claimNumber}`}
                                                target="_blank" rel="noopener noreferrer"
                                                size="small"
                                                color="inherit"
                                                startIcon={<SendOutlined />}
                                            >
                                                Email questions to helpdesk@precedent.com
                                            </Button>
                                            <IconButton onClick={() => setAlertState({ ...alertState, successAlert: false })} size="small" sx={{ color: "inherit", width: "30px", height: "30px" }}>
                                                <CloseOutlined fontSize="20px" />
                                            </IconButton>
                                        </Stack>
                                    }
                                >

                                    {`Confirmation of receipt delivered to ${firmName}`}
                                </Alert>
                            )}
                        </Stack>


                        <div style={{ marginTop: anyAlerts ? '25px' : '80px' }}>
                            <Stack direction="column" spacing={2}>
                                <CollapseCard title="Demand overview" expanded={true}>
                                    <MedicalsOverview documentData={documentData} />
                                </CollapseCard>

                                <MedicalsProviderSummary providerSummary={providerSummary} />
                                {medicalTreatments?.length > 0 &&
                                    <MedicalsTreatmentsChart documentData={documentData} />
                                }
                                <MedicalsTreatments pin={pin} viewType="carrier" documentId={documentId} documentDataProp={documentData} />
                            </Stack>
                        </div>
                    </>
                )}

                {!documentArchived && showPinEntryPage && (
                    <Box textAlign="center" marginTop="65px">
                        <img
                            src={process.env.PUBLIC_URL + '/precedent-fav.png'}
                            alt="precedent-fav"
                            width="60"
                            height="60"
                        />
                        <Typography variant="h3" fontFamily="Rubik" fontWeight="400" fontSize="48px" lineHeight="56.02px" color="#232941" sx={{ mt: 2 }}>
                            Welcome to Precedent Exchange
                        </Typography>
                        <Box marginTop="100px">
                            <Typography variant="h5" fontFamily="Rubik" fontWeight="400" fontSize="24px" lineHeight="32.02px" color="#616161">
                                Use the 4-digit PIN to open the interactive demand
                            </Typography>
                        </Box>

                        <Box marginTop="10px">
                            {error && (
                                <Alert severity="error" sx={{ width: 320, height: 70, m: 'auto' }}>
                                    <Typography variant="body2">
                                        The PIN could not be verified. Please check your entry and try again.
                                    </Typography>
                                </Alert>
                            )}
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    variant="outlined"
                                    size="medium"
                                    value={pin}
                                    error={error}
                                    disabled={loading || pinVerified}
                                    sx={{ width: 320, height: 56, mt: 2 }}
                                    onChange={(e) => {
                                        setError(false);
                                        const value = e.target.value.replace(/\D/g, '').slice(0, 4);
                                        setPin(value);
                                    }}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                    inputRef={inputRef}
                                    label="4 digit PIN"
                                />
                                <div style={{ margin: '10px auto' }}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color={pinVerified ? "success" : "primary"}
                                        startIcon={loading ? <CircularProgress size={24} color="inherit" /> : pinVerified ? <CheckCircleOutlined /> : null}
                                        sx={{
                                            marginTop: '7px',
                                            backgroundColor: pinVerified ? '#4caf50' : '#4077B0',
                                            color: '#FFFFFF',
                                        }}
                                        disabled={pin.length !== 4 || loading || error}
                                        type="submit"
                                    >
                                        {pinVerified ? "Verified" : loading ? "Verifying PIN" : "Open demand"}
                                    </Button>
                                </div>
                            </form>
                            <Box marginTop="70px">
                                <img src={process.env.PUBLIC_URL + '/pinEntryFooter.png'} alt="pinEntryFooter" />
                            </Box>
                        </Box>
                    </Box>
                )}
            </ThemeProvider>
        </Container >
    );
};
