import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const AddButton = ({ clickHandler }) => {

  return (
    <Button
      onClick={clickHandler}
      size="small"
      color="secondary"
      disabled={false}
      variant="text"
      startIcon={<AddCircleOutlineIcon />}
    >
      Add
    </Button>
  );
};

export default AddButton;