import { createSlice } from '@reduxjs/toolkit'

export const NavbarSlice = createSlice({
  name: 'Navbar',
  initialState: {
    isDisplayed: true
  },
  reducers: {
    setIsNavbarDisplayed: (state, action) => {
      state.isDisplayed = action.payload
    },
  },
})

export const { setIsNavbarDisplayed } = NavbarSlice.actions

export default NavbarSlice.reducer