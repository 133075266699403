import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography } from '@mui/material';
import { Transition } from '../../Transition'
import Title from '../Title'
import ConfirmationSwitch from "../../CommonComponents/ConfirmationSwitch";

const UpdateContactDialog = ({ dialogOpen, dialogTitle, dialogText, cancelButtonText, okButtonText, buttonColor, handleCancel, handleOk, type, carrierName, handleUpdateDefaultDeliverySwitched, updateDefaultDeliverySwitched }) => {
    const [switchChecked, setSwitchChecked] = useState(false);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={dialogOpen}
            TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle sx={{ color: '#4077B0' }}>{dialogTitle}</DialogTitle>
            <DialogContent>
                <Title>{carrierName}</Title>
                <Typography sx={{ fontFamily: 'Rubik', fontWeight: 500, fontSize: '14px', mt: 1.5 }}>
                    {`Carrier ${type}`}
                </Typography>
                <DialogContentText sx={{ mt: 1 }} id="alert-dialog-slide-description">
                    {dialogText}
                </DialogContentText>

                <ConfirmationSwitch labelText={`Set ${type} as global default delivery method`} setSwitchChecked={e => {
                    setSwitchChecked(!switchChecked)
                    handleUpdateDefaultDeliverySwitched(!switchChecked)
                }}
                    switchChecked={switchChecked}
                />
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={() => { handleCancel() }}>{cancelButtonText}</Button>
                <Button variant={"contained"} color={buttonColor || 'secondary'} onClick={() => { handleOk() }} disabled={!updateDefaultDeliverySwitched}>{okButtonText}</Button>
            </DialogActions>
        </Dialog>
    )

}


export default UpdateContactDialog;