import { unixToISO8601 } from '../common';

// Observability dependencies
import userflow from 'userflow.js';
import { userflowSafeObject } from './observabilityHelpers'


const isDevMode = process.env.NODE_ENV === 'development';


// NOTE: Nothing in observability should ever be fatal and blow back upwards to prevent external issues from impacting the live user experience.


// Call at app init to initialize any observability prodviders
export const initializeObservability = async () => {
    // Initialize Userflow with env token
    try {
        const USERFLOW_ENV_TOKEN = process.env.REACT_APP_USERFLOW_ENV_TOKEN;
        if (USERFLOW_ENV_TOKEN) {
            await userflow.init(USERFLOW_ENV_TOKEN); // Initialize UserFlow
        } else {
            if (isDevMode) {
                console.log("Failure locating token for UserFlow.");
            } else {
                console.log("Failure in setup for UF.");  // Intentionally ambiguous since users could see console log
            }
        }
    } catch (e) {
        if (isDevMode) {
            console.log("Failure initializing UserFlow observability."); // Log out the issue, but keep is fuzzy since it's user visible.
            console.log(e);
        }
    }

    // In future initialize additional observability packages
}

// Call on establishing user identity
export const observeUserIdentityEstablished = async (userDataWithAttributes) => {
    // Identify the user to UserFlow
    try {
        if (userDataWithAttributes) { // User identity provided
            // Identify the user's attributes
            await userflow.identify(userDataWithAttributes.userId, {
                customerId: userDataWithAttributes.customerId,
                signed_up_at: {
                set: unixToISO8601(userDataWithAttributes.createdTs),
                data_type: 'datetime'
                },
                role: userDataWithAttributes.roles[0],
                email: userDataWithAttributes.emailAddress
            });

            // Associate the user with a customer group
            await userflow.group(userDataWithAttributes.customerId, {}, {
                membership: {
                    role: userDataWithAttributes.roles[0], // User's role is their applicable role in their customer group
                }
            });

            if (isDevMode) {
                console.log("Established UserFlow identity.");
            }
        } else { // No identity provided
            await userflow.identifyAnonymous();
            if (isDevMode) {
                console.log("Established Anonymous UserFlow identity.");
            }
        }
    } catch (e) {
        if (isDevMode) {
            console.log("Failure establishing UserFlow identity.");
            console.log(e);
        }
    }

    // In future notify other observability packages of user identity
}

// Call on clearing of user identity.
export const observeUserIdentityCleared = async () => {
    // Notify Userflow that identity has been cleared
    try {
        await userflow.reset();  // Reset the user on logout
    } catch (e) {
        if (isDevMode) {
            console.log("Failure clearing UserFlow identity.");
            console.log(e);
        }
    }

    // In future notify other observability packages of user identity clear
}

// Call from places we want to observe (buttons, etc.)
export const observeEvent = async (name, attributes = {}) => {
    // Emit event to Userflow
    try {
        await userflow.track(name, userflowSafeObject(attributes));
    } catch (e) {
        if (isDevMode) {
            console.log("Failed to observe UserFlow event.");
            console.log(e);
        }
    }

    // In future emit events or equivalents to other observability packages. Not all packages will support all types of attributes
}

// TODO: Consider an "observe error" function, which can catch and log errors specifically. For some providers this could just emit events if they don't have a specific error tracking capability, on a per-provider basis

// TODO: Consider basic wrappers, i.e. button wrapper so all buttons can auto-emit events, once we confirm we like the patterns here
