// Strip an object to something safe to send to UserFlow
export const userflowSafeObject = (objectToMakeSafe) => objectFilter(objectToMakeSafe, item => item !== Object(item)); // Filter to non Objects

// Utility function to filter object attributes
// Source: https://marcoghiani.com/snippets/filter-method-for-object-properties
const objectFilter = (obj, predicate) => {
  const newObj = {};

  for (let prop in obj) {
    if (obj.hasOwnProperty(prop) && predicate(obj[prop], prop, obj)) {
      newObj[prop] = obj[prop];
    }
  }

  return newObj;
}
