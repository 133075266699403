import { dateComparator } from './comparators';
import { oneDay } from './common-data';

export const buildProviderSummary = (medicalTreatments) => {

    const summary = {};
    medicalTreatments.forEach((medical) => {
        if (medical.status === 'INCLUDED') {
            const treatmentFacility = (medical?.treatmentFacility || '').toUpperCase();

            let facility = summary[treatmentFacility];
            if (!facility) {
                facility = {
                    visits: 0,
                    visitDates: [],
                    treatmentFacility,
                    startDate: medical.treatmentDate,
                    endDate: medical.treatmentDate,
                    billedAmount: 0.0,
                    uniqueDOS: 0,
                    treatmentDuration: 0
                };
                summary[treatmentFacility] = facility;
            }

            if (dateComparator(medical.treatmentDate, facility.endDate) > 0)
                facility.endDate = medical.treatmentDate;

            if (dateComparator(medical.treatmentDate, facility.startDate) < 0)
                facility.startDate = medical.treatmentDate;

            if (!facility.visitDates.includes(medical.treatmentDate)) {
                facility.visits += 1;
                facility.visitDates.push(medical.treatmentDate);
            }
            facility.billedAmount += parseFloat(medical.billedAmount);
            facility.uniqueDOS = facility.visitDates.length;
            const startDate = new Date(facility.startDate);
            const endDate = new Date(facility.endDate);
            const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay)) + 1;
            facility.treatmentDuration = diffDays;
        }
    });

    const overall = {}
    overall.treatmentFacility = "Overall"
    overall.visits = 0
    overall.billedAmount = 0.0
    overall.uniqueDOS = 0
    overall.treatmentDuration = 0
    const providerList = [];
    for (let key in summary) {
        providerList.push(summary[key]);
        let facility = summary[key]
        !overall.startDate && (overall.startDate = facility.startDate)
        !overall.endDate && (overall.endDate = facility.endDate)
        dateComparator(facility.endDate, overall.endDate) > 0 && (overall.endDate = facility.endDate)
        dateComparator(facility.startDate, overall.startDate) < 0 && (overall.startDate = facility.startDate)
        overall.visits += facility.visits
        overall.billedAmount += facility.billedAmount
        overall.uniqueDOS += facility.uniqueDOS
        const startDate = new Date(overall.startDate);
        const endDate = new Date(overall.endDate);
        const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay)) + 1;
        overall.treatmentDuration = diffDays;
    }
    providerList.sort((a, b) => dateComparator(a.startDate, b.startDate));  // sort by first treatment date
    providerList.push(overall)

    return providerList;
};