import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Stack, Button, InputAdornment, DialogContent, DialogTitle, Typography, MenuItem, FormControl, TextField as MuiTextField, Box, Tooltip, Divider, Snackbar, Collapse, Alert, AlertTitle, Paper, FormHelperText, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from "@mui/lab";
import { Close, CloudUploadOutlined, InfoOutlined } from '@mui/icons-material';
import { Form } from 'react-final-form';
import { TextField, Autocomplete, Switches, Radios } from 'mui-rff';
import DocumentUploader from '../DocumentUploader';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';
import Title from '../../CommonComponents/Title';
import { DiscardWork } from '../../dialogs/DialogText';
import { setCreateNewDemandOpen, setDocumentUploaderList } from '../../redux/slices/demandDomSlice';
import { fetchAuthorFormData } from '../../redux/thunks/demandData';
import { fetchCustomerData } from '../../redux/thunks/customerData';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableLiveTemplatesForCustomer, getConfigValue } from '../../api';
import { pollPdfRegenerationStatus } from '../../services/pdf/thunks';
import { isLawFirmUser, isPrecedentUser } from '../../common-roles';
import { formatDecimal, getCurrentDate, cleanNumber, normalizeEmailAddress, formatPhoneNumber, userHasPermission, MAX_DAYS_RELATIVE_DUE_DATE } from '../../common';
import { coverageList, stateLabelValues } from '../../common-data';
import { DOCUMENT_UPLOAD_FILE_TYPES } from '../../common-document';
import { parseNestedObject } from '../../helpers/formHelpers';
import { uploadFilesAndPostToDemand } from '../utilities';
import { buildCarrierAutoCompleteData, loadCarriers, validate, NO_TEMPLATE, buildUserAutoCompleteData, autoFillCreateNewDemandForm, getSingleSelectAutoCompleteFilteredOptions, getMultiSelectAutoCompleteFilteredOptions, joinNames, getUserValues } from './FormHelpers';
import { handleDemandCreationSave } from './Utilities';
import EditDemandDetailsSkeleton from './EditDemandDetailsSkeleton';
import { theme } from '../../Theme';
import { useSaveDocumentDataMutation, useLazyGetDocumentDataQuery } from '../../services/documentData/documentDataApi';
import SaveAnimationDialog from '../../dialogs/SaveAnimationDialog';
import { setToast } from '../../redux/slices/globalToastSlice';
import { setDocumentStatus, setIsAiProcessingForCreateDocument } from '../../redux/slices/documentSlice';
import CreateNewDemandSkeleton from './CreateNewDemandSkeleton';

const CreateNewDemand = ({ useDemandNotesEnabled }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //rtk query
    const [triggerGetDocumentData, { data: DocumentDataFetchResponseDictionary, isLoading: isDocumentLoading }] = useLazyGetDocumentDataQuery();
    const [saveDocumentData, { isLoading: submitting, isSuccess: isSaveSuccessful, isError: hasSaveError, error: saveError }] = useSaveDocumentDataMutation();
    //redux state
    const { userData, user } = useSelector((state) => state.User);
    const { customerId } = userData;
    const { customerData } = useSelector((state) => state.Customer);
    const { createNewDemandOpen, documentUploaderList } = useSelector(state => state.DemandDom);
    const { users, approvers } = useSelector((state) => state.Author);
    //local state
    const [carriersData, setCarriersData] = useState([]);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [uploadErrorState, setUploadErrorState] = useState({});
    const [demandErrorState, setDemandErrorState] = useState({ show: false, severity: 'error', title: '', message: '' });
    const [dialogState, setDialogState] = useState({ discardChanges: false });
    const [toastState, setToastState] = useState({ show: false, severity: 'info', message: '' })
    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [defaultCustomerTemplate, setDefaultCustomerTemplate] = useState(NO_TEMPLATE);
    const [defaultRelativeDueDate, setDefaultRelativeDueDate] = useState(0);
    const [formDataLoaded, setFormDataLoaded] = useState(false);
    //configs
    const isEditBeforeAiProcessingEnabled = customerData?.customerEntityData?.canEditBeforeAiProcessing ?? false;
    //data mapping
    const approverIds = approvers?.map(approver => approver.userId) || [];
    const attorneys = users ? users.filter(user => approverIds.includes(user.userId)) : [];
    if (userData && !attorneys.some(({ userId }) => userId === userData.userId)) {
        attorneys.unshift({
            userId: userData.userId,
            firstName: userData.firstName,
            lastName: userData.lastName,
            contactInfo: { phoneNumber: userData.phone, emailAddress: userData.emailAddress }
        });
    }
    const attorney_auto_complete_data = buildUserAutoCompleteData(attorneys);
    const user_data_for_auto_completes = buildUserAutoCompleteData(users);
    const primaryContact_auto_complete_data = user_data_for_auto_completes;
    const carrier_auto_complete_data = buildCarrierAutoCompleteData(carriersData);
    const collaborators_auto_complete_data = user_data_for_auto_completes;
    const defaultCollaboratorsUserIds = customerData?.defaultCollaboratorsUserIds || [];

    //useEffects
    useEffect(() => { dispatch(fetchCustomerData({ customerId, user })); }, [customerId]);

    const token = user?.signInUserSession?.accessToken?.jwtToken || '';

    useEffect(() => {
        if (!user || !token) return;

        const tokenPayload = { token, user, hasPermission: userHasPermission('DemandCompose', userData) };

        const fetchData = async () => {
            try {
                await dispatch(fetchAuthorFormData(tokenPayload));
                loadCarriers(user, setCarriersData);

                // Await all data fetching promises
                const [templatesResponse, defaultTemplateResponse, relativeDueDateResponse] = await Promise.all([
                    getAvailableLiveTemplatesForCustomer(user),
                    getConfigValue('defaultTemplateId', null, user),
                    getConfigValue('defaultRelativeDueDate', null, user),
                ]);

                if (templatesResponse?.templates) {
                    setAvailableTemplates([{ templateId: NO_TEMPLATE, templateName: NO_TEMPLATE }, ...templatesResponse.templates]);
                }
                if (defaultTemplateResponse) setDefaultCustomerTemplate(defaultTemplateResponse);
                if (relativeDueDateResponse) setDefaultRelativeDueDate(relativeDueDateResponse);

                setFormDataLoaded(true);

            } catch (error) {
                dispatch(setToast({ isOpen: true, message: 'Failed to load data', severity: 'error' }));
            }
        };

        fetchData();
    }, [user, token]);


    const handleSaveSuccessCallback = (documentId) => {
        if (!documentId) {
            dispatch(setToast({ isOpen: true, message: 'Failed to create demand', severity: 'error' }));
            dispatch(setCreateNewDemandOpen(false));
        }
        if (isLawFirmUser(userData)) {
            dispatch(setCreateNewDemandOpen(false));
            navigate('/l/' + documentId);
        } else if (isPrecedentUser(userData)) {
            dispatch(setCreateNewDemandOpen(false));
            navigate('/a/' + documentId);
        }
    }

    const handleClose = () => dispatch(setCreateNewDemandOpen(false))

    const initialValues = useMemo(() => {
        const currentDate = getCurrentDate();

        const initialCollaborators = defaultCollaboratorsUserIds.length > 0 ?
            defaultCollaboratorsUserIds.map(userId => {
                const user = users.find(user => user.userId === userId);
                return { label: joinNames(user), value: getUserValues(user) }
            }) : undefined;

        return {
            metadata: {
                demandTemplateId: defaultCustomerTemplate,
            },
            sendingFirm: {
                caseManagers: []
            },
            claimInfo: {
                dateOfLoss: currentDate,
                lossState: 'none',
                clientIsInjuredParty: true,
                insuredIsTortfeasor: true
            },
            demandDetails: {
                displayDemandAmount: false,
                policyLimitDemandIndicator: false,
                demandResponseRelativeDueDate: defaultRelativeDueDate ?? '',
                usingRelativeDueDate: defaultRelativeDueDate == 0 ? false : true
            },
            mutators: {
                attorneyIsPrimaryContact: true,
            },
            autoCompletes: {
                collaborators: initialCollaborators
            }
        }
    }, [users, defaultCustomerTemplate, defaultCollaboratorsUserIds]);

    const handleSaveClick = async (values, sendForAiProcessing = true) => {
        const body = parseNestedObject({
            ...values,
            documentType: 'COMPOSITEDEMAND',
            damagesSummary: {
                ...values.damagesSummary,
                demandDamagesAmount: values?.damagesSummary?.demandDamagesAmount ? cleanNumber(values.damagesSummary.demandDamagesAmount) : ''
            },
            demandDetails: {
                ...values.demandDetails,
                demandOfferToSettleAmount: values?.demandDetails?.demandOfferToSettleAmount ? cleanNumber(values.demandDetails.demandOfferToSettleAmount) : ''
            },
            sendingFirm: {
                ...values.sendingFirm,
                //use autoComplete values to populate respective fields on sendingFirm object onSave
                attorney: values?.autoCompletes?.attorney?.value,
                primaryContact: values?.mutators?.attorneyIsPrimaryContact ? values?.autoCompletes?.attorney?.value : values?.autoCompletes?.primaryContact?.value,
                caseManagers: (values?.autoCompletes?.collaborators || []).map(collaborator => collaborator.value)
            },
            claimInfo: {
                ...values.claimInfo,
                insuredPolicyLimitsAsUnderstoodByFirm: values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm ? cleanNumber(values.claimInfo.insuredPolicyLimitsAsUnderstoodByFirm) : ''
            },
            defaultDeliveryInformationEnabled: values?.autoCompletes?.carrier?.value?.defaultDeliveryInformationEnabled,
            recipientCarrier: {
                //use autoComplete values to populate respective fields on recipientCarrier object onSave
                ...values?.recipientCarrier,
                carrierCustomerAliasId: values?.autoCompletes?.carrier?.value?.carrierCustomerAliasId,
                carrierCommonName: values?.autoCompletes?.carrier?.value?.carrierCommonName || values?.recipientCarrier?.carrierCommonName,
                //use recipientCarrier.contactInfo values to populate respective fields b/c these fields are not fully controlled by autoComplete selection
                contactInfo: { ...values?.recipientCarrier?.contactInfo }
            },
            recipientAdjuster: {
                ...values.recipientAdjuster,
                contactInfo: {
                    ...values?.recipientAdjuster?.contactInfo,
                    emailAddress: values?.recipientAdjuster?.contactInfo?.emailAddress ? normalizeEmailAddress(values.recipientAdjuster.contactInfo.emailAddress) : '',
                    phoneNumber: values?.recipientAdjuster?.contactInfo?.phoneNumber ? formatPhoneNumber(values.recipientAdjuster.contactInfo.phoneNumber) : ''
                }
            },
            customerSpecialNotes: values?.customerSpecialNotes ? values.customerSpecialNotes : '',
        });
        delete body.autoCompletes;  //remove autoCompletes from document body before submitting
        delete body.mutators;  //remove mutators from document body before submitting

        if (sendForAiProcessing) {
            dispatch(setDocumentStatus('AwaitingMedicalExtraction'));
            dispatch(setIsAiProcessingForCreateDocument(true));
        }
        const pollPdfStatus = (documentId) => dispatch(pollPdfRegenerationStatus({ documentId }));

        handleDemandCreationSave({
            data: body,
            dispatch,
            saveDocumentData,
            triggerGetDocumentData,
            sendForAiProcessing,
            setIsSaving: setFormSubmitting,
            callbacks: {
                handleSaveSuccessCallback,
                uploadFiles: (documentId) => uploadFilesAndPostToDemand(documentUploaderList, documentId, user, setUploadErrorState, uploadErrorState, pollPdfStatus, sendForAiProcessing)
            }
        });
    }

    const haveFilesUploaded = documentUploaderList.length > 0;

    const handleCloseButtonClick = (dirty) => dirty ? setDialogState({ ...dialogState, discardChanges: true }) : handleClose();

    const confirmationDialogProps = {
        dialogOpen: dialogState.discardChanges,
        dialogText: DiscardWork.text,
        cancelButtonText: DiscardWork.cancelText,
        okButtonText: DiscardWork.confirmText,
        handleCancel: () => {
            setDialogState({ ...dialogState, discardChanges: false });
        }
    }

    return (
        <>
            <Form
                validate={(values) => validate({ values, userData })}
                onSubmit={() => null}
                initialValues={initialValues}
                render={({ handleSubmit, form, submitting, pristine, values, valid, errors, dirty, fields, dirtyFields, dirtySinceLastSubmit, touched, visited }) => {
                    const carrierId = values?.autoCompletes?.carrier?.value?.carrierId;
                    const hasUploadErrors = documentUploaderList.some((file) => file.error);

                    return (
                        <form id="createDemand" onSubmit={handleSubmit}>
                            <SaveAnimationDialog
                                isSaving={formSubmitting}
                                message={"Submitting demand..."}
                            />

                            <DialogTitle className='dialog-title-blue' sx={{ height: "60px", width: "100%", zIndex: 1000 }} display="flex" alignItems="center" position={"fixed"}>
                                <Stack direction={"row"} style={{ width: "100%" }} alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>

                                        <IconButton onClick={e => handleCloseButtonClick(dirty || haveFilesUploaded)} aria-label="close" sx={{ mr: 2, '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.15)" } }}>
                                            <Close color={'white'} />
                                        </IconButton>

                                        <Title color={theme.palette.white.main}>Compose demand</Title>

                                    </Stack>

                                    <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-end"}>
                                        {haveFilesUploaded &&
                                            <>
                                                <input
                                                    accept={DOCUMENT_UPLOAD_FILE_TYPES}
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file"
                                                    multiple
                                                    type="file"

                                                    onChange={(e) => {
                                                        const files = e.target.files;
                                                        const newFileList = [...documentUploaderList, ...files];
                                                        //iterate through new file list and if files have same name- remove the duplicate
                                                        const uniqueFiles = newFileList.filter((file, index, self) =>
                                                            index === self.findIndex((t) => (
                                                                t.name === file.name
                                                            ))
                                                        )
                                                        uniqueFiles.forEach((file) => {
                                                            file.error = file.size > 157286400 ? 'File size cannot exceed 150MB' : null;
                                                        })
                                                        dispatch(setDocumentUploaderList(uniqueFiles));
                                                    }}

                                                />
                                                <label htmlFor="raised-button-file">
                                                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, }}>
                                                        <Button
                                                            component="span"
                                                            variant={isEditBeforeAiProcessingEnabled ? "text" : "outlined"}
                                                            color="white"
                                                            startIcon={<CloudUploadOutlined />}
                                                        >
                                                            Upload additional documents
                                                        </Button>
                                                    </Box>
                                                    <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, }}>
                                                        <IconButton size='small'
                                                            component="span"
                                                            color={'white'}
                                                            sx={{ '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.15)" } }}
                                                        >
                                                            <CloudUploadOutlined />
                                                        </IconButton>
                                                    </Box>
                                                </label>
                                            </>
                                        }

                                        {isEditBeforeAiProcessingEnabled && (
                                            <LoadingButton
                                                disabled={!valid || formSubmitting || !dirty || !haveFilesUploaded || hasUploadErrors}
                                                sx={{ '&:disabled': { color: ("rgba(255,255,255,0.2)"), borderColor: ("rgba(255,255,255,0.2)") } }}
                                                onClick={() => {
                                                    setDemandErrorState({ ...demandErrorState, show: false });
                                                    handleSaveClick(values, false)
                                                }}
                                                variant="outlined"
                                                color="white"
                                            >
                                                <span>Save and edit before AI processing</span>
                                            </LoadingButton>
                                        )}

                                        <Box sx={{ '& > button': { m: 1 } }}>
                                            <LoadingButton
                                                disabled={!valid || formSubmitting || !dirty || !haveFilesUploaded || hasUploadErrors}
                                                sx={{ '&:disabled': { backgroundColor: ("rgba(255,255,255,0.2)") } }}
                                                onClick={() => {
                                                    setDemandErrorState({ ...demandErrorState, show: false });
                                                    handleSaveClick(values, true)
                                                }}
                                                variant="contained"
                                                color="white"
                                            >
                                                <span>Submit for AI processing</span>
                                            </LoadingButton>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </DialogTitle>

                            <DialogContent sx={{ marginTop: '0px', paddingTop: '0px', overflow: 'visible' }}>
                                <Grid container spacing={2} sx={{ mt: 8, mb: 5 }}>
                                    {demandErrorState.show &&
                                        <Grid xs={12}>
                                            <Collapse in={demandErrorState.show}>
                                                <Alert severity={demandErrorState.severity}>
                                                    <AlertTitle>{demandErrorState.title}</AlertTitle>
                                                    {demandErrorState.message}
                                                </Alert>
                                            </Collapse>
                                        </Grid>
                                    }
                                    {/* Left Column */}
                                    <Grid xs={12} sm={12} md={8} lg={7}
                                        sx={{
                                            paddingX: 0,
                                            marginX: 0,
                                        }}
                                    >
                                        <fieldset
                                            style={{
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                marginLeft: 0,
                                                marginRight: 0
                                            }}
                                            disabled={formSubmitting}>
                                            <>
                                                {!formDataLoaded || isDocumentLoading ?
                                                    <CreateNewDemandSkeleton />
                                                    :
                                                    <Stack
                                                        spacing={2}
                                                        divider={<Divider flexItem />}
                                                    >
                                                        {/* case details */}
                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Case details</Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} md={6}>
                                                                    <TextField fullWidth required label="Matter number or Case ID" size='small' name='sendingFirm.firmCaseNumber' />
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <TextField fullWidth label="Claim number" size='small' name='claimInfo.claimNumber' />
                                                                </Grid>
                                                                <Grid item xs={12} md={12}>
                                                                    <Stack
                                                                        spacing={1}
                                                                    >
                                                                        <TextField fullWidth required label="Client" name='claimInfo.claimant.firstName' size='small'
                                                                            onChange={(e) => {
                                                                                if (values.claimInfo?.clientIsInjuredParty) {
                                                                                    form.change('claimInfo.injuredPartyFullName', e.target.value);
                                                                                }
                                                                                form.change('claimInfo.claimant.firstName', e.target.value)
                                                                                if (values?.sendingFirm?.firmCaseNumber === "42" && values?.claimInfo?.claimNumber === "42" && e.target.value === "Precedent") {
                                                                                    autoFillCreateNewDemandForm(form, values, attorney_auto_complete_data, carrier_auto_complete_data, user_data_for_auto_completes)
                                                                                }
                                                                            }}
                                                                        />
                                                                        <Switches
                                                                            name="claimInfo.clientIsInjuredParty"
                                                                            onChange={(e) => {
                                                                                const checked = e.target.checked;
                                                                                form.change('claimInfo.clientIsInjuredParty', checked)
                                                                                if (checked) {
                                                                                    form.change('claimInfo.injuredPartyFullName', values?.claimInfo?.claimant?.firstName)
                                                                                } else {
                                                                                    form.change('claimInfo.injuredPartyFullName', '')
                                                                                }
                                                                            }}
                                                                            data={{ label: 'Client is injured party', value: true }}
                                                                            color="secondary"
                                                                        />
                                                                        {!values.claimInfo?.clientIsInjuredParty &&

                                                                            <Box
                                                                                sx={{
                                                                                    paddingLeft: 6,
                                                                                    paddingBottom: 2
                                                                                }}
                                                                            >
                                                                                <TextField fullWidth required label="Injured party" name='claimInfo.injuredPartyFullName' size='small'
                                                                                />
                                                                            </Box>

                                                                        }
                                                                    </Stack>
                                                                </Grid>

                                                                <Grid item xs={12} md={12}>
                                                                    <Stack spacing={1}>
                                                                        <TextField fullWidth required label="Insured" name="claimInfo.insuredFirstName" size='small'
                                                                            onChange={(e) => {
                                                                                form.change('claimInfo.insuredFirstName', e.target.value)

                                                                                if (values.claimInfo?.insuredIsTortfeasor) {
                                                                                    form.change('claimInfo.tortfeasorFullName', e.target.value)
                                                                                }
                                                                            }} />
                                                                        <Switches
                                                                            name="claimInfo.insuredIsTortfeasor"
                                                                            onChange={(e) => {
                                                                                const checked = e.target.checked;
                                                                                form.change('claimInfo.insuredIsTortfeasor', checked)
                                                                                if (checked) {
                                                                                    form.change('claimInfo.tortfeasorFullName', values?.claimInfo?.insuredFirstName)
                                                                                } else {
                                                                                    form.change('claimInfo.tortfeasorFullName', '')
                                                                                }
                                                                            }}
                                                                            data={{ label: 'Insured is tortfeasor', value: true }}
                                                                            color="secondary"
                                                                        />
                                                                        {!values.claimInfo?.insuredIsTortfeasor &&
                                                                            <Box
                                                                                sx={{
                                                                                    paddingLeft: 6
                                                                                }}
                                                                            >
                                                                                <TextField fullWidth required label="Tortfeasor" name='claimInfo.tortfeasorFullName' size='small' />
                                                                            </Box>
                                                                        }
                                                                    </Stack>
                                                                </Grid>

                                                            </Grid>

                                                        </Stack>

                                                        {/* loss details */}
                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Loss details</Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField
                                                                        name='claimInfo.dateOfLoss'
                                                                        fullWidth={true}
                                                                        inputProps={{ max: getCurrentDate() }}
                                                                        label="Date of loss" type="date"
                                                                        InputLabelProps={{ shrink: true }}
                                                                        size='small'
                                                                        required
                                                                        //we are using MUI textfield here b/c datepicker is better; therefore, we need to add below props to explicitly create controlled component
                                                                        onChange={e => form.change('claimInfo.dateOfLoss', e.target.value)}
                                                                        value={values?.claimInfo?.dateOfLoss}
                                                                        error={errors.claimInfo?.dateOfLoss}
                                                                        helperText={errors.claimInfo?.dateOfLoss}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Coverage" name='claimInfo.claimCoverage' select size='small'>
                                                                        {coverageList.map((option) => (
                                                                            <MenuItem key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Loss state" name="claimInfo.lossState" size='small' select required>
                                                                        {[{ label: 'Select a state', value: 'none' }, ...stateLabelValues].map((option, idx) => (
                                                                            <MenuItem key={idx} value={option.value} disabled={idx === 0} >
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Loss county" name="claimInfo.lossCounty" size='small' />
                                                                </Grid>
                                                            </Grid>

                                                        </Stack>

                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Carrier details</Typography>
                                                            <Grid container spacing={2}>

                                                                <Grid xs={12}>
                                                                    <Autocomplete
                                                                        name='autoCompletes.carrier'
                                                                        freeSolo
                                                                        fullWidth
                                                                        blurOnSelect={true}
                                                                        filterSelectedOptions={true}
                                                                        filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                                        options={carrier_auto_complete_data}
                                                                        onChange={(e, selection) => {
                                                                            form.change('recipientCarrier', selection?.value || {
                                                                                carrierCommonName: '',
                                                                                carrierCustomerAliasId: '',
                                                                                contactInfo: {
                                                                                    address: {
                                                                                        city: '',
                                                                                        state: '',
                                                                                        street1: '',
                                                                                        street2: '',
                                                                                        zipCode: ''
                                                                                    },
                                                                                    emailAddress: '',
                                                                                    faxNumber: '',
                                                                                    phoneNumber: ''
                                                                                }
                                                                            })
                                                                        }}
                                                                        onInputChange={(e, value) => {
                                                                            if (e?.type === 'click') return;
                                                                            const match = carrier_auto_complete_data.find(option => option.label === value);
                                                                            const previousMatch = typeof values?.autoCompletes?.carrier !== 'string' ? carrier_auto_complete_data.find(option => option.label === values?.autoCompletes?.carrier?.label) : carrier_auto_complete_data.find(option => option.label === values?.autoCompletes?.carrier);
                                                                            if (match) {
                                                                                form.change('recipientCarrier', match.value)
                                                                                form.change('autoCompletes.carrier', match)
                                                                            } else {
                                                                                if (previousMatch) {
                                                                                    form.change('recipientCarrier', {
                                                                                        carrierCommonName: value,
                                                                                        carrierCustomerAliasId: '',
                                                                                        contactInfo: {
                                                                                            address: {
                                                                                                city: '',
                                                                                                state: '',
                                                                                                street1: '',
                                                                                                street2: '',
                                                                                                zipCode: ''
                                                                                            },
                                                                                            emailAddress: '',
                                                                                            faxNumber: '',
                                                                                            phoneNumber: ''
                                                                                        }
                                                                                    })
                                                                                } else {
                                                                                    form.change('recipientCarrier.carrierCommonName', value)
                                                                                }
                                                                            }
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                required
                                                                                name='autoCompletes.carrier'
                                                                                size='small'
                                                                                label="Carrier"
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                {!carrierId &&
                                                                    <>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField fullWidth label="Carrier fax number" name='recipientCarrier.contactInfo.faxNumber' type='tel' size='small' autoComplete='off'
                                                                                onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid xs={12} md={6}>
                                                                            <TextField fullWidth label="Carrier email" name='recipientCarrier.contactInfo.emailAddress' type='email' size='small' autoComplete='off' />
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                <Grid xs={12}>
                                                                    <Switches
                                                                        name="mutators.showCarrierContactInfo"
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            form.change('mutators.showCarrierContactInfo', checked)
                                                                            if (!checked) {
                                                                                form.change('recipientCarrier.contactInfo.phoneNumber', '');
                                                                                form.change('recipientCarrier.contactInfo.address.street1', '');
                                                                                form.change('recipientCarrier.contactInfo.address.street2', '');
                                                                                form.change('recipientCarrier.contactInfo.address.city', '');
                                                                                form.change('recipientCarrier.contactInfo.address.state', '');
                                                                                form.change('recipientCarrier.contactInfo.address.zipCode', '');
                                                                            }
                                                                        }}
                                                                        data={{ label: 'Carrier contact info (optional)', value: true }}
                                                                        color="secondary"
                                                                    />
                                                                </Grid>

                                                                {values?.mutators?.showCarrierContactInfo &&
                                                                    <>
                                                                        <Grid xs={12}>
                                                                            <TextField fullWidth label="Carrier phone number" name='recipientCarrier.contactInfo.phoneNumber' type='tel' size='small' onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)} />
                                                                        </Grid>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField fullWidth label="Address line 1" name="recipientCarrier.contactInfo.address.street1" size='small' />
                                                                        </Grid>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField fullWidth label="Address line 2" name="recipientCarrier.contactInfo.address.street2" size='small' />
                                                                        </Grid>
                                                                        <Grid xs={12} md={4}>
                                                                            <TextField fullWidth label="City" name="recipientCarrier.contactInfo.address.city" size='small' />
                                                                        </Grid>
                                                                        <Grid xs={6} md={4}>
                                                                            <TextField fullWidth label="State" name="recipientCarrier.contactInfo.address.state" size='small' select>
                                                                                {stateLabelValues.map((option) => (
                                                                                    <MenuItem key={option.value} value={option.value}>
                                                                                        {option.label}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                        </Grid>
                                                                        <Grid xs={6} md={4}>
                                                                            <TextField fullWidth label="Zip" name="recipientCarrier.contactInfo.address.zipCode" size='small' />
                                                                        </Grid>
                                                                    </>
                                                                }

                                                                <Grid xs={12}>
                                                                    <Switches
                                                                        name="mutators.showAdjusterContactInfo"
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            form.change('mutators.showAdjusterContactInfo', checked)
                                                                            if (!checked) {
                                                                                form.change('recipientAdjuster.contactInfo.phoneNumber', '');
                                                                                form.change('recipientAdjuster.contactInfo.emailAddress', '');
                                                                                form.change('recipientAdjuster.firstName', '');
                                                                                form.change('recipientAdjuster.lastName', '');
                                                                            }
                                                                        }}
                                                                        data={{ label: 'Adjuster contact info (optional)', value: true }}
                                                                        color="secondary"
                                                                    />
                                                                </Grid>

                                                                {values?.mutators?.showAdjusterContactInfo &&
                                                                    <>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField fullWidth label="Adjuster first name" name='recipientAdjuster.firstName' size='small' />
                                                                        </Grid>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField fullWidth label="Adjuster last name" name='recipientAdjuster.lastName' size='small' />
                                                                        </Grid>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField fullWidth label="Adjuster phone" name='recipientAdjuster.contactInfo.phoneNumber' type='tel' size='small'
                                                                                onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField fullWidth label="Adjuster email" name='recipientAdjuster.contactInfo.emailAddress' type='email' size='small' />
                                                                        </Grid>
                                                                    </>
                                                                }


                                                            </Grid>
                                                        </Stack>

                                                        {/* demand details */}
                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Demand details</Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid xs={12} md={6}>

                                                                    <FormControl fullWidth>
                                                                        <Radios
                                                                            color="secondary"
                                                                            sx={{ width: '100%' }}
                                                                            label="Display demand amount in letter?"
                                                                            name="demandDetails.displayDemandAmount"
                                                                            onChange={(e) => {
                                                                                form.change(
                                                                                    'demandDetails.displayDemandAmount',
                                                                                    e.target.value === 'true'
                                                                                );
                                                                                if (e.target.value !== 'true') {
                                                                                    form.change('claimInfo.insuredPolicyLimitsAsUnderstoodByFirm', '');
                                                                                    form.change('demandDetails.demandOfferToSettleAmount', '');
                                                                                    form.change('demandDetails.policyLimitDemandIndicator', false);
                                                                                } else {
                                                                                    form.change('demandDetails.policyLimitDemandIndicator', true);
                                                                                    form.change(
                                                                                        'demandDetails.demandOfferToSettleAmount',
                                                                                        values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm || ''
                                                                                    );
                                                                                }
                                                                            }}
                                                                            data={[
                                                                                { label: 'Yes', value: true },
                                                                                { label: 'No', value: false },
                                                                            ]}
                                                                            radioGroupProps={{ row: true }}
                                                                            required
                                                                        />
                                                                    </FormControl>
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    {values?.demandDetails?.displayDemandAmount !== false && (
                                                                        <FormControl fullWidth>
                                                                            <Radios
                                                                                color="secondary"
                                                                                sx={{ width: '100%' }}
                                                                                label="Is this a policy limit demand?"
                                                                                name="demandDetails.policyLimitDemandIndicator"
                                                                                onChange={(e) => {
                                                                                    form.change(
                                                                                        'demandDetails.policyLimitDemandIndicator',
                                                                                        e.target.value === 'true'
                                                                                    );
                                                                                    if (e.target.value === 'true') {
                                                                                        form.change(
                                                                                            'demandDetails.demandOfferToSettleAmount',
                                                                                            values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm
                                                                                        );
                                                                                    } else {
                                                                                        form.change('demandDetails.demandOfferToSettleAmount', '');
                                                                                    }
                                                                                }}
                                                                                data={[
                                                                                    { label: 'Yes', value: true },
                                                                                    { label: 'No', value: false },
                                                                                ]}
                                                                                radioGroupProps={{ row: true }}
                                                                            />
                                                                        </FormControl>
                                                                    )}
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <TextField
                                                                        label="Insured policy limit"
                                                                        name='claimInfo.insuredPolicyLimitsAsUnderstoodByFirm'
                                                                        onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                                                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                                                        onChange={(e) => {
                                                                            if (values?.demandDetails?.policyLimitDemandIndicator) {
                                                                                form.change("demandDetails.demandOfferToSettleAmount", e.target.value)
                                                                            }
                                                                            form.change("claimInfo.insuredPolicyLimitsAsUnderstoodByFirm", e.target.value)
                                                                        }}
                                                                        size='small'
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        label="Demand amount"
                                                                        name='demandDetails.demandOfferToSettleAmount'
                                                                        onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                                                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                                                        disabled={values?.demandDetails?.policyLimitDemandIndicator}
                                                                        size='small'
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Expected medical specials amount"
                                                                            name='damagesSummary.demandDamagesAmount'
                                                                            onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                                endAdornment: <Tooltip title="This optional field captures the expected amount of medical charges that have been uploaded for the demand." arrow placement="top">
                                                                                    <div style={{ marginTop: '8px' }}>
                                                                                        <InfoOutlined color="action" />
                                                                                    </div>
                                                                                </Tooltip>
                                                                            }}
                                                                            size='small'
                                                                        />



                                                                    </Stack>
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Tax ID" name="sendingFirm.firmTINNumber" size='small' />
                                                                </Grid>




                                                                <Grid item xs={12} md={6}>
                                                                    {values?.demandDetails?.usingRelativeDueDate === true ?
                                                                        <TextField
                                                                            name="demandDetails.demandResponseRelativeDueDate"
                                                                            label="Relative days to respond"
                                                                            type="number"
                                                                            fullWidth
                                                                            onChange={(e) => {
                                                                                //clip the input values to the range (1, MAX_DAYS_RELATIVE_DUE_DATE)
                                                                                e.target.value = Math.min(Math.max(parseInt(e.target.value), 1), MAX_DAYS_RELATIVE_DUE_DATE);
                                                                                form.change('demandDetails.demandResponseRelativeDueDate', e.target.value);
                                                                                form.change('demandDetails.demandResponseDueDate', '');
                                                                            }}
                                                                            onInput={(e) => {
                                                                                e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Filter out non-integer values
                                                                            }}
                                                                            value={values?.demandDetails?.demandResponseRelativeDueDate || ''}
                                                                            InputProps={{
                                                                                endAdornment:
                                                                                    <Tooltip title="Using a relative due date will automatically calculate and adjust the due date until the demand is sent." arrow placement="top">
                                                                                        <div style={{ marginTop: '8px' }}>
                                                                                            <InfoOutlined color="action" />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                            }}
                                                                        />
                                                                        :
                                                                        <TextField
                                                                            name="demandDetails.demandResponseDueDate"
                                                                            fullWidth
                                                                            inputProps={{ min: getCurrentDate() }}
                                                                            label="Demand response fixed due date"
                                                                            type="date"
                                                                            InputLabelProps={{ shrink: true }}
                                                                            size="small"
                                                                            onChange={(e) => {
                                                                                form.change('demandDetails.demandResponseDueDate', e.target.value)
                                                                                form.change('demandDetails.demandResponseRelativeDueDate', '')
                                                                            }}
                                                                            value={values?.demandDetails?.demandResponseDueDate || ''}
                                                                            error={errors.demandDetails?.demandResponseDueDate}
                                                                            helperText={
                                                                                !errors.demandDetails?.demandResponseDueDate &&
                                                                                    values?.demandDetails?.demandResponseDueDate &&
                                                                                    moment(values.demandDetails.demandResponseDueDate).isAfter(moment().add(1, 'years'))
                                                                                    ? 'Date is more than 1 year from today'
                                                                                    : errors.demandDetails?.demandResponseDueDate
                                                                            }
                                                                        />
                                                                    }
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <Switches
                                                                            name="demandDetails.usingRelativeDueDate"
                                                                            onChange={(e) => {
                                                                                const checked = e.target.checked;
                                                                                form.change('demandDetails.usingRelativeDueDate', checked)
                                                                                if (!checked) {
                                                                                    form.change('demandDetails.demandResponseRelativeDueDate', '');
                                                                                    form.change('demandDetails.usingRelativeDueDate', false);
                                                                                }
                                                                                else {
                                                                                    form.change('demandDetails.demandResponseDueDate', '');
                                                                                    form.change('demandDetails.usingRelativeDueDate', true);
                                                                                }
                                                                            }}
                                                                            data={{ label: 'Use relative due date', value: true }}
                                                                            color="secondary"
                                                                        />
                                                                    </Stack>
                                                                </Grid>

                                                                <Grid xs={12}>
                                                                    <TextField fullWidth label="Demand letter template" name='metadata.demandTemplateId' select size='small' required>
                                                                        {availableTemplates.map((option) => {
                                                                            return (
                                                                                <MenuItem key={option.templateId} value={option.templateId}>
                                                                                    {option.templateName}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                    </TextField>
                                                                </Grid>

                                                            </Grid>
                                                        </Stack>

                                                        {/* case team */}
                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Case team</Typography>
                                                            <Grid container spacing={2}>

                                                                <Grid item xs={12}>
                                                                    <Autocomplete
                                                                        name="autoCompletes.attorney"
                                                                        disablePortal
                                                                        //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText

                                                                        fullWidth
                                                                        blurOnSelect={true}
                                                                        filterSelectedOptions={true}
                                                                        filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                                                                        options={attorney_auto_complete_data}
                                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                                        renderInput={(params) => (
                                                                            <>
                                                                                <MuiTextField
                                                                                    {...params}
                                                                                    size='small'
                                                                                    label="Attorney"
                                                                                    required={true}
                                                                                    onClick={(e) => form.blur('autoCompletes.attorney')}
                                                                                    error={touched['autoCompletes.attorney'] && errors.autoCompletes?.attorney}
                                                                                    helperText={touched['autoCompletes.attorney'] && errors.autoCompletes?.attorney}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid xs={12}>
                                                                    <Autocomplete
                                                                        name="autoCompletes.collaborators"
                                                                        //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                                                                        fullWidth
                                                                        multiple
                                                                        limitTags={4}
                                                                        filterOptions={(options, params) => getMultiSelectAutoCompleteFilteredOptions(options, params, values?.autoCompletes?.collaborators)}
                                                                        options={collaborators_auto_complete_data}
                                                                        renderInput={(params) => (
                                                                            <>
                                                                                <MuiTextField
                                                                                    //MUI-RFF textfield does filtering does not work well with multi select autocomplete, so we use MUI textfield here
                                                                                    {...params}
                                                                                    size='small'
                                                                                    label="Collaborators"
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>


                                                                <Grid xs={12}>
                                                                    <Switches
                                                                        name="mutators.attorneyIsPrimaryContact"
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            form.change('mutators.attorneyIsPrimaryContact', checked)
                                                                            form.change('autoCompletes.primaryContact', checked ? values?.autoCompletes?.attorney : undefined)
                                                                        }}
                                                                        data={{ label: 'Attorney is primary contact', value: true }}
                                                                        color="secondary"
                                                                    />
                                                                </Grid>

                                                                {!values?.mutators?.attorneyIsPrimaryContact &&

                                                                    <Grid item xs={12}>
                                                                        <Autocomplete
                                                                            name="autoCompletes.primaryContact"
                                                                            //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                                                                            fullWidth
                                                                            blurOnSelect={true}
                                                                            filterSelectedOptions={true}
                                                                            filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                                                                            isOptionEqualToValue={(option, value) => option.label === value.label}
                                                                            options={primaryContact_auto_complete_data}
                                                                            renderInput={(params) => (
                                                                                <>
                                                                                    <MuiTextField
                                                                                        {...params}
                                                                                        size='small'
                                                                                        label="Primary contact"
                                                                                        required={true}
                                                                                        onClick={(e) => form.blur('autoCompletes.primaryContact')}
                                                                                        error={touched['autoCompletes.primaryContact'] && errors.autoCompletes?.primaryContact}
                                                                                        helperText={touched['autoCompletes.primaryContact'] && errors.autoCompletes?.primaryContact}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Stack>

                                                        {/* response address */}
                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Response address</Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Address line 1" name="demandDetails.demandResponseAddress.street1" size='small' />
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField fullWidth label="Address line 2" name="demandDetails.demandResponseAddress.street2" size='small' />
                                                                </Grid>
                                                                <Grid xs={12} md={4}>
                                                                    <TextField fullWidth label="City" name="demandDetails.demandResponseAddress.city" size='small' />
                                                                </Grid>
                                                                <Grid xs={6} md={4}>
                                                                    <TextField fullWidth label="State" name="demandDetails.demandResponseAddress.state" select size='small'>
                                                                        {stateLabelValues.map((option) => (
                                                                            <MenuItem key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                </Grid>
                                                                <Grid xs={6} md={4}>
                                                                    <TextField fullWidth label="Zip" name="demandDetails.demandResponseAddress.zipCode" size='small' />
                                                                </Grid>
                                                            </Grid>
                                                        </Stack>

                                                        {useDemandNotesEnabled &&
                                                            <Stack>
                                                                <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Notes</Typography>
                                                                <Grid container spacing={1}>
                                                                    <Grid xs={12}>
                                                                        <TextField fullWidth label="Demand Notes" name="customerSpecialNotes" size="small" inputProps={{ maxLength: 50 }} />
                                                                        <FormHelperText>{values?.customerSpecialNotes ? values.customerSpecialNotes.length : 0}/50</FormHelperText>
                                                                    </Grid>
                                                                </Grid>
                                                            </Stack>
                                                        }
                                                    </Stack>
                                                }
                                            </>
                                        </fieldset>

                                    </Grid>
                                    {/* Right Column */}
                                    <Grid xs={12} sm={12} md={4} lg={5}>
                                        <Stack spacing={2} width={"100%"}>
                                            <fieldset disabled={formSubmitting}>
                                                <DocumentUploader />
                                            </fieldset>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <ConfirmationDialog
                                handleOk={() => {
                                    setDialogState({ ...dialogState, discardChanges: false });
                                    handleClose();
                                }}
                                {...confirmationDialogProps}
                            />
                        </form>
                    )
                }
                }>
            </Form>
            <Snackbar
                open={toastState.show}
                autoHideDuration={7000}
                onClose={e => setToastState({ ...toastState, show: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Paper elevation={8}>
                    <Alert onClose={e => setToastState({ ...toastState, show: false })} severity={toastState.severity} sx={{ width: '100%' }}>{toastState.message}</Alert>
                </Paper>
            </Snackbar>
        </ >
    )
}

export default CreateNewDemand;
