import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    enableCloseTransition: false,
    component_A_width: 0,
    component_B_width: 0,
    isClosed: false,
    component_B_current_minimum_width: 350,
    settings: {
        component_B_minimum_width: 350,
        component_A_minimum_width: 350,
        closedWidth: 58,
    }
};

export const SideBySideWithVerticalNavSlice = createSlice({
    name: 'SideBySideWithVerticalNav',
    initialState,
    reducers: {

        set_component_A_width: (state, action) => {
            state.component_A_width = action.payload
        },
        set_component_B_width: (state, action) => {
            state.component_B_width = action.payload
        },
        setScreenWidth: (state, action) => {
            state.screenWidth = action.payload
        },
        setResizableSideBySideClosed: (state, action) => {
            state.isClosed = action.payload
            state.component_B_current_minimum_width = action.payload ? state.settings.closedWidth : state.settings.component_B_minimum_width;
            state.component_A_width = action.payload ? window.innerWidth - state.settings.closedWidth : state.settings.component_A_minimum_width;
        },
        setEnableCloseTransition: (state, action) => {
            state.enableCloseTransition = action.payload
        },
        resetSideBySideWithVerticalNavState: (state) => initialState,
    },
})

export const {
    set_component_A_width,
    set_component_B_width,
    resetSideBySideWithVerticalNavState,
    setScreenWidth,
    setResizableSideBySideClosed,
    setEnableCloseTransition
} = SideBySideWithVerticalNavSlice.actions

export default SideBySideWithVerticalNavSlice.reducer;