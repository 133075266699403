import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSelector, useDispatch } from 'react-redux'
import { fetchDocumentContacts } from '../redux/thunks/demandContactData';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AttorneyFilter = ({ data, updateFilteredActiveDocuments, currentQuickFilterData, updateFilteredContacts }) => {
    const dispatch = useDispatch();
    const inventoryState = useSelector(state => state.Inventory);
    const { user } = useSelector(state => state.User);
    const token = user?.signInUserSession?.accessToken?.jwtToken;



    const [contactsDataFetched, setContactsDataFetched] = useState(false);
    const [selectedAttorneys, setSelectedAttorneys] = useState(inventoryState.contactData.selectedAttorneys);
    const uniqueAttorneys = inventoryState.contactData.attorneys.reduce((accumulator, current) => {
        if (!accumulator.find((item) => `${item.firstName} ${item.lastName}` === `${current.firstName} ${current.lastName}`)) {
            accumulator.push(current);
        }
        return accumulator;
    }, []);
    const options = uniqueAttorneys;
    const requestBody = {
        "contactType": "ATTORNEY"
    };

    useEffect(() => {
        if (!contactsDataFetched) {
            dispatch(fetchDocumentContacts({ requestBody, token }));
            setContactsDataFetched(true);
        }
    }, [currentQuickFilterData])

    const handleChange = (event, value) => {
        setSelectedAttorneys(value);
        dispatch(updateFilteredContacts(value));
    }

    return (
        <Autocomplete
            size='small'
            limitTags={1}
            sx={{ mt: .2, ml: 1, mb: 1, height: 40, width: 350, zIndex: 2, display: 'flex', alignItems: 'center' }}
            multiple
            options={options || []}
            value={selectedAttorneys || []}
            defaultValue={[]}
            selected={selectedAttorneys || []}
            isOptionEqualToValue={(option, value) => { return `${option.firstName}${option.lastName}` === `${value.firstName}${value.lastName}` }}
            disableCloseOnSelect
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            onChange={handleChange}
            renderOption={(props, option, { selected }) => {
                return (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.firstName} {option.lastName}
                    </li>
                )
            }}
            renderInput={(params) => (
                <TextField sx={{ height: 40 }} {...params} label='Filter by attorney' placeholder={selectedAttorneys?.length !== 0 ? '' : 'Filter by attorney'} />
            )}
        />
    );
}

export default AttorneyFilter;