import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs, Tab } from "@mui/material";


function samePageLinkNavigation(event) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

function LinkTab(props) {
  return (
    <Tab
      id={props.id}
      component="a"
      value={props.value}
      onClick={(event) => {
        if (samePageLinkNavigation(event)) {
          event.preventDefault();
        }
      }}
      aria-current={props.selected && 'page'}
      {...props}
    />
  );
}

const AdminTabs = (props) => {
    const { selectedTab } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [value, setValue] = React.useState(selectedTab);

    const handleChange = (event, newValue) => {
      // event.type can be equal to focus with selectionFollowsFocus.
      if (
        event.type !== 'click' ||
        (event.type === 'click' && samePageLinkNavigation(event))
      ) {
        setValue(newValue);
        navigate('/'+newValue, { state: { from: location.pathname } });
      }
      
    };

    return (
      <>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="admin tabs"
          role="navigation"
        >
          <LinkTab value="customer-management" label="Customer management" href="/customer-management" />
          <LinkTab value="contact-management" label="Contact database" href="/contact-management" />
          <LinkTab value="demand-template" label="Demand Template" href="/demand-template" />
        </Tabs>
      </>
    )
}
export default AdminTabs;