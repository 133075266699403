import { apiSlice } from '../apiSlice';
import { pollPdfRegenerationStatus } from '../pdf/thunks';

const documentDataApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    // SAVE /document
    saveDocumentData: builder.mutation({
      query: ({ data }) => ({
        url: `/document`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      invalidatesTags: (result, error, { documentId }) => [{ type: 'DocumentData', id: documentId }],
    }),

    // GET /document/{documentId}
    getDocumentData: builder.query({
      query: ({ documentId, pollPdfStatus }) => ({
        url: `/document/${documentId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { documentId }) => [{ type: 'DocumentData', id: documentId }],
      async onQueryStarted({ documentId, pollPdfStatus = true }, { dispatch, queryFulfilled }) {
        //we set pollPdfStatus to false when we don't want to poll the pdf regeneration status
        //this is useful when we are fetching the document data for the first time
        //we set pollPdfStatus to true when we want to poll the pdf regeneration status and trigger other actions
        //this is useful when we are fetching the document data after a save
        try {
          await queryFulfilled;
          pollPdfStatus && dispatch(pollPdfRegenerationStatus({ documentId })); // Dispatch the thunk after successful fetch
          return;
        } catch (error) {
          console.error('Error while fetching document data:', error);
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useSaveDocumentDataMutation,
  useGetDocumentDataQuery,
  useLazyGetDocumentDataQuery
} = documentDataApi;

export default documentDataApi;
