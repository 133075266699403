import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setToast, setApiErrorToast } from '../redux/slices/globalToastSlice';
import { Snackbar, Alert, Paper } from '@mui/material';
import customApiToastDictionary from './CustomToasts/ApiToasts';

const Toast = () => {
  const { toast, apiErrorToast } = useSelector((state) => state.GlobalToasts);
  const { isOpen, severity, message, action, keepOpenOnClickAway } = toast;
  const duration = toast?.duration || 5000;
  const anchorOrigin = toast?.anchorOrigin || { vertical: 'bottom', horizontal: 'right' };

  const { isOpen: apiErrorIsOpen, message: apiErrorMessage, customToastDisplay = 'apiDefaultToast', errorPayload: apiErrorPayload, keepOpenOnClickAway: apiErrorKeepOpenOnClickAway } = apiErrorToast;
  const apiErrorDuration = apiErrorToast?.duration || undefined; //make sticky with option to close by default unlesss duration is set
  const apiErrorAnchorOrigin = apiErrorToast?.anchorOrigin || { vertical: 'top', horizontal: 'left' };
  const apiErrorSeverity = apiErrorToast?.severity || 'error';

  const dispatch = useDispatch();

  // allow for toast to bypass default clickaway behavior (useful when user is interacting with a form and rapidly pressing keys)
  const handleCloseToast = (event, reason) => reason === 'clickaway' && keepOpenOnClickAway ? null : dispatch(setToast({ isOpen: false, message: '' }));
  const handleApiErrorToastClose = () => dispatch(setApiErrorToast({ isOpen: false, message: '' }));

  const ApiErrorComponent = customApiToastDictionary[customToastDisplay];

  return (

    <>
      <Snackbar
        open={isOpen}
        autoHideDuration={duration}
        onClose={handleCloseToast}
        anchorOrigin={anchorOrigin}
      >
        <Paper elevation={8}>
          <Alert
            onClose={handleCloseToast}
            severity={severity}
            sx={{ width: '100%' }}
          >
            {message}
            {action}
          </Alert>
        </Paper>
      </Snackbar>

      <Snackbar
        open={apiErrorIsOpen}
        autoHideDuration={apiErrorDuration}
        onClose={handleApiErrorToastClose}
        anchorOrigin={apiErrorAnchorOrigin}
      >
        <Paper elevation={8}>
          <ApiErrorComponent errorPayload={apiErrorPayload} />
        </Paper>
      </Snackbar>
    </>
  );
};

export default Toast;
