import { createSlice } from '@reduxjs/toolkit'
import { fetchInventoryDemandData } from '../thunks/demandData'
import { DEFAULT_PAGE_SIZE } from '../../common'

const originalQuickFiltersState = [
  {
    id: 'ALL',
    name: 'All demands',
    toggleState: true,
    data: null
  },
  {
    id: 'NEEDS_INIITAL_CALL',
    name: 'Needs initial call',
    toggleState: false,
    data: null
  },
  {
    id: 'NEEDS_RECEIPT_ACK',
    name: 'Needs receipt acknowledged',
    toggleState: false,
    data: null
  },
  {
    id: 'PENDING_SIGNOFF',
    name: 'Pending signoff',
    toggleState: false,
    data: null
  },
  {
    id: 'PENDING_PROCESSING',
    name: 'Pending processing',
    toggleState: false,
    data: null
  },
]

export const ContactLogInventorySlice = createSlice({
  name: 'ContactLogInventory',
  initialState: {
    contactLogResponseData: {
      loading: false,
      isActiveDataFetched: false,
      isInactiveDataFetched: false,
      activeDocuments: [],
      inActiveDocuments: [],
      inActivePaginationKeys: {},
      activePaginationKeys: {},
    },
    pagination: {
      page: 0,
      paginationKeys: [],
      hasNextPage: false,
      sort: 'date_created',
      sortDirection: 'desc',
      pageSize: DEFAULT_PAGE_SIZE
    },
    filterState: {
      filteredActiveDocuments: null,
      quickFilters: originalQuickFiltersState
    }
  },
  reducers: {
    pageChange: (state, action) => {
      state.pagination.page = action.payload.page;
      state.pagination.hasNextPage = action.payload.hasNextPage;
    },
    pageSizeChange: (state, action) => {
      state.pagination.pageSize = action.payload.pageSize;
    },
    sortChange: (state, action) => {
      state.pagination.sort = action.payload.sort;
      state.pagination.sortDirection = action.payload.sortDirection;
    },
    updateFilterState: (state, action) => {
      state.filterState = action.payload;
    },
    resetFilterState: (state, action) => {
      state.filterState = {
        filteredActiveDocuments: null,
        filterModel: { items: [] },
        quickFilters: originalQuickFiltersState,
        activeQuickFilter: 'ALL'
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDemandData: (state, action) => {
      const { data, isActive } = action.payload;
      if (isActive) {
        state.contactLogResponseData.activeDocuments = data;
      } else {
        state.contactLogResponseData.inActiveDocuments = data;
      }
    },
    updateFilteredActiveDocuments: (state, action) => {
      state.filterState.filteredActiveDocuments = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInventoryDemandData.fulfilled, (state, action) => {
      const { response, isActive } = action.payload;
      if (isActive) {
        state.contactLogResponseData.activeDocuments = response.documents;
        state.contactLogResponseData.activeTotalRows = response.totalRows;
        state.contactLogResponseData.activeTotalDocuments = response.totalDocuments;
        state.contactLogResponseData.activeStatusCounts = response.totalStatusCounts;
        state.isActiveDataFetched = true;
        state.loading = false;
      } else {
        state.contactLogResponseData.inActiveDocuments = response.documents;
        state.contactLogResponseData.activeTotalRows = response.totalRows;
        state.contactLogResponseData.activeTotalDocuments = response.totalDocuments;
        state.contactLogResponseData.activeStatusCounts = response.totalStatusCounts;
        state.isInactiveDataFetched = true;
        state.loading = false;
      }
    })
  },
})

export const { pageChange, pageSizeChange, sortChange, setLoading, setDemandData, updateFilterState, updateFilteredActiveDocuments, resetFilterState } = ContactLogInventorySlice.actions
export default ContactLogInventorySlice.reducer