import { createSlice } from '@reduxjs/toolkit';

const originalUserState = {
    user: {},
    userData: {},
    isAuthenticated: false,
};

export const UserSlice = createSlice({
    name: 'User',
    initialState: originalUserState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        resetUserState: (state) => originalUserState,
        logout: () => {},
    },
});

export const {
    setUser,
    setUserData,
    setIsAuthenticated,
    resetUserState,
    logout,
}
    = UserSlice.actions;

export default UserSlice.reducer;