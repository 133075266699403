import { setInitialCodeEditorContent } from '../redux/slices/codeEditorSlice';
import { setIsEditorOpen } from '../redux/slices/adminEditTemplateSlice';
import { setCodeEditorContentFetched } from '../redux/slices/codeEditorSlice';
import { fetchCodeEditorContent } from '../redux/thunks/codeEditorData';
import { resetTemplateSlice } from '../redux/slices/templateSlice';

export const handleCreateTemplateButtonClick = (precedentDefaultTemplateId, dispatch, user) => {
    dispatch(resetTemplateSlice()); 
    if (!precedentDefaultTemplateId) {
        dispatch(setInitialCodeEditorContent({ parentComponentName: 'AdminEditTemplate', content: '' }));
        dispatch(setCodeEditorContentFetched({ parentComponentName: 'AdminEditTemplate', fetched: true }));
    } else {
        dispatch(fetchCodeEditorContent({ templateId: precedentDefaultTemplateId, user, parentComponentName: 'AdminEditTemplate' }));
    }
    dispatch(setIsEditorOpen(true));
}