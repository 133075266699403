import { Dialog, Slide } from '@mui/material';
import styled from '@emotion/styled';
import React from 'react';

const AnimatedDialog = styled(Dialog)(({ open }) => ({

}));

AnimatedDialog.defaultProps = {
  TransitionComponent: (props) => <Slide {...props} direction="up" />,
  TransitionProps: { timeout: 250 },
  'aria-describedby': "edit-demand-details",
  fullScreen: true,
  fullWidth: true,
};

export default AnimatedDialog;
