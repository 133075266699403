import { Extension } from '@tiptap/core';
import { generateNarrativeForField } from '../../../api';
import { setToast } from '../../../redux/slices/globalToastSlice';
import store from '../../../redux/store';

const formatResponse = (response, addNewLine) => {
  response = response.replaceAll('\\n', '</br>'); // replace newlines with line breaks
  if (response.startsWith('"') && response.endsWith('"'))
    // remove end quotes
    response = response.substring(1, response.length - 1);
  response = addNewLine ? '</br>' + response : response; // add newline if editor wasn't empty
  return response;
};

const typeResponse = async (
  editor,
  formattedResponse,
  typingDurationLowerBound,
  typingDurationUpperBound,
  wordsPerUpdate
) => {
  const words = formattedResponse.split(' ');
  const totalWords = words.length;
  const typingDuration = Math.floor(
    Math.random() * (typingDurationUpperBound - typingDurationLowerBound + 1) + typingDurationLowerBound
  );
  const intervalTime = typingDuration / Math.ceil(totalWords / wordsPerUpdate);

  for (let i = 0; i < totalWords; i += wordsPerUpdate) {
    const chunk = words.slice(i, i + wordsPerUpdate).join(' ');

    editor
      .chain()
      .command(({ commands, tr }) => {
        const { $to } = tr.selection;
        const endPosition = $to.end();
        commands.insertContentAt(endPosition, chunk + ' ', {
          parseOptions: {
            preserveWhitespace: 'full',
          },
        });
      })

      .run();
    await new Promise((resolve) => setTimeout(resolve, intervalTime));
  }
};

const AiGenerate = Extension.create({
  name: 'aiGenerate',

  addStorage() {
    return {
      isLoading: false,
    };
  },

  addCommands() {
    return {
      generateNarrative:
        (fieldName, documentId, user) =>
        ({ editor }) => {
          this.storage.isLoading = true;
          generateNarrativeForField(fieldName, documentId, user)
            .then((response) => {
              if (response.status === 200) {
                const formattedResponse = formatResponse(response.body, editor.getText() !== '');
                typeResponse(editor, formattedResponse, 3000, 5000, 2); // take anywhere from 3 to 5 seconds to type out the response using 2 words per update
              } else {
                store.dispatch(
                  setToast({
                    isOpen: true,
                    severity: 'error',
                    message: 'Error generating AI narrative',
                    duration: 7000,
                  })
                );
              }
            })
            .finally(() => {
              this.storage.isLoading = false;
            });

          return true;
        },
    };
  },
});

export default AiGenerate;
