/* eslint-disable no-param-reassign */
/**
 * Custom Tiptap extension for applying indentation.
 *
 * Sources:
 * https://github.com/ueberdosis/tiptap/issues/1036#issuecomment-1000983233
 * https://tiptap.dev/docs/editor/extensions/custom-extensions
 */

import { CommandProps, Extension, Extensions, isList, KeyboardShortcutCommand } from '@tiptap/core';
import { TextSelection, Transaction } from 'prosemirror-state';

const PARAGRAPH_INDENT_LENGTH = '2em';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    indent: {
      indent: () => ReturnType;
      outdent: () => ReturnType;
      indentParagraph: () => ReturnType;
      outdentParagraph: () => ReturnType;
    };
  }
}

type IndentOptions = {
  names: Array<string>;
  indentRange: number;
  minIndentLevel: number;
  maxIndentLevel: number;
  defaultIndentLevel: number;
  HTMLAttributes: Record<string, any>;
};

export const Indent = Extension.create<IndentOptions, never>({
  name: 'indent',

  addOptions() {
    return {
      names: ['heading', 'paragraph', 'blockquote', 'listItem', 'orderedList', 'bulletList'], // Indent applied to these extensions
      indentRange: 24,
      minIndentLevel: 0,
      maxIndentLevel: 24 * 10,
      defaultIndentLevel: 0,
      HTMLAttributes: {},
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: ['heading', 'paragraph', 'listItem', 'orderedList', 'bulletList'], // Indent attributes for heading and paragraph apply margin on left
        attributes: {
          indent: {
            default: this.options.defaultIndentLevel,
            renderHTML: (attributes) => ({
              style: `margin-left: ${attributes.indent}px!important`,
            }),
            parseHTML: (element) => parseInt(element.style.marginLeft, 10) || this.options.defaultIndentLevel,
          },
        },
      },
      {
        types: ['blockquote'], // Attributes for blockquote apply indentation on left and right
        attributes: {
          indent: {
            default: 24,
            renderHTML: (attributes) => ({
              style: `margin: 0 ${attributes.indent}px!important`,
            }),
            parseHTML: (element) => parseInt(element.style.marginLeft, 10) || 24,
          },
        },
      },
      {
        types: ['paragraph'],
        attributes: {
          textIndent: {
            default: null,
            keepOnSplit: false, // Don't apply indentation on enter key to create new paragraph.
            parseHTML: (element) => element.style.textIndent?.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.textIndent) {
                return {};
              }

              return {
                style: `text-indent: ${attributes.textIndent}`,
              };
            },
          },
        },
      },
    ];
  },

  // Define indent and outdent (un-indent) commands for the editor.
  addCommands(this) {
    return {
      indent:
        () =>
        ({ tr, state, dispatch, editor }: CommandProps) => {
          const { selection } = state;
          tr = tr.setSelection(selection);
          tr = updateIndentLevel(tr, this.options, editor.extensionManager.extensions, 'indent');
          if (tr.docChanged && dispatch) {
            dispatch(tr);
            return true;
          }
          return false;
        },
      outdent:
        () =>
        ({ tr, state, dispatch, editor }: CommandProps) => {
          const { selection } = state;
          tr = tr.setSelection(selection);
          tr = updateIndentLevel(tr, this.options, editor.extensionManager.extensions, 'outdent');
          if (tr.docChanged && dispatch) {
            dispatch(tr);
            return true;
          }
          return false;
        },
      indentParagraph:
        () =>
        ({ commands }: CommandProps) => {
          return commands.updateAttributes('paragraph', { textIndent: PARAGRAPH_INDENT_LENGTH });
        },
      outdentParagraph:
        () =>
        ({ commands }: CommandProps) => {
          return commands.resetAttributes('paragraph', 'textIndent');
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      Tab: tabIndent(),
      'Shift-Tab': getOutdent(true),
      Backspace: backspaceOutdent(),
      'Mod-]': getIndent(),
      'Mod-[': getOutdent(true),
    };
  },

  onUpdate() {
    const { editor } = this;
    if (editor.isActive('listItem')) {
      const node = editor.state.selection.$head.node();
      if (node.attrs.indent) {
        editor.commands.updateAttributes(node.type.name, { indent: 0 });
      }
    }
  },
});

export const clamp = (val: number, min: number, max: number): number => {
  if (val < min) {
    return min;
  }
  if (val > max) {
    return max;
  }
  return val;
};

function setNodeIndentMarkup(tr: Transaction, pos: number, delta: number, min: number, max: number): Transaction {
  if (!tr.doc) return tr;
  const node = tr.doc.nodeAt(pos);
  if (!node) return tr;
  const indent = clamp((node.attrs.indent || 0) + delta, min, max);
  if (indent === node.attrs.indent) return tr;
  const nodeAttrs = {
    ...node.attrs,
    indent,
  };
  return tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks);
}

type IndentType = 'indent' | 'outdent';
const updateIndentLevel = (
  tr: Transaction,
  options: IndentOptions,
  extensions: Extensions,
  type: IndentType
): Transaction => {
  const { doc, selection } = tr;
  if (!doc || !selection) return tr;
  if (!(selection instanceof TextSelection)) {
    return tr;
  }
  const { from, to } = selection;
  doc.nodesBetween(from, to, (node, pos) => {
    if (options.names.includes(node.type.name)) {
      tr = setNodeIndentMarkup(
        tr,
        pos,
        options.indentRange * (type === 'indent' ? 1 : -1),
        options.minIndentLevel,
        options.maxIndentLevel
      );
      return false;
    }
    return !isList(node.type.name, extensions);
  });
  return tr;
};

// Tab button is expected to do a lot of different things.
const tabIndent: () => KeyboardShortcutCommand =
  () =>
  ({ editor }) => {
    // Nest list items
    if (editor.can().sinkListItem('listItem')) {
      return editor.chain().focus().sinkListItem('listItem').run();
    }
    // Indent first line of a paragraph
    if (editor.isActive('paragraph', { textIndent: null }) && !editor.isActive('listItem')) {
      return editor.chain().indentParagraph().run();
    }
    // Apply indentation
    return editor.chain().focus().indent().run();
  };

const backspaceOutdent: () => KeyboardShortcutCommand =
  () =>
  ({ editor }) => {
    // If cursor is at beginning, outdent the paragraph or indentation. If in a list, default backspace behavior.
    if (editor.state.selection.$head.parentOffset === 0) {
      if (editor.getAttributes('paragraph')?.textIndent) {
        return editor.chain().outdentParagraph().run();
      }
      if (!editor.isActive('listItem')) {
        return editor.chain().focus().outdent().run();
      }
    }
    return false;
  };

// Function that calls editor command to indent. If called on list item, will sink item to inner list.
// Can be exported to be used in other extensions.
export const getIndent: () => KeyboardShortcutCommand =
  () =>
  ({ editor }) => {
    if (editor.can().sinkListItem('listItem')) {
      return editor.chain().focus().sinkListItem('listItem').run();
    }
    return editor.chain().focus().indent().run();
  };

// Function that calls editor command to outdent. If called on list item, will lift item to outer list.
// Can be exported to be used in other extensions.
export const getOutdent: (outdentOnlyAtHead: boolean) => KeyboardShortcutCommand =
  (outdentOnlyAtHead) =>
  ({ editor }) => {
    if (outdentOnlyAtHead && editor.state.selection.$head.parentOffset > 0) {
      return false;
    }
    if (
      (!outdentOnlyAtHead || editor.state.selection.$head.parentOffset > 0) &&
      editor.can().liftListItem('listItem')
    ) {
      return editor.chain().focus().liftListItem('listItem').run();
    }
    // If text indent attribute is applied, outdent the paragrpah.
    if (editor.getAttributes('paragraph')?.textIndent && editor.state.selection.$head.parentOffset === 0) {
      return editor.chain().outdentParagraph().run();
    }
    return editor.chain().focus().outdent().run();
  };
