
import React, { useEffect,useState, createRef } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Title from './CommonComponents/Title'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {formatAddress, formatCurrency,formatDate} from './common';
import {getAttachmentS3Url, downloadAttachment} from './attachments_api';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import EnclosuresTable from './EnclosuresTable';
import { MedicalInformationRounded } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux'
import { toggle } from './redux/slices/accordionSlice'

const AdjusterViewerFutureDamages= (props) => {

    const primaryActionsColor = "rgba(0, 0, 0, 0.56)";
    const [detailsPaneOpen, setDetailsPaneOpen] = useState(false);
    const [currentDocumentUrl, setCurrentDocumentUrl] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [lostWageAttachments, setLostWageAttachments] = useState([]);
    const [otherDamageAttachments, setOtherDamageAttachments] = useState([]);
    const [totalFutureMedicalsCost, setTotalFutureMedicalsCost] = useState(0.0);
    const [totalFutureLostWagesCost, setTotalFutureLostWagesCost] = useState(0.0);
    const [totalFutureOtherDamagesCost, setTotalFutureOtherDamagesCost] = useState(0.0);
    const [accordionStates, setAccordionStates] = useState({});

    const frameRef = createRef();
    const leftFrameRef = createRef();
    const demandDoc = props.demandDoc;

    const dispatch = useDispatch();
    const panels = useSelector(state => state.accordion.panels);

    const handleAccordionChange = (panel, newExpanded) => {
        dispatch(toggle({id:panel,expanded:newExpanded}));
    };

    const isExpanded = (panelId) => {
        const matchingPanel = panels.find(panel => panel.id === panelId)
        if (matchingPanel) {
            return matchingPanel.expanded;
        }
        else {
            return false;
        }
    } 

    const findAttachment = (attachmentId) => {
        for (let i = 0; i < demandDoc.attachments.length; i++) {
            const attachment = demandDoc.attachments[i];
            if (attachment.attachmentId == attachmentId)
                return attachment;
        }

        return null;
    };

    const resizeIframe = () => {
        if (frameRef.current != null) {
            let h = window.innerHeight-150;    
            h = 700;
            frameRef.current.style.height = h+'px';
            leftFrameRef.current.style.height = frameRef.current.style.height
        }
    };

    const hideDetailsPanel = () => {
        setDetailsPaneOpen(false);
    };

    const viewDetailsPanelCallback = async (attachmentId, title) => {
    
        // Load the documents
        const s3_url = await getAttachmentS3Url(attachmentId);
        setCurrentDocumentUrl(s3_url);

        // Open the panel
        setDetailsPaneOpen(true);
    };

    const downloadAttachmentCallback = async (attachmentId) => {
        downloadAttachment(findAttachment(attachmentId));
    };

    const getAccordionState = (id) => {
        return accordionStates[id] == undefined ? false : accordionStates[id]; 
     };
 
    useEffect(() => {

        const fmIds = []
        let totalMed = 0.0;
        props.demandDoc.futureMedicals.map((medical) =>  {
            totalMed += parseFloat(medical.estimatedAmount);
            fmIds.push(MedicalInformationRounded.futureMedicalId);
        });
        setTotalFutureMedicalsCost(totalMed);

        const lwIds = []
        let totalFutureLostWages = 0.0;
        props.demandDoc.lostWages.map((lostWage) =>  {
            if (lostWage.futureIndicator) {
                totalFutureLostWages += parseFloat(lostWage.grossWages);
                lwIds.push(lostWage.lostWageId);
            }
        });
        setTotalFutureLostWagesCost(totalFutureLostWages);

        const odIds = []
        let totalOtherDamages = 0.0;
        props.demandDoc.lostWages.map((damage) =>  {
            if (damage.futureIndicator) {
                totalOtherDamages += parseFloat(damage.otherDamageId);
                odIds.push(damage.lostWageId);
            }
        });
        setTotalFutureOtherDamagesCost(totalOtherDamages);


        const tempAttachments = [];
        const tempLWAttachments = [];
        const tempODAttachments = [];
        for (let i = 0; i < props.demandDoc.attachments.length; i++) {
            const attachment = props.demandDoc.attachments[i];

            if (attachment.associatedDemandEntity.demandSection == 'FutureDamages' &&
                fmIds.includes(attachment.associatedDemandEntity.associatedDemandEntityId)) {
                tempAttachments.push(attachment);
            }
            else if (attachment.associatedDemandEntity.demandSection == 'IncurredDamages' &&
                (attachment.associatedDemandEntity.associatedDemandEntityId && 
                 lwIds.includes(attachment.associatedDemandEntity.associatedDemandEntityId))) {
                    tempLWAttachments.push(attachment);
            }
            else if (attachment.associatedDemandEntity.demandSection == 'IncurredDamages' &&
                    (attachment.associatedDemandEntity.associatedDemandEntityId && 
                     odIds.includes(attachment.associatedDemandEntity.associatedDemandEntityId))) {
                    tempODAttachments.push(attachment);
            }
        }
        setAttachments([...tempAttachments]);
        setLostWageAttachments([...tempLWAttachments]);
        setOtherDamageAttachments([...tempODAttachments]);

        
    }, []);
    
    useEffect(() => {
        resizeIframe();
    }, [frameRef,leftFrameRef]);

    return (
        <Grid container sx={{mt:1}}>
            <Grid xs={detailsPaneOpen ? 6 : 12} >
                <Box ref={leftFrameRef} style={{overflowY:'auto'}}>
                
                    <Stack spacing={3} width={detailsPaneOpen ? "100%" : "50%"}>

                        <Box>
                            <Title color={primaryActionsColor}>Estimate Future Medical Damages: {formatCurrency(totalFutureMedicalsCost)}</Title>
                        </Box>

                        {demandDoc.futureMedicals.map((cot,index) => {
                            return (
                                <Accordion  elevation={3} sx={{mb:1}} key={index} expanded={isExpanded(cot.treatmentCourseId)} onChange={(e,expanded) => {handleAccordionChange(cot.treatmentCourseId, expanded)} }>
                                    <AccordionSummary
                                        sx={{margin:0}}
                                        expandIcon={<ExpandMoreIcon color='secondary'/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Title>{cot.treatmentType}: {formatCurrency(cot.estimatedAmount)}</Title>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>

                                            <Grid container xs={12} className="doc-content-section adjuster-form-data">
                                                <Grid xs={3} className="adjuster-form-label">
                                                    Start Date
                                                </Grid>
                                                <Grid xs={9}>
                                                    {formatDate(cot.estimatedStartDate)}
                                                </Grid>

                                                <Grid xs={3} className="adjuster-form-label">
                                                    End Date
                                                </Grid>
                                                <Grid xs={9}>
                                                    {formatDate(cot.estimatedEndDate)}
                                                </Grid>

                                                <Grid xs={3} className="adjuster-form-label">
                                                    Provider
                                                </Grid>
                                                <Grid xs={9}>
                                                    {cot.medicalProvider.businessName}
                                                </Grid>

                                                <Grid xs={3} className="adjuster-form-label">
                                                    Provider TIN
                                                </Grid>
                                                <Grid xs={9}>
                                                    {cot.medicalProvider.providerTIN}
                                                </Grid>

                                                <Grid xs={3} className="adjuster-form-label">
                                                    Estimated Damages
                                                </Grid>
                                                <Grid xs={9}>
                                                    {formatCurrency(cot.estimatedAmount)}
                                                </Grid>
                                            </Grid>

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                            })}

                            <Accordion  elevation={3} sx={{mb:1}} expanded={isExpanded('fm-enclosure')} onChange={(e,expanded) => {handleAccordionChange('fm-enclosure', expanded)} }>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon color='secondary'/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Title>Enclosures ({attachments.length})</Title>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <EnclosuresTable showTitle={false} demandDoc={demandDoc} attachments={attachments} viewTitle="" downloadAttachment={downloadAttachmentCallback} viewAttachment={viewDetailsPanelCallback} />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <div class="section-hr" />

                            <Title color={"rgba(0, 0, 0, 0.56)"}>Lost Wages: {formatCurrency(totalFutureLostWagesCost)}</Title>
                            
                                {demandDoc.lostWages.map((lw,index) => {
                                    if (lw.futureIndicator == false)
                                         return null;
                                    else
                                        return (
                                            <Accordion  elevation={3} sx={{mb:1}} key={lw.lostWageId} expanded={isExpanded(lw.lostWageId)} onChange={(e,expanded) => {handleAccordionChange(lw.lostWageId, expanded)}}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon color='secondary'/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Title>{lw.employer.employerName}: {formatCurrency(lw.grossWages)}</Title>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                    <   Stack direction={"column"} spacing={1} className='adjuster-form-data'>
                                                            <Grid container xs={12} className="doc-content-section adjuster-form-data">
                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Start Date
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {formatDate(lw.startDate)}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    End Date
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {formatDate(lw.endDate)}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Employer
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {lw.employer.employerName}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Address
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {formatAddress(lw.employer.contactInfo.address)}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Phone
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {lw.employer.contactInfo.phoneNumber}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Fax
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {lw.employer.contactInfo.faxNumber}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Email
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {lw.employer.contactInfo.emailAddress}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Gross Wages
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {formatCurrency(lw.grossWages)}
                                                                </Grid>
                                                            </Grid>

                                                
                                                        </Stack>

                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                        })}

                              <Accordion  elevation={3} sx={{mb:1}} expanded={isExpanded('fm-lw-enclosures')} onChange={(e,expanded) => {handleAccordionChange('fm-lw-encolsures', expanded)}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon color='secondary'/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Title>Enclosures ({lostWageAttachments.length})</Title>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <EnclosuresTable showTitle={false} demandDoc={demandDoc} attachments={lostWageAttachments} viewTitle="" downloadAttachment={downloadAttachmentCallback} viewAttachment={viewDetailsPanelCallback} />
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <div  className="section-hr" />

                                <Title color={"rgba(0, 0, 0, 0.56)"}>Other Damages: {formatCurrency(totalFutureOtherDamagesCost)}</Title>

                                {demandDoc.otherDamages.map((lw,index) => {
                                    if (lw.futureIndicator == false)
                                        return null;
                                    else
                                        return (
                                            <Accordion  elevation={3} sx={{mb:1}} expanded={isExpanded(lw.otherDamageId)} onChange={(e,expanded) => {handleAccordionChange(lw.otherDamageId, expanded)}}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon color='secondary'/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Title>{lw.otherDamageType}: {formatCurrency(lw.otherDamageAmount)}</Title>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                    <   Stack direction={"column"} spacing={1} className='adjuster-form-data'>
                                                            <Grid container xs={12} className="doc-content-section adjuster-form-data">
                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Type
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {lw.otherDamageType}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Descriptiom
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {lw.otherDamageDescription}
                                                                </Grid>

                                                                <Grid xs={3} className="adjuster-form-label">
                                                                    Amount
                                                                </Grid>
                                                                <Grid xs={9}>
                                                                    {formatCurrency(lw.otherDamageAmount)}
                                                                </Grid>
                                                            </Grid>
                                                        </Stack>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                    )
                                    })}

                                <Accordion elevation={3}  sx={{mb:1}}  expanded={isExpanded('fm-od-enclosures')} onChange={(e,expanded) => {handleAccordionChange('fm-od-enclosures', expanded)}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon color='secondary'/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Title>Enclosures ({otherDamageAttachments.length})</Title>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <EnclosuresTable showTitle={false} demandDoc={demandDoc} attachments={otherDamageAttachments} viewTitle="" downloadAttachment={downloadAttachmentCallback} viewAttachment={viewDetailsPanelCallback} />
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                    </Stack>
                </Box>
            </Grid>
            <Grid xs={detailsPaneOpen ? 6 : 0} >
                {detailsPaneOpen ? (
                <div style={{ height: "100%", marginLeft:"20px" }}>
                    <Stack direction={"column"}>
                        <Stack direction={"row"}>
                            <Grid container xs={12}>
                                <Grid xs={6} >
                                    <Title>Enclosures</Title>
                                </Grid>
                                <Grid xs={6} textAlign={"right"}>
                                    <IconButton title='Close Details' style={{width:'25px'}} onClick={() => { hideDetailsPanel()}}>
                                    <Close color='secondary'/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Stack>
                    
                        <div ref={frameRef}>
                            <iframe onLoad={resizeIframe()} id="documentFrame" src={currentDocumentUrl} title="Document" width={"100%"} height={"100%"}>
                            </iframe>
                        </div>
                    </Stack>
                </div>
                ) : null }
            </Grid>
        </Grid>
    )
}

export default AdjusterViewerFutureDamages;