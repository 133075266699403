
import React, { } from "react";
import { formatCurrency, formatDate } from '../common';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Stack } from '@mui/material';
import KeyValuePair from "./KeyValuePair";

const MedicalsOverview = (props) => {

    const documentData = props.documentData;

    // ui styles for grid
    const gridStyles = { px: 1, py: 1 };
    const stackSpacing = 1;
    const md = 3;
    const sm = 6;
    const xs = 6;

    return (
        <>
            <Grid container >
                <Grid md={md} sm={sm} xs={xs} sx={gridStyles}>
                    <Stack direction={"column"} spacing={stackSpacing}>
                        <KeyValuePair label="Claim number" value={documentData.claimInfo?.claimNumber || "Not on File"} />

                        <KeyValuePair label="Claim coverage" value={documentData.claimInfo?.claimCoverage || "Not on File"} />

                        <KeyValuePair label="Loss state" value={documentData.claimInfo?.lossState || "Not on File"} />
                    </Stack>
                </Grid>
                <Grid md={md} sm={sm} xs={xs} sx={gridStyles}>
                    <Stack direction={"column"} spacing={stackSpacing}>
                        <KeyValuePair label="Date of loss" value={formatDate(documentData.claimInfo?.dateOfLoss)} />

                        <KeyValuePair label="Date of demand" value={formatDate(documentData.demandDetails?.demandWrittenDate)} />

                        <KeyValuePair label="Response due date" value={formatDate(documentData.demandDetails?.demandResponseDueDate) || "Not on File"} />
                    </Stack>
                </Grid>
                <Grid md={md} sm={sm} xs={xs} sx={gridStyles}>
                    <Stack direction={"column"} spacing={stackSpacing}>
                        <KeyValuePair label="Demand amount" value={documentData.demandDetails?.demandOfferToSettleAmount ? formatCurrency(documentData.demandDetails?.demandOfferToSettleAmount) : 'Policy Limits'} />

                        <KeyValuePair label="Policy limit" value={documentData.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm && parseInt(documentData.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm) != 0 ? formatCurrency(documentData.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm) : 'Not on File'} />

                        <KeyValuePair label="Policy limit demand" value={documentData.demandDetails?.policyLimitDemandIndicator ?? false ? 'Yes' : 'No'} />

                    </Stack>
                </Grid>
                <Grid md={md} sm={sm} xs={xs} sx={gridStyles} >
                    <Stack direction={"column"} spacing={stackSpacing} sx={{ textOverflow: 'ellipsis' }}>
                        <KeyValuePair label="Firm contact name" value={(documentData.sendingFirm?.primaryContact?.firstName || "Not on File") + " " + (documentData.sendingFirm?.primaryContact?.lastName ?? "")} />

                        <KeyValuePair label="Firm contact phone" value={documentData.sendingFirm?.primaryContact?.contactInfo?.phoneNumber || "Not on File"} />

                        <KeyValuePair label="Firm contact email" value={documentData.sendingFirm?.primaryContact?.contactInfo?.emailAddress || "Not on File"} />

                    </Stack>
                </Grid>
            </Grid>
        </>
    )

}

export default MedicalsOverview;