import React from 'react';
import { Typography, Stack } from '@mui/material';

const TableNoRowsOverlay = ({ text }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: '100%',
        px: 4,
      }}
    >
      <Typography variant="body1">{text}</Typography>
    </Stack>
  );
};

export default TableNoRowsOverlay;
