import { createAsyncThunk } from '@reduxjs/toolkit';
import { setResizableSideBySideClosed, setEnableCloseTransition } from '../slices/sideBySideWithVerticalNavSlice';

export const CLOSE_TRANSITION_TIME = 200;

export const closeWithTransition = createAsyncThunk('SideBySideWithVerticalNav/closeWithTransition', async (_, { dispatch }) => {
    dispatch(setResizableSideBySideClosed(true));
    dispatch(setEnableCloseTransition(true));

    setTimeout(() => {
        dispatch(setEnableCloseTransition(false));
    }, CLOSE_TRANSITION_TIME);
});