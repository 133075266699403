import { RefreshOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors';
import { format } from 'date-fns';
import React, { useState, useRef, useEffect } from 'react'

const Countdown = ({ seconds }) => {
    const [countdown, setCountdown] = useState(seconds);
    const timerId = useRef();
    const [open, setOpen] = useState(false);

    const formatTime = (time) => {
        let minutes = Math.floor(time / 60);
        let seconds = Math.floor(time - minutes * 60);

        if (minutes < 1) minutes = '0' + minutes;
        if (seconds < 10) seconds = '0' + seconds;
        return minutes + ':' + seconds
    }

    useEffect(() => {
        timerId.current = setInterval(() => {
            seconds > 0 ? setCountdown(prev => prev - 1) : clearInterval(timerId.current)
        }, 1000)
    }, []);

    useEffect(() => {
        if (countdown == 0) {
            clearInterval(timerId.current);
            setOpen(true)
        }

    }, [countdown])

    const pageRefresh = () => {
        window.location.reload()
    }

    return (

        <>
            {countdown > 0 &&
                <Stack direction="row" spacing={1} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant="h6" sx={{ textAlign: 'center', color: grey[700] }}>Time remaining for set up:</Typography>
                    <Typography variant="h6" sx={{ width: '60px', color: grey[700] }}> {formatTime(countdown)}</Typography>
                </Stack>
            }
            <Dialog
                open={open}
                aria-describedby="alert-dialog"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Multi-factor authentication timeout</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography>
                            Reload to restart setup.
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            If you have "Precedent-Exchange-prod" in your authenticator, delete it then reload.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={pageRefresh} startIcon={<RefreshOutlined />} color="secondary" variant="contained">Reload</Button>
                </DialogActions>
            </Dialog>
        </>


    )
}

export default Countdown