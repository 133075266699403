import { isValidEmail, formatPhoneNumber } from '../common'

export const isValidPhoneNumber = (value) => {
    const formattedPhoneNumber = formatPhoneNumber(value);
    //strip anything past the "x"
    const withoutExtension = formattedPhoneNumber.split("x")[0];
    //there must be at least 10 digits in a valid phone number
    const digitsOnly = withoutExtension.replace(/[^0-9]/g, '');
    return digitsOnly.length >= 10;
}

export const validateValue = (fieldtype, value) => {
    switch (fieldtype) {
        case 'email':
            return isValidEmail(value)
        case 'faxnumber':
            return isValidPhoneNumber(value)
        case 'phonenumber':
            return isValidPhoneNumber(value)
        default:
            return true
    }
}

export const errorDictionary = {
    email: 'Invalid email',
    phonenumber: 'Invalid phone number',
    faxnumber: 'Invalid fax number'
}