import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const Title = ({ children, component, color }) => {
  return (
    <Typography component={component ? component : 'h2'} variant='h6' color={color ? color : 'primary'}>
      {children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;