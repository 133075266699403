import { createSlice } from '@reduxjs/toolkit';

const originalEditMenuState = {
    // anchorEl: null,
};

const EditMenuSlice = createSlice({
    name: 'EditMenu',
    initialState: originalEditMenuState,
    reducers: {
        // setAnchorEl: (state, action) => {
        //     state.anchorEl = action.payload;
        // },
    },
});

export const {
    // setAnchorEl,
} = EditMenuSlice.actions;

export default EditMenuSlice.reducer;

