
import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from '../Transition'
import { Button } from '@mui/material';

const StatusDialog = (props) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            open={props.dialogOpen}
            TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
        >
            {props.DialogTitle &&
                <DialogTitle>{props.dialogTitle}</DialogTitle>
            }
            <DialogContent
            >
                <DialogContentText id="alert-dialog-slide-description">
                    {props.dialogText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color='secondary' disabled={props.closeDisabled} onClick={() => { props.handleClose() }}>Ok</Button>
            </DialogActions>
        </Dialog>
    )

}

export default StatusDialog;