import React, { useState,createRef, useEffect } from "react";
import {
  Button,
  Stack,
  ThemeProvider} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {theme} from './Theme';
import awsExports from './aws-exports';

const AttachmentView = ({ open, attachmentId, closeCallback, user }) => {
    
    const rootApiUrl = awsExports.ROOT_API_URL;
    const [attachmentS3Url, setAttachmentS3Url] = useState("");
    const frameRef = createRef();

    const getAttachmentURL = async (attachmentId) => {
        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            }
        };

        const response = await fetch(
            rootApiUrl+"/attachment/"+attachmentId, settings
        ).then((response) => response.json());
    
        // update the state and map form elements
        setAttachmentS3Url(response["s3Url"]);
    };

    const resizeIframe = () => {
        if (frameRef.current != null) {
           frameRef.current.style.height = (window.innerHeight-200)+'px';
        }
    };

    useEffect(() => {
        resizeIframe();
    }, [frameRef]);

    
    const handleClose = (e) => {
        closeCallback();
    }

    useEffect(() => {
        getAttachmentURL(attachmentId)
    }, [open]);

    return (
        <ThemeProvider theme={theme}>
            <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth={true} PaperProps={{ style: {
                    minHeight: '90%',
                    maxHeight: '90%',
                }}}>
                <DialogTitle>Attachments</DialogTitle>
                <DialogContent>
                    <Stack direction={"row"} sx={{width:"100%"}} justifyContent={"center"}>
                        <div ref={frameRef} style={{width:"100%"}}>
                            <iframe onLoad={resizeIframe()} id="documentFrame" src={attachmentS3Url} title="Document" width={"100%"} height={"100%"}>
                            </iframe>
                        </div>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">Close</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};
export default AttachmentView;