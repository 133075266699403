import { createSlice } from '@reduxjs/toolkit';
import { fetchCustomerData, fetchCustomerAvailableTemplatesAndCustomFields } from '../thunks/customerData';
import { NO_TEMPLATE } from '../../DemandComponents/DemandForms/FormHelpers';

const originalCustomerState = {
  customerData: {},
  availableTemplatesAndCustomFields: [],
};

const customerSlice = createSlice({
  name: 'Customer',
  initialState: originalCustomerState,
  reducers: {
    setCustomerData: (state, action) => {
      state.customerData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerData.fulfilled, (state, action) => {
      state.customerData = action.payload;
    });
    builder.addCase(fetchCustomerAvailableTemplatesAndCustomFields.fulfilled, (state, action) => {
      const defaultTemplate = { templateName: NO_TEMPLATE, templateId: NO_TEMPLATE };
      const returnedTemplates = Array.isArray(action.payload.templates) ? action.payload.templates : [];
      state.availableTemplatesAndCustomFields = [defaultTemplate, ...returnedTemplates];
    });
  },
});

export const { setCustomerData } = customerSlice.actions;

export default customerSlice.reducer;
