
import { grey, purple } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#232941',
      light: '#4077B0'
    },
    secondary: {
      main: '#4077B0'
    },
    primaryIconFillColor: {
      main: '#4077B0'
    },
    primaryActions: {
      main: 'rgba(0, 0, 0, 0.56)'
    },
    white: {
      main: '#ffffff'
    },
    success: {
      main: '#2E7D32',
      dark: '#1B5E20'
    },
    text: {
      secondary: grey[800]
    },
    icon: {
      main: 'rgba(0, 0, 0, 0.54)'
    }
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "Rubik",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: "Rubik",
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em"
    },
    h2: {
      fontFamily: "Rubik",
      fontWeight: 300,
      fontSize: "3.75rem",
      lineHeight: 1.2,
      letterSpacing: "-0.008330em"
    },
    h3: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em"
    },
    h4: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em"
    },
    h5: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em"
    },
    h6: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em"
    },
    subtitle1: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "1.125rem",
      lineHeight: 1.75,
      letterSpacing: "0.00938em"
    },
    subtitle2: {
      fontFamily: "Rubik",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.57,
      letterSpacing: "0.00714em"
    },
    body1: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em"
    },
    body2: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em"
    },
    button: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "none"
    },
    caption: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
      textTransform: "none"
    },
    overline: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      letterSpacing: "0.08333em",
      textTransform: "none"
    },
    inherit: {
      fontFamily: "inherit",
      fontWeight: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
      letterSpacing: "inherit",
      textTransform: "inherit"
    },
    inputLabel: {
      fontFamily: "Rubik",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "12px",
      letterSpacing: "0.15000000596046448px",
      textAlign: "left"
    },
    tableP1: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
      color: grey[800]
    },
    tableP2: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
      color: grey[700]
    },
  },
  components: {
    MuiDialogTitle: {
      defaultProps: {
        color: "primary",
        variant: "h6",
        sx: { borderBottom: "1px solid rgba(0,0,0,0.12)" }
      }
    },
    MuiDialogActions: {
      defaultProps: {
        sx: {
          borderTop: "1px solid rgba(0,0,0,0.12)",
          py: 2
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      }
    },
    MuiCardHeader: {
      defaultProps: {
        avatar: {
          color: "secondary"
        },
        titleTypographyProps: {
          variant: 'h5',
          color: "secondary",
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
        sx: {
          px: 2,
          py: .5
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        }
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: grey[600],
          },
        }
      }
    },
    MuiSnackbar: {
      defaultProps: {
        sx: {
          elevation: 8
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiSelect: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: grey[800],
          color: '#FFFFFF',
          fontSize: '13px',
        },
        arrow: {
          color: grey[800]
        }
      }
    }
  },
  visuals: {
    categorical: [
      '#173A5E',
      '#00A3A0',
      '#C91B63',
      '#EF5350',
      '#FFA726',
      '#B800D8',
      '#60009B',
      '#2E96FF',
      '#2731C8',
      '#03008D'
    ]
  }
});