import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDocumentApi } from '../../api';

export const fetchDocumentData = createAsyncThunk('Document/fetchDocumentData', async ({ documentId, user }, { rejectWithValue }) => {
  const response = await getDocumentApi(documentId, user) 
  .then((serverResponse) => {
    if (serverResponse.status === 200) {
      return serverResponse.json();
    } else {
      return rejectWithValue({ error: { message: `GET request failed` }, meta: { baseQueryMeta: { response: { status: serverResponse.status } }, arg: { endpointName: `/document/${documentId}`, originalArgs:  documentId  } } });
     
    }
  })
  .catch((error) => {
    console.log('error', error);
    return rejectWithValue({ error: { message: error.message }, meta: { baseQueryMeta: { response: { status: 500 } }, arg: { endpointName: `/document/${documentId}`, originalArgs:  documentId  } } });
  });

  return response;
});