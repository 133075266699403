import React, { useEffect, useRef } from 'react';
import PSPDFKit from 'pspdfkit';
import { useDispatch, useSelector } from 'react-redux';
import { setIsRedactorPdfDirty } from '../../redux/slices/demandDomSlice';

const PdfRedactor = ({ demandS3Url, setPdfRedactionInstance }) => {

    const dispatch = useDispatch();
    const { pdfRedactionIndex } = useSelector(state => state.DemandDom);
    const pdfRedactorContainerRef = useRef(null);
    const licenseKey = process.env.REACT_APP_PSPDF_LICENSE_KEY;
    const baseUrl = `${window.location.protocol}//${window.location.host}/assets/`
    const height = window.innerHeight - 64;

    const annotationToolbarItems = (annotation, { defaultAnnotationToolbarItems }) =>
        [{ type: 'apply-redactions' }, { type: 'delete' }];

    const annotationPresets = PSPDFKit.defaultAnnotationPresets;
    annotationPresets.redaction = {
        outlineColor: PSPDFKit.Color.BLACK
    };

    const toolbarItems = [{ type: 'redact-rectangle' }, { type: 'zoom-out' }, { type: 'zoom-in' }, { type: 'zoom-mode' }];

    const viewState = new PSPDFKit.ViewState({
        zoom: PSPDFKit.ZoomMode.FIT_WIDTH,
        interactionMode: PSPDFKit.InteractionMode.REDACT_SHAPE_RECTANGLE,
        viewportPadding: { horizontal: 0, vertical: 70 },
    });

    useEffect(() => {
        pdfRedactorContainerRef.current && PSPDFKit.unload(pdfRedactorContainerRef.current)

        PSPDFKit.load({
            styleSheets: [
                `${baseUrl}pspdfkit.css`,
                `${baseUrl}customPSPDFstyles.css`
            ],
            annotationPresets,
            licenseKey,
            container: pdfRedactorContainerRef.current,
            baseUrl: baseUrl,
            document: demandS3Url,
            toolbarItems,
            annotationToolbarItems,
            initialViewState: viewState,
        })
            .then(instance => {
                setPdfRedactionInstance(instance);

                instance.addEventListener("document.change", async (changes) => {
                    //prevent annotations apply operations handler from changing viewState to keep toolbar items enabled
                    instance.setViewState(viewState)
                    dispatch(setIsRedactorPdfDirty(true))
                });

                instance.addEventListener("annotations.didSave", () => {
                    //prevent annotations delete handler from changing viewState to keep toolbar items enabled
                    instance.setViewState(viewState)
                });
            })
    }, [PSPDFKit, pdfRedactionIndex]);

    return (
        <div ref={pdfRedactorContainerRef} style={{ width: "100%", height }} />
    );

}

export default PdfRedactor;